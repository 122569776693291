import Vue from 'vue'
import VeeValidate from 'vee-validate'

function installRules({ app }) {
  VeeValidate.Validator.extend('unique_username', {
    getMessage(field) {
      return 'username is already taken'
    },
    async validate(value, [exception] = []) {
      if (value && value.length > 2) {
        let valid = false
        try {
          const res = await app.$api.checkUsername(value)
          if (res.data.taken) {
            valid = false
          } else {
            valid = true
          }
        } catch (e) {
          console.log(e)
          valid = false
        }
        return {
          valid: valid // Your api could return a response containing a 'valid property'
        }
      } else {
        return false
      }
    }
  })
  VeeValidate.Validator.extend('unique_email', {
    getMessage(field) {
      return 'email is already registered'
    },
    async validate(value, [exception] = []) {
      if (value && value.length > 2) {
        let valid = false
        try {
          const res = await app.$api.checkEmail(value)
          if (res.data.taken) {
            valid = false
          } else {
            valid = true
          }
        } catch (e) {
          console.log(e)
          valid = false
        }
        return {
          valid: valid // Your api could return a response containing a 'valid property'
        }
      } else {
        return false
      }
    }
  })
  VeeValidate.Validator.extend('blacklist_username', {
    getMessage(field) {
      return 'this username is not available'
    },
    async validate(value, [exception] = []) {
      if (value && value.length > 2) {
        let valid = false
        var Filter = require('bad-words'),
          filter = new Filter()
        filter.addWords(
          'about',
          'account',
          'accounts',
          'art',
          'arts',
          'artist',
          'artcontest',
          'artcontests',
          'artists',
          'background',
          'backgrounds',
          'billing',
          'class',
          'classes',
          'contest',
          'contests',
          'code',
          'coding',
          'coding-club',
          'community',
          'contact',
          'club',
          'clubs',
          'doc',
          'docs',
          'educator',
          'educators',
          'elementari',
          'elementary',
          'email',
          'emails',
          'explore',
          'feature',
          'features',
          'file',
          'files',
          'fieldtrip',
          'field_trip',
          'grant',
          'grants',
          'hobby',
          'hourcode',
          'hourofcode',
          'illustration',
          'illustrations',
          'live',
          'livestream',
          'login',
          'logout',
          'music',
          'musics',
          'notification',
          'notifications',
          'password',
          'payment',
          'payments',
          'plans',
          'premium',
          'privacy',
          'pro',
          'researcher',
          'researchers',
          'reset_password',
          'reset',
          'school',
          'school-connect',
          'schools',
          'setting',
          'settings',
          'signin',
          'sign_in',
          'signout',
          'sign_out',
          'sound_effect',
          'sound_effects',
          'sound',
          'sounds',
          'soundeffect',
          'soundeffects',
          'stories',
          'story',
          'studio',
          'subscribe',
          'support',
          'teacher',
          'teachers',
          'terms',
          'virtual',
          'virtual_fieldtrip',
          'web',
          'webmonetize',
          'webmonetization', 
          'monetization',
          'monetize'
        )
        let cleaned = filter.clean(value)
        if (cleaned.includes('*')) {
          valid = false
        } else {
          valid = true
        }
        return {
          valid: valid // Your api could return a response containing a 'valid property'
        }
      } else {
        return false
      }
    }
  })
  VeeValidate.Validator.extend('blacklist_words', {
    getMessage(field) {
      return 'inappropriate'
    },
    async validate(value, [exception] = []) {
      if (value) {

        let valid = false
        var Filter = require('bad-words'),
          filter = new Filter()
        value = value + ' ABC'
        let cleaned = filter.clean(value)
        // remove ABC from string - workaround for emojis
        let substring = cleaned.substr(-3)
        if (substring == "ABC"){
          value = cleaned.substring(0, cleaned.length - 3);
        }

        if (cleaned.includes('*')) {
          valid = false
        } else {
          valid = true
        }
        return {
          valid: valid // Your api could return a response containing a 'valid property'
        }
      } else {
        return false
      }
    }
  })
}

let installed = false
let localized = {
  en: false,
  fr: false,
  es: false,
  // zh_CN: false,
  // zh_TW: false,
  it: false,
  pt: false,
  de: false,
  el: false
}

export default ({ app }) => {
  if (!installed) {
    Vue.use(VeeValidate, {
      inject: false,
      i18n: app.i18n
    })
    // extend the rules after vee-validate is installed.
    installRules({ app })
    installed = true
  }

  const locale = app.i18n.locale
  // skip if already localized.
  if (localized[locale]) return

  // dynamically load a vee-validate locale
  return import(`vee-validate/dist/locale/${locale}`).then(localeObj => {
    VeeValidate.Validator.localize({ [locale]: localeObj })
    localized[locale] = true
  })
}

// Vue.use(VueI18n)
// const i18n = new VueI18n()
// Vue.use(VeeValidate, {
//   inject: false,
//   i18nRootKey: 'validations',
//   i18n,
//   dictionary: {
//     en: en,
//     fr: fr,
//     es: es
//   }
// })

// installRules()
