export default function(req, res, next) {
  const redirects = [
    {
      from: /^\/review\/(.+)$/, 
      to: "/studio/edit/$1/"
    },
    {
      from: "/curriculums", 
      to: "/lessons/"
    },
    {
      from: /^\/stories(\/.*)?$/, 
      to: "/projects$1"
    },
    {
      from: "/stories/",
      to: "/projects/"
    },
  ];

  // Normalize the request URL by removing trailing slashes
  const normalizedUrl = req.url.replace(/\/$/, '') || '/';

  const redirect = redirects.find((r) => {
    if (r.from instanceof RegExp) {
      // Return true if the regex matches the normalized URL
      return r.from.test(normalizedUrl);
    } else {
      // Return true if it's a direct match
      return r.from.replace(/\/$/, '') === normalizedUrl;
    }
  });

  if (redirect) {
    let newLocation;
    if (redirect.from instanceof RegExp) {
      // Replace the `from` regex with the `to` string
      newLocation = normalizedUrl.replace(redirect.from, redirect.to);
    } else {
      newLocation = redirect.to;
    }

    // Ensure the new location has a trailing slash if necessary
    if (!newLocation.endsWith('/')) {
      newLocation += '/';
    }

    res.writeHead(301, { Location: newLocation });
    res.end();
  } else {
    next();
  }
}
