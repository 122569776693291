/* eslint-disable prettier/prettier */
// store/index.js
// import('~/assets/style/app.styl')

// // Helpers
// import colors from 'vuetify/es5/util/colors'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import StudioLauncher from '~/store/modules/StudioLauncher.js'
import StudioProps from '~/store/modules/StudioProps.js'


const pkg = require('~/package.json')

// define this.app.i18n.t
Vue.use(VueI18n)


const getDefaultState = () => {
  return {
    user: null,
    hasLoginCookie: false,
    suggestedUsers: [],
    displayMessages: [],
    currentProfile: {},
    currentClass: {},
    currentLibrary: {},
    studentSelectClass: {},
    studentSelectStory: {},
    collectionAssets: [],
    badgesCard: [],
  }
}

const store = () => {
  return new Vuex.Store({
    state: {
      version: pkg.version,

      assignmentDev: false,
      // NOTE PRODUCTION
      baseURL: 'https://api.elementari.com',
      socketBaseURL: 'api.elementari.com',
      frontendBaseURL: 'https://elementari.com',

      // NOTE TESTING LOCAL0
      // socketBaseURL: 'localhost:8000',
      // baseURL: 'http://localhost:8000',
      // frontendBaseURL: 'http://localhost:3000',

      // socketBaseURL: '10.0.0.4:8000',
      // baseURL: 'http://10.0.0.4:8000',
      // frontendBaseURL: 'http://10.0.0.4:3333',


      // cleverKey: "08ca37ebe4b483e092af",
      // classlinkKey: "c1679318255687a40158431d5a09f6322b5f5ecff28e",  //need to change allauth view in backend also
      
      // PRODUCTION
      cleverKey: "996f4d336940568886e4",
      classlinkKey: "c167931825568759f63a490295ddbe303aebcea3d9ba", //need to change allauth view in backend also
      stripeAPIKey: "pk_live_uXGDzHyRpWI9u9EVXTqOJ6oK",
      // stripeAPIKey: "pk_test_h04r9PP1YM2csvaHMEgrUfmc",
      getStream: {
        // PRODUCTION
        apiKey: '6xjncf73yqp5',
        appId: '32405'
        // // LOCAL DEV
        // apiKey: 'hustmwq3krcq',
        // appId: '46233'
      },
      genre: {
        ap: 'App',
        ga: 'Game',
        st: 'Story',
        an: 'Animation'
      },
      currency: null,
      plans: [
        // PRODUCTION DOLLARS
        {
          currency: 'USD',
          accountType: 'h',
          amount: 10,
          planId: 'plan_EXDbN8PryPqwvZ',
          name: 'monthlyEducatorPlan'
        },
        {
          currency: 'USD',
          accountType: 'h',
          amount: 96,
          planId: 'plan_EXDcExqR0iZz7Z',
          name: 'yearlyEducatorPlan',
          discount: 20
        },
        {
          currency: 'USD',
          accountType: 't',
          amount: 29,
          planId: 'plan_EXDZJfna316DQU',
          name: 'monthlyEducatorPlusPlan'
        },
        {
          currency: 'USD',
          accountType: 't',
          amount: 300,
          planId: 'plan_EXDYV6VrEjkGRN',
          name: 'yearlyEducatorPlusPlan',
          discount: 14
        },
        {
          currency: 'USD',
          accountType: 'o',
          amount: 1188,
          planId: 'plan_FagVGaIfdzswKr',
          name: 'yearlySchoolPlan',
        },
        // PRODUCTION EUROS
        {
          currency: 'EUR',
          accountType: 'h',
          amount: 10,
          planId: 'plan_Ee1NfjCNLtkDtm',
          name: 'monthlyEducatorPlan'
        },
        {
          currency: 'EUR',
          accountType: 'h',
          amount: 96,
          planId: 'plan_Ee1PfRuGxYxb2p',
          name: 'yearlyEducatorPlan',
          discount: 20
        },
        {
          currency: 'EUR',
          accountType: 't',
          amount: 29,
          planId: 'plan_Ee1SFzdxM3eQIX',
          name: 'monthlyEducatorPlan'
        },
        {
          currency: 'EUR',
          accountType: 't',
          amount: 300,
          planId: 'plan_Ee1TUlAj9tdvjv',
          name: 'yearlyEducatorPlan',
          discount: 14
        },
        {
          currency: 'EUR',
          accountType: 'o',
          amount: 1188,
          planId: 'plan_FagW6U01cl9bjF',
          name: 'yearlySchoolPlan',
        }

        // // LOCAL DEV USD
        // {
        //   currency: 'USD',
        //   accountType: 'h',
        //   amount: 10,
        //   planId: 'plan_FHaUJkwnvjTPO5',
        //   name: 'monthlyHobbyPlan'
        // },
        // {
        //   currency: 'USD',
        //   accountType: 'h',
        //   amount: 96,
        //   planId: 'plan_FHaUSwW0sIckM8',
        //   name: 'yearlyHobbyPlan',
        //   discount: 10
        // },
        // {
        //   currency: 'USD',
        //   accountType: 't',
        //   amount: 29,
        //   planId: 'plan_FHaWKzKaNUl2g7',
        //   name: 'monthlyEducatorPlan'
        // },
        // {
        //   currency: 'USD',
        //   accountType: 't',
        //   amount: 300,
        //   planId: 'plan_FHaXJALADGfBRc',
        //   name: 'yearlyEducatorPlan',
        //   discount: 10
        // },
        // // LOCAL DEV EUROS
        // {
        //   currency: 'EUR',
        //   accountType: 'h',
        //   amount: 10,
        //   planId: 'plan_FHaUmTIvGNdUNR',
        //   name: 'monthlyHobbyPlan'
        // },
        // {
        //   currency: 'EUR',
        //   accountType: 'h',
        //   amount: 96,
        //   planId: '	plan_FHaVtXgkHew0pO',
        //   name: 'yearlyHobbyPlan',
        //   discount: 10
        // },
        // {
        //   currency: 'EUR',
        //   accountType: 't',
        //   amount: 29,
        //   planId: 'plan_FHaWtYzpsrQoQz',
        //   name: 'monthlyEducatorPlan'
        // },
        // {
        //   currency: 'EUR',
        //   accountType: 't',
        //   amount: 300,
        //   planId: 'plan_FHaXVDJiu8UNec',
        //   name: 'yearlyEducatorPlan',
        //   discount: 10
        // },
      ],
      cookie_exp_date: 1,
      searchQuery: '',
      placeholder: {
        avatar_sm: 'https://d35gz3cm1ahlmo.cloudfront.net/cache/db/83/db83571ab11febd2aadfa5ec2f839fb0.jpg',
        avatar_md: 'https://d35gz3cm1ahlmo.cloudfront.net/cache/db/83/db83571ab11febd2aadfa5ec2f839fb0.jpg',
        story: '/img/blank_project.jpg'
      },
      navigationDrawer: null,
      onlineStatusDrawer: null,
      loginDialog: false,
      studentSignupDialog: false,
      teacherSignupDialog: false,
      signupDialog: false,
      contactDialog: false,
      submitAssetDialog: false,
      schoolContactDialog: false,
      imagePrefixPath: 'https://d35gz3cm1ahlmo.cloudfront.net/',

      featuredStories: [],
      featuredCurriculums: [],
      featuredClassrooms: [],
      featuredAssets: [],
      featuredSounds: [],

      previewSoundItem: {},
      previewSoundLoading: false,
      previewSoundPlaying: false,
      howl: null,
      initReaderAudio: false,
      hasLoginCookie: false,
      showLayoutLoader: true,

      suggestedUsers: [],
      displayMessages: [],
      currentProfile: {},
      currentClass: {},
      currentLibrary: {},
      studentSelectClass: {},
      studentSelectStory: {},
      collectionAssets: [],
      badgesCard: [],
      readerHelpDocumentationDialog: false,
      studioHelpDocumentationDialog: false,
      documentationArticle: {
        name: 'setTextEffect',
        category: 'transform'
      },   
      inspectMode: false

    },
    getters: {
      currentLibrary: (state) => {
        return state.currentLibrary;
      },
      getCurrentProfile(state) {
        return state.currentProfile
      },
      loggedIn(state) {
        return state.auth.loggedIn
      },
      appVersion: (state) => {
        return state.version
      },
      currency(state) {
        // if (state.currency) {
        //   return state.currency
        // } else {
        return {
          code: 'USD',
          symbol: '$'
        }
        // }
      }
    },

    modules: {
      StudioLauncher,
      StudioProps
    },
    mutations: {
      SET_NUM_STUDENTS(state, num) {
        state.currentLibrary.num_students = num;
      },
      SET_INSPECT_MODE(state, inspectMode){
        state.inspectMode = inspectMode
      },
      SET_DOCUMENTATION_ARTICLE(state, documentationArticle){
        state.documentationArticle = documentationArticle
      },
      SET_READER_HELP_DOCUMENTATION_DIALOG(state, readerHelpDocumentationDialog){
        state.readerHelpDocumentationDialog = readerHelpDocumentationDialog
      },
      SET_STUDIO_HELP_DOCUMENTATION_DIALOG(state, studioHelpDocumentationDialog){
        state.studioHelpDocumentationDialog = studioHelpDocumentationDialog
      },
      HAS_LOGIN_COOKIE(state, hasLoginCookie) {
        state.hasLoginCookie = hasLoginCookie
      },
      SET_SHOW_LAYOUT_LOADER(state, showLayoutLoader) {
        state.showLayoutLoader = showLayoutLoader
      },
      LOGOUT() {
        this.$auth.logout()
        this.$auth.setUser(false)
        this.$axios.setHeader('Authorization', false)
  
        window.localStorage.clear();
        this.$cookies.removeAll()
      },
      RESET_STATE(state) {
        Object.assign(state, getDefaultState())
      },
      
      INIT_READER_AUDIO(state) {
        state.initReaderAudio = true
      },

      setSearchQuery(state, searchQuery) {
        state.searchQuery = searchQuery
      },
      setNavigationDrawer(state, navigationDrawer) {
        state.navigationDrawer = navigationDrawer
      },
      setOnlineStatusDrawer(state, onlineStatusDrawer) {
        state.onlineStatusDrawer = onlineStatusDrawer
      },
      setLoginDialog(state, loginDialog) {
        state.loginDialog = loginDialog
      },
      setStudentSignupDialog(state, studentSignupDialog) {
        state.signupDialog = false
        state.studentSignupDialog = studentSignupDialog
      },
      setTeacherSignupDialog(state, teacherSignupDialog) {
        state.signupDialog = false
        state.teacherSignupDialog = teacherSignupDialog
      },
      setSubmitAssetDialog(state, submitAssetDialog) {
        state.submitAssetDialog = submitAssetDialog
      },
      setSignupDialog(state, signupDialog) {
        state.teacherSignupDialog = false
        state.studentSignupDialog = false
        state.signupDialog = signupDialog
      },
      setContactDialog(state, contactDialog) {
        state.contactDialog = contactDialog
      },
      setSchoolContactDialog(state, contactDialog) {
        state.schoolContactDialog = contactDialog
      },
      SET_FEATURED_CURRICULUMS(state, {
        list
      }) {
        state.featuredCurriculums = list
      },
      SET_FEATURED_STORIES(state, {
        list
      }) {
        state.featuredStories = list
      },
      SET_FEATURED_CLASSROOMS(state, {
        list
      }) {
        state.featuredClassrooms = list
      },
      SET_FEATURED_ASSETS(state, {
        list
      }) {
        state.featuredAssets = list
      },
      SET_FEATURED_SOUNDS(state, list) {
        state.featuredSounds = list
      },
      SET_STUDENT_SELECT_STORY(state, obj) {
        state.studentSelectStory = obj
      },
      SET_STUDENT_SELECT_CLASS(state, obj) {
        state.studentSelectClass = obj
      },
      SET_STUDENT_SELECT_ASSIGNMENT(state, obj) {
        state.studentSelectClass.assignment = obj
      },
      SET_CURRENT_CLASS(state, obj) {
        state.currentClass = obj
      },

      SET_CURRENT_LIBRARY(state, obj) {
        state.currentLibrary = obj
      },
      SET_CURRENT_CLASS_LIBRARY_PASSWORD(state, obj) {
        state.currentClass.student_password = obj
      },
      SET_CURRENT_LIBRARY_ARCHIVE(state, obj) {
        state.currentLibrary.archived_at = obj
      },
      SET_CURRENT_ASSIGNMENT(state, obj) {
        state.currentClass.assignment = obj
      },
      SET_CURRENT_PROFILE(state, obj) {
        state.currentProfile = obj
      },
      ADD_SOCIAL_LINK(state) {
        var obj = {
          title: '',
          title_verbose: '',
          url: ''
        }
        state.currentProfile.profile.social.push(obj)
      },
      REMOVE_SOCIAL_LINK(state, index) {
        state.currentProfile.profile.social.splice(index, 1)
      },
      SET_CLEVER_TOKEN(state, data){
        state.auth.clever_token = data
      },
      SET_CLASSLINK_TOKEN(state, data){
        state.auth.classlink_token = data
      },
      SET_LEVEL(state, data) {
        state.auth.user.level = data
      },
      CHANGE_LANG(state, value) {
        if(state.auth.loggedIn){
          state.auth.user.profile.lang = value
        }
        state.i18n.locale = value

      },
      SET_STUDENT_PASS_IS_CODE(state, value) {
        state.auth.user.student_data.need_pass_change = value
      },
      SET_TEACHER_PASS_IS_CODE(state, value) {
        state.auth.user.teacher_data.need_pass_change = value
      },
      SET_DISPLAY_MESSAGES(state, {
        list
      }) {
        state.displayMessages = list
      },
      CHANGE_AVATAR(state, data) {
        state.auth.user.profile.source = data
      },
      // CANCEL_TRIAL(state) {
      //   state.auth.user.subscription = null
      //   state.auth.user.get_account_type_display = null
      //   state.auth.user.account_type = null
      //   state.auth.user.is_premium = false
      //   state.auth.user.is_teacher = false
      //   state.auth.user.is_student = false
      //   state.auth.user.is_school = false
      // },
      // CANCEL_PLAN(state, value) {
      //   if (value) {
      //     state.auth.user.subscription.canceled_at = Date.now()
      //   } else {
      //     state.auth.user.subscription.canceled_at = null
      //   }
      // },
      async REMOVE_MESSAGE(state, id) {
        try {
          const index = state.displayMessages.findIndex(item => item.id === id)
          const res = await this.$api.removeMessage(id)
          state.displayMessages.splice(index, 1)
        } catch (e) {
          console.log(e)
        }
      },
      SET_CURRENCY(state, currency) {
        if (currency == 'EUR') {
          state.currency = {
            code: 'EUR',
            symbol: '€'
          }
        } else {
          state.currency = {
            code: 'USD',
            symbol: '$'
          }
        }
      },
      SET_SUGGESTED_USERS(state, {
        list
      }) {
        state.suggestedUsers = list
      },
      SET_ASSETS_IN_COLLECTION(state, {
        list
      }) {
        state.collectionAssets = list
      },
      SET_BADGES_CARD(state, {
        list
      }) {
        state.badgesCard = list
      },
      //FEED
      SET_UNSEEN_NOTIFICATIONS(state, count) {
        state.auth.user.unseen_notifications = count
      },

      // SOUNDS
      SET_PREVIEW_SOUND_ITEM(state, obj) {
        state.previewSoundItem = obj
      },
      async PLAY_SOUND_ITEM(state, obj) {
        let src = state.imagePrefixPath + obj.audio_file
        if (obj.audio_file.includes(state.imagePrefixPath)){
          src = obj.audio_file
        }
        state.howl = new Howl({
          src: [src],
          volume: 0.5,
          onplay: function () {
            state.previewSoundLoading = false
            state.previewSoundPlaying = true
          },
          onend: function () {
            state.previewSoundPlaying = false
            state.previewSoundItem = {}
          }
        })
        state.howl.play()
        const data = {
          model: 'sound',
          obj_id: obj.id
        }
        // try {
        //   const res = await this.$api.hit(data)
        // } catch (e) {
        //   console.log(e)
        // }
      },
      STOP_SOUND_ITEM(state) {
        state.previewSoundPlaying = false
        state.howl.stop()
      },
      SET_FEEDBACK_FOR_STORIES(state, res) {
        let a1 = state.draft_stories.results
        let a2 = res
        state.draft_stories.results = state.draft_stories.results.map(itm => ({
          ...res.find((item) => (item.slug === itm.slug)),
          ...itm
        }))
      },

      // ON_REMOVE_TRANSFERED_STORY(state, slug) {
      //   // find index
      //   let index = state.draft_stories.results.findIndex(x => x.slug === slug)
      //   // remove from drafts
      //   state.draft_stories.results.splice(index, 1)
      // },
      // UPDATE_STORY_DETAILS(state, story) {
      //   for (var i = 0, l = state.draft_stories.results.length; i < l; i++) {
      //     if (state.draft_stories.results[i].slug == story.slug) {
      //       for (var keyName in story) {
      //         if (keyName == 'slug') continue
      //         state.draft_stories.results[i][keyName] = story[keyName]
      //       }
      //     }
      //   }
      // }
    },
    actions: {
      SET_READER_HELP_DOCUMENTATION_DIALOG({commit}, readerHelpDocumentationDialog){
        commit('SET_READER_HELP_DOCUMENTATION_DIALOG', readerHelpDocumentationDialog)
      },
      SET_STUDIO_HELP_DOCUMENTATION_DIALOG({commit}, studioHelpDocumentationDialog){
        commit('SET_STUDIO_HELP_DOCUMENTATION_DIALOG', studioHelpDocumentationDialog)
      },
      SET_DOCUMENTATION_ARTICLE({commit}, documentationArticle){
        commit('SET_DOCUMENTATION_ARTICLE', documentationArticle )
      },
      SET_INSPECT_MODE({commit}, inspectMode) {
        commit('SET_INSPECT_MODE', inspectMode)
      },
      DISPLAY_HELP({commit, state}, payload){
        if(state.inspectMode){
          commit('SET_READER_HELP_DOCUMENTATION_DIALOG', true)
        }else{
          commit('SET_STUDIO_HELP_DOCUMENTATION_DIALOG', true)
        }
        commit('SET_DOCUMENTATION_ARTICLE', payload.documentationArticle)
      },
      HAS_LOGIN_COOKIE({
        commit
      }, hasLoginCookie) {
        commit('HAS_LOGIN_COOKIE', hasLoginCookie)
      },
      SET_SHOW_LAYOUT_LOADER({
        commit
      }, showLayoutLoader) {
        commit('SET_SHOW_LAYOUT_LOADER', showLayoutLoader)
      },
      LOGOUT({
        commit
      }) {
        commit('LOGOUT')
        commit('RESET_STATE')
      },
      INIT_READER_AUDIO({
        commit
      }) {
        commit('INIT_READER_AUDIO')
      },
      setLoginDialog({
        commit
      }, loginDialog) {
        commit('setLoginDialog', loginDialog)
      },
      setStudentSignupDialog({
        commit
      }, studentSignupDialog) {
        commit('setStudentSignupDialog', studentSignupDialog)
      },
      setTeacherSignupDialog({
        commit
      }, teacherSignupDialog) {
        commit('setTeacherSignupDialog', teacherSignupDialog)
      },
      setSignupDialog({
        commit
      }, signupDialog) {
        commit('setSignupDialog', signupDialog)
      },
      setSubmitAssetDialog({
        commit
      }, submitAssetDialog) {
        commit('setSubmitAssetDialog', submitAssetDialog)
      },
      setContactDialog({
        commit
      }, contactDialog) {
        commit('setContactDialog', contactDialog)
      },
      setSchoolContactDialog({
        commit
      }, contactDialog) {
        commit('setSchoolContactDialog', contactDialog)
      },
      async GET_CURRENCY({
        commit
      }) {
        try {
          const res = await this.$api.getCurrency()
          if (res.success) {
            commit('SET_CURRENCY', res.currency)
          } else {
            commit('SET_CURRENCY', "USD")
          }
        } catch (e) {
          console.log(e)
        }
      },
      async GET_SUGGESTED_USERS({
        commit
      }) {
        try {
          const res = await this.$api.getSuggestedUsers()
          commit('SET_SUGGESTED_USERS', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },
      async GET_FEATURED_CURRICULUMS({
        commit
      }) {
        try {
          const res = await this.$api.getCurriculums()
          commit('SET_FEATURED_CURRICULUMS', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },
      async GET_FEATURED_STORIES({
        commit
      }) {
        try {
          const res = await this.$api.getFeaturedStories()
          commit('SET_FEATURED_STORIES', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },
      async GET_FEATURED_CLASSROOMS({
        commit
      }) {
        try {
          const res = await this.$api.getFeaturedClassrooms()
          commit('SET_FEATURED_CLASSROOMS', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },
      async GET_FEATURED_ASSETS({
        commit
      }) {
        try {
          const res = await this.$api.getFeaturedAssets()
          commit('SET_FEATURED_ASSETS', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },

      async GET_FEATURED_SOUNDS({
        commit
      }) {
        try {
          const res = await this.$api.getFeaturedSounds()
          commit('SET_FEATURED_SOUNDS', res)
        } catch (e) {
          console.log(e)
        }
      },
      async GET_DISPLAY_MESSAGES({
        commit
      }) {
        try {
          const res = await this.$api.getDisplayMessages()
          commit('SET_DISPLAY_MESSAGES', {
            list: res.results
          })
        } catch (e) {
          console.log(e)
        }
      },

      // GAMIFICATION
      GET_BADGES_CARD: function ({
        commit
      }) {
        if (this.$auth.user.getting_started_done) {
          this.$axios.$get('api/v1/badges/?category=wr').then(res => {
            commit('SET_BADGES_CARD', {
              list: res.results
            })
          })
        } else {
          this.$axios.$get('api/v1/badges/?category=gs').then(res => {
            commit('SET_BADGES_CARD', {
              list: res.results
            })
          })
        }
      },

      ADD_SOCIAL_LINK({
        commit
      }) {
        commit('ADD_SOCIAL_LINK')
      },
      REMOVE_SOCIAL_LINK({
        commit
      }, index) {
        commit('REMOVE_SOCIAL_LINK', index)
      },
      SET_CLEVER_TOKEN({
        commit
      }, data) {
        commit('SET_CLEVER_TOKEN', data)
      },
      SET_CLASSLINK_TOKEN({
        commit
      }, data) {
        commit('SET_CLASSLINK_TOKEN', data)
      },
      SET_LEVEL({
        commit
      }, data) {
        commit('SET_LEVEL', data)
      },
      SET_STUDENT_PASS_IS_CODE({
        commit
      }, value) {
        commit('SET_STUDENT_PASS_IS_CODE', value)
      },
      SET_TEACHER_PASS_IS_CODE({
        commit
      }, value) {
        commit('SET_TEACHER_PASS_IS_CODE', value)
      },
      CHANGE_LANG({
        commit
      }, value) {
        commit('CHANGE_LANG', value)
      },
      SUBSCRIBE_PLAN({
        commit
      }, data) {
        commit('SUBSCRIBE_PLAN', data)
      },
      CHANGE_AVATAR({
        commit
      }, data) {
        commit('CHANGE_AVATAR', data)
      },
      CANCEL_TRIAL({
        commit
      }) {
        commit('CANCEL_TRIAL')
      },
      CANCEL_PLAN({
        commit
      }, value) {
        commit('CANCEL_PLAN', value)
      },

      // FORMS
      SEND_CONTACT_FORM: function ({
        commit
      }) {
        this.$axios.$get('api/v1/contact').then(res => {})
      },
      SEND_SUBSCRIBER: function ({
        commit
      }) {
        this.$axios.$get('api/v1/subscriber').then(res => {})
      },

      //FEED
      UPDATE_UNSEEN_NOTIFICATIONS: function ({
        commit
      }, count) {
        commit('SET_UNSEEN_NOTIFICATIONS', count)
      },

      //SOUNDS
      SET_PREVIEW_SOUND_ITEM({
        commit
      }, obj) {
        commit('SET_PREVIEW_SOUND_ITEM', obj)
        commit('PLAY_SOUND_ITEM', obj)
      },
      STOP_SOUND_ITEM({
        commit
      }) {
        commit('STOP_SOUND_ITEM')
      }
    }
  })
}
export default store
