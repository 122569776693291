import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  // set observer to true
  observer: true,
  lazyComponent: true,

  // optional
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.2
  }
})

// // or with options
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error: 'dist/error.png',
//   loading: 'dist/loading.gif',
//   attempt: 1
// })
