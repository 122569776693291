import Vue from 'vue'
import {
  Howl,
  Howler
} from 'howler';
import { compressToEncodedURIComponent } from 'lz-string';
import { mdiConsoleNetworkOutline } from '@mdi/js';

Vue.prototype.$elementari = {
  SpeechRecognition: null,
  SpeechTargetsList: [],
  goToLinkStatus: {},
  timeThresholdBetweenTwoGoToLink: 1000,
  page: null,
  reader: null,
  selectedBtnObj: null,
  nextFrameTime: 0,
  animationDelay: 27,
  shadow: {
    color: 'rgba(0,0,0,0.6)',
    blur: 10,
    offsetX: 10,
    offsetY: 10,
    opacity: 0.6,
    fillShadow: true,
    strokeShadow: true
  },
  glow: {
    color: 'rgba(0,0,255,1)',
    blur: 0,
    offsetX: 0,
    offsetY: 0,
    opacity: 1,
    fillShadow: true,
    strokeShadow: true
  },
  eventCodeCounter: 0,
  global_variables: {},
  highlightBlocksList: {},
  // collisionWatchList: {},
  eventBlocks: [
    'onPageStartBlock',
    // 'onTouchBlock',
    'onClickBlock',
    'onClickOnceBlock',
    'onDragOverBlock',
    'onDragOverOnceBlock',
    'onTextInputBlock',
    'onTextInputOnceBlock',
    'onPronounceBlock',
    'onCollisionBlock'
  ],

  blocksWithObjectPort: [
    'animateSpineBlock',
    'blinkBlock',
    'bounceBlock',
    'fadeBlock',
    'textEffectBlock',
    'changeImageBlock',
    'hideBlock',
    'moveToBlockAbsolute',
    'moveToBlockRelative',
    'rotateBlock',
    'scaleBlock',
    'restoreObjectBlock',
    'flipObjectBlock',
    'setObjectAngleBlock',
    'setObjectScaleBlock',
    'setObjectPositionBlock',
    'setDraggableBlock',
    'showBlock', 
    'textObjectEqualsValueBlock',
    'textObjectEqualsVariableBlock'
  ],

  blockWithLogicPort: [
    'branchBlock',    
    'numberComparisonBlock',
    'textComparisonBlock'
  ],

  setVarValBlocks: [
    'textVariableEqualsVariableBlock',
    'textVariableEqualsValueBlock',
    'textVariableEqualsTextObjectBlock',
    'numberVariableEqualsVariableBlock',
    'numberVariableEqualsValueBlock',
    'booleanVariableEqualsVariableBlock',
    'booleanVariableEqualsValueBlock'
  ],

  initEventStatus: function (eventObjectId, maxIterations) {
    this.reader.initEventStatus(eventObjectId, maxIterations)
  },

  setGlobalVariables: function(events) {
    // search for variables through each event page 
    // and store them into $elementari.global_variables
    for (let i = 0; i < events.length; i++) {
      for (let l = 0; l < events[i].length; l++) {
        if (events[i][l]['entities'] == undefined) continue
        
        var type = "Text"
        var value = ""
        if (events[i][l]['cssClass'] == 'createNumberVariables') {
          type = "Number"
          value = '0'
        } else if (events[i][l]['cssClass'] == 'createBooleanVariables') {
          type = "Boolean"
          value = 'false'
        }

        for (var m = 0; m < events[i][l]['entities'].length; m++) {
          this.reader.global_variables[events[i][l]['entities'][m]['text']] = {
            value: value,
            type: type
          }
        }
      }
    }
  },  
  getObjectBlockName: function(o){
    let obj_name
    if ('name' in o && o["name"] !== null){
      obj_name = o["name"]
    }else{
      obj_name = o["id"]
    }
    return obj_name
  },

  setPage: function (page, callback) {
    this.page = page
    typeof callback === 'function' && callback()
  },

  setPageScale: function (pageScale) {
    this.pageScale = pageScale
  },
  setReader: function (reader) {
    this.reader = reader
  },

  checkIfEventIsActive: function (eventCode) {
    if (this.reader.eventStatus[eventCode] && this.reader.eventStatus[eventCode].activeStack.length > 0) return true
    return false
  },

  checkIfEventCodeIsCompleted: function (options) {
    // console.log('checkIfEventCodeIsCompleted', options)
    // console.log('counter', this.reader.eventStatus[options.eventCode].counter)
    if (this.reader.eventStatus[options.eventCode] == undefined) {
      return false
    }

    if (this.reader.eventStatus[options.eventCode].activeStack.length > 0) return false

    if (options.currentIteration != this.reader.eventStatus[options.eventCode].counter) return false
    if (
      this.reader.eventStatus[options.eventCode].maxIterations > 0 &&
      this.reader.eventStatus[options.eventCode].counter >= this.reader.eventStatus[options.eventCode].maxIterations - 1
    ) {
      // make sure counter is reset before ending the loop
      this.reader.eventStatus[options.eventCode].counter = 0
      return false
    }

    this.reader.eventStatus[options.eventCode].counter++

    //  get event block from id and call it again
    const block = this.getBlock(options.eventCode)

    if (block == null) return false

    this.executeOnEventCode(block, this.getBlockConnections(block["id"]))

    return true

  },

  animateCanvas: function (self, time) {
    if (!this.page) {
      this.stopAllRendering()
      return
    }
    // if we're not animating anything or if desired delay
    // hasn't occurred, just request another loop and return
    if (time < this.nextFrameTime) {
      this.myRequestAnimation = requestAnimationFrame(function (time) {
        self.animateCanvas(self, time)
      })
      // this.animateCanvas(this, new Date())
      return
    }

    if (this.reader && !this.reader.isAnimating) {
      this.stopAllRendering()
      return
    }

    // set nextTime to the next elapsed time
    this.nextFrameTime = time + this.animationDelay
    // re-render everything
    this.page.requestRenderAll()
    // request another loop
    this.myRequestAnimation = requestAnimationFrame(function (time) {
      self.animateCanvas(self, time)
    })
  },

  stopAllRendering: function () {
    if (this.page) {
      this.page.requestRenderAll()
    }
    cancelAnimationFrame =
      window.cancelAnimationFrame || window.mozCancelAnimationFrame
    cancelAnimationFrame(this.myRequestAnimation)
    this.myRequestAnimation = null
    //reset list
    this.reader.animatedObjects = []
  },

  startObjectAnimationFrame: function (objectID, animationName) {
    let self = this
    this.reader.isAnimating = true

    this.reader.animatedObjects.push({
      id: objectID,
      animationName: animationName
    })

    if (this.myRequestAnimation) return

    requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame
    this.myRequestAnimation = requestAnimationFrame(function (time) {
      
      self.animateCanvas(self, time)
    })
  },

  stopObjectAnimationFrame: function (objectID, animationName) {
    for (var i = 0, l = this.reader.animatedObjects.length; i < l; i++) {
      if (
        this.reader.animatedObjects[i].id === objectID &&
        this.reader.animatedObjects[i].animationName === animationName
      ) {
        this.reader.animatedObjects.splice(i, 1)
        break
      }
    }

    if (!this.reader.animatedObjects.length) {
      this.reader.isAnimating = false
      if (this.reader.displayNextPageHint) {
        this.reader.displayNextPageHint(
          this.reader,
          this.reader.pageSelected,
          500
        )
      }
    }
  },

  goToPageBlock: function (self, block, callback) {

    // let pageNumber = parseInt(block['page'])


    let pageNumber = this.isValueAVariableField(block["page"])
    if (!this.isNumber(pageNumber, true)) return

    // if (isNaN(parseInt(block['page']))){
    //   // check if 'page' is a Variable
    //   if (this.reader.global_variables[block['page']] != undefined) {
    //     pageNumber = this.reader.global_variables[block['page']]['value']
    //   } else {
    //     return
    //   }
    // }
    let log_pageNumber
    if(Number(pageNumber.value) == 0){
      // is origin page
      log_pageNumber = this.reader.$t('studio.blocks.originPage')
    } else if(Number(pageNumber.value) == -1) { 
      log_pageNumber = this.reader.$t('actions.next').toLowerCase()
    } else if(Number(pageNumber.value) == -2) { 
      log_pageNumber = this.reader.$t('actions.previous').toLowerCase()
    }else{
      log_pageNumber= pageNumber.value
    }

    this.appendLogMsg(
      this.reader.$t('reader.inspect.goToPage', {
        page: log_pageNumber
      }),
      null,
      true
    )
    
    this.reader.goToPage(parseInt(pageNumber.value) - 1)
    callback()
  },

  goToLinkBlock: function (self, block, callback) {
    if (this.reader.eventStatus[block["eventCode"]] == undefined) return
    // check if tab are already open with url

    if (!this.goToLinkStatus[block["url"]]) {
      this.goToLinkStatus[block["url"]] = new Date()
    } else {
      // check if at least 1 second happend since last gotolink call
      const now = new Date()
      if (now.getTime() - this.goToLinkStatus[block["url"]].getTime() < this.timeThresholdBetweenTwoGoToLink) {
        this.timeThresholdBetweenTwoGoToLink = 60000
        return
      }
    }
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter

    this.appendLogMsg(this.reader.$t('reader.inspect.goToLink', {
      url: block['url']
    }))

    this.reader.goToLink(block["url"])
    // this.checkIfEventCodeIsCompleted(block)

    callback()
    
  },

  hideBlock: function (self, block, callback) {
    if (this.reader.eventStatus[block["eventCode"]] == undefined) return
    if (!this.page) return
    let o = this.page.getItemById(block["obj_id"])
    if (!o) return
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    o.visible = false

    this.appendLogMsg(this.reader.$t('reader.inspect.hide', {
      name: this.getObjectBlockName(o)
    }))

    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }
    // this.checkIfEventCodeIsCompleted(block)

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  showBlock: function (self, block, callback) {
    if (this.reader.eventStatus[block["eventCode"]] == undefined) return
    if (!this.page) return
    let o = this.page.getItemById(block["obj_id"])
    if (!o) return
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    o.visible = true
    o.opacity = 1

    this.appendLogMsg(this.reader.$t('reader.inspect.show', {
      name: this.getObjectBlockName(o)
    }))

    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }
    this.popObject(this, block)

    this.testCollision(this, block, o)

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  scaleBlock: function (self, block, callback) {
    if (!this.page) return

    let o = this.page.getItemById(block["obj_id"]),
      coeff = 1

    if (!o) return

    let pageSelected = self.reader.pageSelected
    let pageScale = self.reader.pageScales[self.reader.pageSelected]
    if (self.reader.isStudio) {
      pageScale =
        self.reader.pageScales[self.reader.pageSelected] *
        self.reader.project.pageScale[self.reader.pageSelected]
    }

    let duration = this.isValueAVariableField(block["duration"])
    if (!this.isNumber(duration, false)) {
      return
    } 

    let scale  = this.isValueAVariableField(block["scale"])
    
    if (!this.isNumber(scale, false)) {
      return
    }

    //check if object is being dragged
    if (o.dragAnimationTrigger) {
      coeff = 1.2
      scale *= coeff
    }

    if (o.isScaling) return

    this.appendLogMsg(this.reader.$t('reader.inspect.scale', {
      name: this.getObjectBlockName(o),
      factor: scale.value,
      duration: duration.value
    }))

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    self.startObjectAnimationFrame(block["obj_id"], 'scaleObject')
    o.isScaling = true

    // if (o._originalProperties && o._originalProperties.scaleX == undefined) {
    //   o._originalProperties.scaleX = o.scaleX
    //   o._originalProperties.scaleY = o.scaleY
    // }

    let easing = false
    if (block["easing"] != "default") {
      easing = fabric.util.ease['ease' + block["easing"]]
    }

    if (o.abortAnimation) {     
      delete o.abortAnimation
    }
    o.animate({
      scaleX: o.scaleX * scale.value,
      scaleY: o.scaleY * scale.value
    }, {
      duration: parseInt(duration.value) * 1000,
      easing: easing,
      abort: function () {
        console.log("aborting animation")
        
        if (!self.page || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(block["obj_id"], 'scaleObject')
          return true
        }

        if (o.abortAnimation) {
          
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          o.isScaling = false

          if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
            delete o.abortAnimation
          }

          return true
        }
        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
        
      },
      onChange: function () {
        // update LanczosFilter
        self.reader.setImageLanczosFilter(o)
        self.testCollision(self, block, o)
        //readjusting in case of page re-scaling
        // if (self.reader.pageScales[self.reader.pageSelected] !== pageScale) {
        //   o.left *=
        //     self.reader.pageScales[self.reader.pageSelected] / pageScale
        //   o.top *=
        //     self.reader.pageScales[self.reader.pageSelected] / pageScale
        // }

      },
      onComplete: function onComplete() {
        self.stopObjectAnimationFrame(block["obj_id"], 'scaleObject')
        if (self.page && pageSelected == self.reader.pageSelected) {
          o.isScaling = false
          //save original scale
          // if (o._originalProperties) {
          //   o._originalProperties.scaleX = o.scaleX / coeff
          //   o._originalProperties.scaleY = o.scaleY / coeff
          // }
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          // if (typeof callback !== 'function') {
          // const c = self.checkIfEventCodeIsCompleted(block)
          // }
          // if (c) return
          
          typeof callback === 'function' && callback()
        }
      }
    })
  },

  rotateBlock: function (self, block, callback) {
    if (!this.page) return

    let o = self.page.getItemById(block["obj_id"])

    if (!o) return
    if (o.isRotating) return
    let pageSelected = self.reader.pageSelected
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    self.startObjectAnimationFrame(block["obj_id"], 'rotateObject')
    o.isRotating = true
    //save original angle
    if (!o.originalAngle) {
      o.originalAngle = o.angle
    }

    let easing = false
    if (block["easing"] != "default") {
      easing = fabric.util.ease['ease' + block["easing"]]
    }
    
    let angle = this.isValueAVariableField(block["rotation"])
    let duration = this.isValueAVariableField(block["duration"])

    if (!this.isNumber(angle, true)) return
    if (!this.isNumber(duration, false)) return
    // if (this.isNumber(angle, true)) {
    //   angle = parseInt(angle)
    // } else {
    //   angle = parseInt(this.isValueAVariableField(block["rotation"]).value)
      
    //   if (!this.isNumber(angle, true)) return
    // }

    this.appendLogMsg(this.reader.$t('reader.inspect.rotate', {
      name: this.getObjectBlockName(o),
      angle: angle.value,
      duration: duration.value
    }))

    
    if (o.abortAnimation) {     
      delete o.abortAnimation
    }

    o.animate('angle', o.angle + angle.value, {
      duration: parseInt(duration.value) * 1000,
      easing: easing,
      abort: function () {
        
        if (!self.page || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(block["obj_id"], 'rotateObject')
          return true
        }

        if (o.abortAnimation) {
          
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          o.isRotating = false
          
          if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
            delete o.abortAnimation
          }

          return true
        }
        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
        
      },
      onChange: function () {
        self.testCollision(self, block, o)
      },
      onComplete: function onComplete() {
        self.stopObjectAnimationFrame(block["obj_id"], 'rotateObject')
        if (self.page && pageSelected == self.reader.pageSelected) {
          o.isRotating = false
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          typeof callback === 'function' && callback()
        }
      }
    })
  },

  waitBlock: function (self, block, callback) {
    
    const pageSelected = self.reader.pageSelected
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    let duration = this.isValueAVariableField(block["duration"])

    if (!this.isNumber(duration, false)) return

    this.appendLogMsg(this.reader.$t('reader.inspect.wait', {
      duration: duration.value
    }))

    self.reader.timeouts.push(
      setTimeout(function () {
        self.reader.timeouts.pop()
        if (pageSelected == self.reader.pageSelected) {
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          typeof callback === 'function' && callback()
        }
      }, parseFloat(duration.value) * 1000)
    )
  },

  moveToBlockAbsolute: function (self, block, callback) {
    let pageScale = self.reader.pageScales[self.reader.pageSelected]
    if (self.reader.isStudio) {
      pageScale =
        self.reader.pageScales[self.reader.pageSelected] *
        self.reader.project.pageScale[self.reader.pageSelected]
    }

    let o = self.page.getItemById(block["obj_id"])
    if (!o) return
    if (o.moveX && o.moveY) return

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    let pageSelected = self.reader.pageSelected
    self.startObjectAnimationFrame(block["obj_id"], 'moveToAbsolute')

    var duration = this.isValueAVariableField(block["duration"])
    if (!this.isNumber(duration, false)) return

    var testX = this.isValueAVariableField(block["xCoord"])
    if (!this.isNumber(testX, true)) return

    var testY = this.isValueAVariableField(block["yCoord"])
    if (!this.isNumber(testY, true)) return

    if (Number(testX.value) != 0) {
      o.moveX = true
    }
    if (Number(testY.value) != 0) {
      o.moveY = true
    }

    const coords = {}
    coords['left'] = Number(testX.value) * pageScale
    coords['top'] = Number(testY.value) * pageScale

    let easing = false
    if (block["easing"] != "default") {
      easing = fabric.util.ease['ease' + block["easing"]]
    }

    this.appendLogMsg(this.reader.$t('reader.inspect.moveToAbs', {
      name: this.getObjectBlockName(o),
      xCoord:  testX.value,
      yCoord: testY.value,
      duration: duration.value
    }))
    
    if (o.abortAnimation) {
      delete o.abortAnimation
    }
    
    o.animate(coords, {
      duration: parseInt(duration.value) * 1000,
      easing: easing,
      abort: function () {

        
        if (!self.page || !o || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(block["obj_id"], 'moveToAbsolute')
          return true
        }

        if (o.abortAnimation) {
          
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          if (Number(testX.value) != 0) {
            o.moveX = false
          }

          if (Number(testY.value) != 0) {
            o.moveY = false
          }

          if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
            delete o.abortAnimation
          }

          return true
        }

        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
        
      },
      onChange: function() {

        self.testCollision(self, block, o)
      //   //readjusting in case of page re-scaling
      //   if (self.reader.pageScales[self.reader.pageSelected] === pageScale) {
      //     return
      //   }
      //   o.left *= self.reader.pageScales[self.reader.pageSelected] / pageScale
      //   o.top *= self.reader.pageScales[self.reader.pageSelected] / pageScale
      },
      onComplete: function () {
        self.stopObjectAnimationFrame(block["obj_id"], 'moveToAbsolute')
        if (self.page && pageSelected == self.reader.pageSelected) {

          if (Number(testX.value) != 0) {
            o.moveX = false
          }

          if (Number(testY.value) != 0) {
            o.moveY = false
          }

          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          
          // const c =  self.checkIfEventCodeIsCompleted(block)
          
          // if (!c) {
          typeof callback === 'function' && callback()
          // }
          
        }
      }
    })
  },

  moveToBlockRelative: function (self, block, callback) {
    
    // let self = this
    let pageScale = self.reader.pageScales[self.reader.pageSelected]
    if (self.reader.isStudio) {
      pageScale =
        self.reader.pageScales[self.reader.pageSelected] *
        self.reader.project.pageScale[self.reader.pageSelected]
    }
    let o = self.page.getItemById(block["obj_id"]),
      pageSelected = self.reader.pageSelected
    if (!o) return
    if (o.moveX && o.moveY) return

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    self.startObjectAnimationFrame(block["obj_id"], 'moveToRelative')
    // o.move = true

    const coords = {}

    var testX = this.isValueAVariableField(block["xCoord"])
    if (!this.isNumber(testX, true)) return
    var testY = this.isValueAVariableField(block["yCoord"])
    if (!this.isNumber(testY, true)) return

    var duration = this.isValueAVariableField(block["duration"])
    if (!this.isNumber(duration, false)) return


    if (Number(duration.value) < 0) {
      duration.value = 0
    }
    if (Number(testX.value) != 0) {
      o.moveX = false
    }

    if (Number(testY.value) != 0) {
      o.moveY = false
    }

    if (Number(testX.value) != 0) {
      coords['left'] = o.left + (Number(testX.value) * pageScale)
    }

    if (Number(testY.value) != 0) {
      coords['top'] = o.top + (Number(testY.value) * pageScale)
    }

    if (!coords['left'] && !coords['top']) return

    let easing = false
    if (block["easing"] != "default") {
      easing = fabric.util.ease['ease' + block["easing"]]
    }

    this.appendLogMsg(this.reader.$t('reader.inspect.moveTo', {
      name: this.getObjectBlockName(o),
      xCoord: testX.value,
      yCoord: testY.value,
      duration: duration.value
    }))

    if (o.abortAnimation) {
      delete o.abortAnimation
    }
    
    o.animate(coords, {
      duration: parseInt(duration.value) * 1000,
      easing: easing,
      abort: function () {

        if (!o || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(block["obj_id"], 'moveToRelative')
          return true
        }

        if (o.abortAnimation) {

          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          if (Number(testX.value) != 0) {
            o.moveX = false
          }
      
          if (Number(testY.value) != 0) {
            o.moveY = false
          }

          if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
            delete o.abortAnimation
          }

          return true
        }

        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true

        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
      },
      onChange: function() {

        self.testCollision(self, block, o)
        //readjusting in case of page re-scaling
        // if (self.reader.pageScales[self.reader.pageSelected] === pageScale) {
        //   return
        // }
        // o.left *= self.reader.pageScales[self.reader.pageSelected] / pageScale
        // o.top *= self.reader.pageScales[self.reader.pageSelected] / pageScale
      },
      onComplete: function () {
        
        self.stopObjectAnimationFrame(block["obj_id"], 'moveToRelative')
        if (self.page && pageSelected == self.reader.pageSelected) {
          if (Number(testX.value) != 0) {
            o.moveX = false
          }
      
          if (Number(testY.value) != 0) {
            o.moveY = false
          }
          
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          
          // if (typeof callback !== 'function') {
            
          //   return
          // }
          // self.reader.eventStatus[block["eventCode"]].backOrder++

          // const c = self.checkIfEventCodeIsCompleted(block)
          
          // if (!c) {
          typeof callback === 'function' && callback()
          // } 
          
        }
      }
    })
  },

  testCollision: function (self, block, o) {
    if (o.visible == false) return
        
    if (!self.reader.collisionWatchList[block["obj_id"]]) return

    const threshold = self.reader.collisionWatchList[block["obj_id"]]['threshold']
    
    self.reader.collisionWatchList[block["obj_id"]]["targets"].forEach(function(target){
      
      if (target.visible == false) return
    
      if (!o.intersectsWithObject(target, false, true, threshold)) {

        for (var event_id in target['collisionWatch'][o.id]['events']) {
          if (target['collisionWatch'][o.id]['events'].hasOwnProperty(event_id)) {
            target['collisionWatch'][o.id]['events'][event_id]['collision_is_active'] = false
          }
        }
        return
      }
      
      for (var event_id in target["collisionWatch"][o.id]["events"]) {
        if (target["collisionWatch"][o.id]["events"].hasOwnProperty(event_id)) {
          
          if (target['collisionWatch'][o.id]['events'][event_id]['collision_is_active']) return

          let event = JSON.parse(JSON.stringify(target["collisionWatch"][o.id]["events"][event_id]))

          if (event.collisionTriggered) continue


          self.appendLogMsg(self.reader.$t('reader.inspect.onCollision', {
            name1: self.getObjectBlockName(o),
            name2: self.getObjectBlockName(target),
            
          }), true)

          self.reader.setTriggerObject(event.block["id"], target.id)
 
          let isExecuting = self.executeOnEventCode(event.block, event.conn, event.connection, true)

          if (isExecuting == false) return

          target['collisionWatch'][o.id]['events'][event_id]['collision_is_active'] = true
          
          // check if layout_obj is already evented
          if (event.block["once"] == true) {
            event.collisionTriggered = true
          }
          
          
        }
      }
    })
  },

  // flipObject: function (id, image, direction, center, easing) {
  //   let o = self.page.getItemById(id)

  //   if (!o) return

  //   if (o.flipping) return

  //   o.flipping = true
  //   let pageScale = self.reader.pageScales[self.reader.pageSelected]

  //   if (image != 'main') {
  //     o.srcArray.forEach(function (e, i) {
  //       if (e.title == image) {
  //         image = i
  //         return
  //       }
  //     })
  //   }

  //   if (!o.flipToggle) o.flipToggle = false

  //   let midToggle = true,
  //     url = null,
  //     targetOrigin = 'center',
  //     coeff = 1,
  //     scale = o.scaleX,
  //     flipProperty = 'flipX',
  //     scaleProperty = 'scaleX',
  //     originProperty = 'originX',
  //     positionProperty = 'left',
  //     dimensionProperty = 'width'
  //   switch (direction) {
  //     case 'right-to-left':
  //       if (!o.flipToggle) {
  //         if (!center) {
  //           o.originX = 'left'
  //           o.left -= (o.width / 2) * o.scaleX
  //           targetOrigin = 'right'
  //           coeff = -1
  //         }
  //         url = o.srcArray[image].url
  //       } else {
  //         if (!center) {
  //           o.originX = 'right'
  //           o.left += (o.width / 2) * o.scaleX
  //           targetOrigin = 'left'
  //         }
  //         url = o.srcArray[0].url
  //       }
  //       break
  //     case 'left-to-right':
  //       scale = o.scaleX
  //       flipProperty = 'flipX'
  //       scaleProperty = 'scaleX'
  //       originProperty = 'originX'
  //       positionProperty = 'left'
  //       dimensionProperty = 'width'
  //       if (!o.flipToggle) {
  //         if (!center) {
  //           o.originX = 'right'
  //           o.left += (o.width / 2) * o.scaleX
  //           targetOrigin = 'left'
  //         }
  //         url = o.srcArray[image].url
  //       } else {
  //         if (!center) {
  //           o.originX = 'left'
  //           o.left -= (o.width / 2) * o.scaleX
  //           targetOrigin = 'right'
  //           coeff = -1
  //         }
  //         url = o.srcArray[0].url
  //       }
  //       break
  //     case 'top-to-bottom':
  //       ;
  //       (scale = o.scaleY), (flipProperty = 'flipY')
  //       scaleProperty = 'scaleY'
  //       originProperty = 'originY'
  //       positionProperty = 'top'
  //       dimensionProperty = 'height'
  //       if (!o.flipToggle) {
  //         if (!center) {
  //           o.originY = 'bottom'
  //           o.top += (o.height / 2) * o.scaleY
  //           targetOrigin = 'top'
  //         }
  //         url = o.srcArray[image].url
  //       } else {
  //         if (!center) {
  //           o.originY = 'top'
  //           o.top -= (o.height / 2) * o.scaleY
  //           targetOrigin = 'bottom'
  //           coeff = -1
  //         }
  //         url = o.srcArray[0].url
  //       }
  //       break
  //     case 'bottom-to-top':
  //       ;
  //       (scale = o.scaleY),
  //       (flipProperty = 'flipY'),
  //       (scaleProperty = 'scaleY'),
  //       (originProperty = 'originY'),
  //       (positionProperty = 'top'),
  //       (dimensionProperty = 'height')
  //       if (!o.flipToggle) {
  //         if (!center) {
  //           o.originY = 'top'
  //           o.top -= (o.height / 2) * o.scaleY
  //           targetOrigin = 'bottom'
  //           // coeff = - 1;
  //         }
  //         url = o.srcArray[image].url
  //       } else {
  //         if (!center) {
  //           o.originY = 'bottom'
  //           o.top += (o.height / 2) * o.scaleY
  //           targetOrigin = 'top'
  //         }
  //         url = o.srcArray[0].url
  //       }
  //       break
  //   }

  //   self.startObjectAnimationFrame(id, 'flipObject')
  //   o.animate(scaleProperty, -scale, {
  //     duration: 1000,
  //     easing: easing,
  //     onComplete: function onComplete() {
  //       o.setCoords()
  //       o.flipToggle = !o.flipToggle
  //       o.flipping = false
  //       // o[flipProperty] =
  //       if (!center) {
  //         o[originProperty] = 'center'
  //         o[positionProperty] +=
  //           ((coeff * o[dimensionProperty]) / 2) * o[scaleProperty]
  //         self.page.requestRenderAll()
  //       }
  //       self.stopObjectAnimationFrame(id, 'flipObject')
  //     },
  //     onAbort: function () {
  //       if (!self.page) {
  //         return true
  //       }
  //     }
  //     // onChange: function() {
  //     //   //readjusting in case of page re-scaling
  //     //   o[scaleProperty] *=
  //     //     self.reader.pageScales[self.reader.pageSelected] / pageScale
  //     //   // look wether we passed 50% of the animation
  //     //   if (midToggle && o[scaleProperty] < scale * 0.05) {
  //     //     midToggle = false
  //     //     o[originProperty] = targetOrigin
  //     //     if (!center) {
  //     //       o[positionProperty] +=
  //     //         coeff * o[dimensionProperty] * o[scaleProperty]
  //     //     }
  //     //     o.setSrc(url, function() {})
  //     //   }
  //     //   // o["flipProperty"] = o.flipToggle;
  //     // }
  //   })
  // },

  // scrollingObject: function (id, speed, direction) {
  //   if (!this.page) return

  //   let self = this,
  //     o = self.page.getItemById(id),
  //     pageScale = self.reader.pageScales[self.reader.pageSelected]

  //   if (o.scrolling) return

  //   let reference = null,
  //     duration1 = null,
  //     duration2 = null,
  //     target = null
  //   o.scrolling = true

  //   if (direction === 'left-to-right') {
  //     reference = 'left'
  //     target = self.page.width + (o.width * o.scaleX) / 2
  //     duration1 =
  //       (1000 * (self.page.width - (o.left + (o.width * o.scaleX) / 2))) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //     duration2 =
  //       (1000 * (self.page.width - o.left)) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //   } else if (direction === 'right-to-left') {
  //     reference = 'left'
  //     target = (-o.width * o.scaleX) / 2
  //     duration1 =
  //       (1000 * o.left) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //     duration2 = duration1
  //   } else if (direction === 'bottom-to-top') {
  //     reference = 'top'
  //     target = (-o.height * o.scaleY) / 2
  //     duration1 =
  //       (1000 * o.top) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //     duration2 = duration1
  //   } else if (direction === 'top-to-bottom') {
  //     reference = 'top'
  //     target = self.page.height + (o.height * o.scaleY) / 2
  //     duration1 =
  //       (1000 * (self.page.height - (o.top + (o.height * o.scaleY) / 2))) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //     duration2 =
  //       (1000 * (self.page.height - o.top)) /
  //       (speed * self.reader.pageScales[self.reader.pageSelected])
  //   }

  //   self.startObjectAnimationFrame(id, 'scrollingObject')
  //   o.animate(reference, target, {
  //     duration: duration1,
  //     abort: function () {
  //       if (!self.page) {
  //         self.stopObjectAnimationFrame(id, 'scrollingObject')
  //         return true
  //       }
  //     },
  //     onChange: function () {
  //       //readjusting in case of page re-scaling
  //       o[reference] *=
  //         self.reader.pageScales[self.reader.pageSelected] / pageScale
  //     },
  //     onComplete: function onComplete() {
  //       if (!self.page) return

  //       //reset object position
  //       if (direction === 'left-to-right') {
  //         o.left = (-o.width * o.scaleX) / 2
  //       } else if (direction === 'right-to-left') {
  //         o.left = self.page.width + (o.width * o.scaleX) / 2
  //       } else if (direction === 'bottom-to-top') {
  //         o.top = self.page.height + (o.height * o.scaleY) / 2
  //       } else if (direction === 'top-to-bottom') {
  //         o.top = (-o.height * o.scaleY) / 2
  //       }
  //       // console.log(duration2)
  //       // console.log(o.left)
  //       o.animate(reference, target, {
  //         duration: duration2,
  //         abort: function () {
  //           if (!self.page) {
  //             self.stopObjectAnimationFrame(id, 'scrollingObject')
  //             return true
  //           }
  //         },
  //         onChange: function () {
  //           o[reference] *=
  //             self.reader.pageScales[self.reader.pageSelected] / pageScale
  //         },
  //         onComplete: function () {
  //           if (!self.page) return
  //           // if (direction === "left-to-right") {
  //           //     o.left = -o.width * o.scaleX / 2;
  //           // } else if (direction === "right-to-left") {
  //           //     o.left = self.options.pageWidth + o.width * o.scaleX / 2;
  //           // } else if (direction === "bottom-to-top") {
  //           //     o.top = self.options.pageHeight + o.height * o.scaleY / 2;
  //           // } else if (direction === "top-to-bottom") {
  //           //     o.top = -o.height * o.scaleY / 2;
  //           // }
  //           onComplete()
  //         }
  //       })
  //     }
  //   })
  // },

  popObject: function (self, block) {
    if (!this.page) return

    let o = self.page.getItemById(block["obj_id"])

    let pageScale = self.reader.pageScales[self.reader.pageSelected],
      pageSelected = self.reader.pageSelected
    // if (self.reader.isStudio) {
    //   pageScale =
    //     self.reader.pageScales[self.reader.pageSelected] *
    //     self.reader.project.pageScale[self.reader.pageSelected]
    // }

    if (o.pop) return

    self.startObjectAnimationFrame(block["obj_id"], 'popObject')
    o.originalScaleY = o.scaleY
    o.pop = true
    block['soundUrl'] = '/sounds/pop-effect.wav'
    block['volume'] = 30
    block['pageNumber'] = self.reader.pageSelected
    block['dontAddToActiveStack'] = true
    self.playSoundEffectBlock(self, block)
    o.animate('scaleY', o.scaleY * 1.2, {
      duration: 100,
      easing: fabric.util.ease.easeOutExpo,
      abort: function () {
        
        

        if (!self.page || pageSelected != self.reader.pageSelected) {

          self.stopObjectAnimationFrame(block["obj_id"], 'popObject')
          return true
        }

        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
        
        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true

      },
      onChange: function () {
        //readjusting in case of page re-scaling
        o.scaleY *=
          self.reader.pageScales[self.reader.pageSelected] / pageScale
      },
      onComplete: function onComplete() {
        if (self.page != null) {
          o.animate('scaleY', o.originalScaleY, {
            duration: 400,
            easing: fabric.util.ease.easeOutElastic,
            abort: function () {              
              if (!self.page || pageSelected != self.reader.pageSelected) {
                self.stopObjectAnimationFrame(block["obj_id"], 'popObject')
                return true
              }

              if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
              if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
              
            },
            onChange: function () {
              //readjusting in case of page re-scaling
              o.scaleY *=
                self.reader.pageScales[self.reader.pageSelected] / pageScale
            },
            onComplete: function () {
              if (self.page) {
                if (o.scaleY != o.originalScaleY) {
                  onComplete()
                } else {
                  o.pop = false
                  self.stopObjectAnimationFrame(block["obj_id"], 'popObject')
                  if (!block['dontPopActiveStack']) {
                    self.reader.eventStatus[block["eventCode"]].activeStack.pop()
                    self.checkIfEventCodeIsCompleted(block)
                  }
                  // o.originY = 'center'
                  // typeof callback === 'function' && callback()
                }
              }
            }
          })
          // self.stopObjectAnimationFrame(id, 'popObject')
        }
      }
    })

  },

  bounceBlock: function (self, block, callback) {
    if (!this.page) return

    let o = self.page.getItemById(block["obj_id"])
    let pageSelected = self.reader.pageSelected

    if (o.bounce) return
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    self.startObjectAnimationFrame(block["obj_id"], 'bounceObject')

    this.appendLogMsg(this.reader.$t('reader.inspect.bounce', {
      name: this.getObjectBlockName(o)
    }))

    o.bounce = true

    if (o.abortAnimation) {
      delete o.abortAnimation
    }

    o.animate(
      'top',
      o.top - 100 * self.reader.pageScales[self.reader.pageSelected], {
        duration: 700,
        easing: fabric.util.ease.easeOutExpo,
        abort: function () {
          
          if (!self.page || pageSelected != self.reader.pageSelected) {
            self.stopObjectAnimationFrame(block["obj_id"], 'bounceObject')
            return true
          }

          if (o.abortAnimation) {

            self.reader.eventStatus[block["eventCode"]].activeStack.pop()
            o.bounce = false

            if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
              delete o.abortAnimation
            }
            
            return true
          }

          if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
          if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
        },
        onChange: function() {
          
          self.testCollision(self, block, o)
          
          //readjusting in case of page re-scaling
          // o.left *=
          //   self.reader.pageScales[self.reader.pageSelected] / pageScale
          // o.top *=
          //   self.reader.pageScales[self.reader.pageSelected] / pageScale
        },
        onComplete: function onComplete() {
          if (self.page != null) {
            o.animate(
              'top',
              // o.top == o.originalTop ?
              // o.top -
              // 100 * self.reader.pageScales[self.reader.pageSelected] :
              // o.top +
              // 100 * self.reader.pageScales[self.reader.pageSelected],
              o.top + 100 * self.reader.pageScales[self.reader.pageSelected], {
                duration: 700,
                easing: fabric.util.ease.easeOutBounce,
                abort: function () {
                  
                  if (!self.page || pageSelected != self.reader.pageSelected) {
                    self.stopObjectAnimationFrame(block["obj_id"], 'bounceObject')
                    return true
                  }

                  if (o.abortAnimation) {

                    self.reader.eventStatus[block["eventCode"]].activeStack.pop()
                    o.bounce = false
        
                    if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
                      delete o.abortAnimation
                    }
                    
                    return true
                  }

                  if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
                  if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true

                },
                onChange: function() {
                  self.testCollision(self, block, o)
                  //   //readjusting in case of page re-scaling
                  //   o['top'] *=
                  //     self.reader.pageScales[self.reader.pageSelected] /
                  //     pageScale
                },
                onComplete: function () {
                  self.stopObjectAnimationFrame(block["obj_id"], 'bounceObject')
                  if (self.page && pageSelected == self.reader.pageSelected) {
                    o.bounce = false
                    self.reader.eventStatus[block["eventCode"]].activeStack.pop()

                    // const c = self.checkIfEventCodeIsCompleted(block)
                    
                    // if (c) return
                    
                    typeof callback === 'function' && callback()
                  }
                }
              }
            )
          }
        }
      }
    )

  },

  fadeBlock: function (self, block, callback) {


    if (!this.page) return

    let shift = 0,
      o = self.page.getItemById(block["obj_id"])

    let pageScale = self.reader.pageScales[self.reader.pageSelected],
      pageSelected = self.reader.pageSelected

    if (!o) return
    if (o.fade) return
    
    if (o.type === 'textbox') {
      o.ownCaching = false
      o.objectCaching = false
    }

    
    // o.ownCaching = false
    // o.objectCaching = false
   

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter

    let effect = 0
    if (block["effect"] == 'fade in') {
      effect = o._originalProperties.opacity * 100
      // effect = 100
    }

    if (o.visible && effect != 0) {
      o.visible = false
      o.opacity = 0
    }

    if (!o.visible && effect == 0) {
      o.visible = true
      o.opacity = o._originalProperties.opacity
      // o.opacity = 1
    }

    // if (o.visible && effect != 0 || !o.visible && effect == 0) {

    //   const t = self.checkIfEventCodeIsCompleted(block)

    //   if (t) return

    //   if (typeof callback == 'function') {
    //     typeof callback === 'function' && callback()
    //   }
    //   return
    // }

    if (block.dontAddToActiveStack == undefined) {
      self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
      self.startObjectAnimationFrame(block["obj_id"], 'fadeobject')

      if (effect == 100) {
        this.appendLogMsg(this.reader.$t('reader.inspect.fadeIn', {
          name: this.getObjectBlockName(o)
        }))
      } else {
        this.appendLogMsg(this.reader.$t('reader.inspect.fadeOut', {
          name: this.getObjectBlockName(o)
        }))
      }
    }

    if (block["direction"] === 'right-to-left') {
      block["direction"] = 'left'
      if (!o.visible) {
        o.left += 20 * pageScale
      }

      shift = -20
    } else if (block["direction"] === 'left-to-right') {
      block["direction"] = 'left'
      if (!o.visible) {
        o.left -= 20 * pageScale
      }
      shift = 20
    } else if (block["direction"] === 'top-to-bottom') {
      block["direction"] = 'top'
      if (!o.visible) {
        o.top -= 20 * pageScale
      }
      shift = 20
    } else if (block["direction"] === 'bottom-to-top') {
      block["direction"] = 'top'
      if (!o.visible) {
        o.top += 20 * pageScale
      }
      shift = -20
    }

    o.setCoords()
    o.fade = true
    self.reader.timeouts.push(
      setTimeout(() => {
        self.reader.timeouts.pop()

        let animateParams = {
          opacity: effect / 100
        }
        
        if (block["direction"] != 'none') {
          animateParams[block["direction"]] = parseInt(o[block["direction"]]) + shift * self.reader.pageScales[self.reader.pageSelected]
        }

        if (o.abortAnimation) {
          delete o.abortAnimation
        }
        
        o.visible = true
        o.animate(animateParams, {
          duration: 710,
          easing: fabric.util.ease.easeOutQuart,
          abort: function () {

            if (!self.page || pageSelected != self.reader.pageSelected) {
              self.stopObjectAnimationFrame(block["obj_id"], 'fadeobject')
              if (o.type === 'textbox') {
                o.ownCaching = true
                o.objectCaching = true
              }
              return true
            }

            if (o.abortAnimation) {
              
              self.reader.eventStatus[block["eventCode"]].activeStack.pop()
              o.fade = false

              if (!o.bounce && !o.isScaling && !o.isRotating && !o.moveX && !o.moveY && !o.fade) {
                delete o.abortAnimation
              }

              if (o.type === 'textbox') {
                o.ownCaching = true
                o.objectCaching = true
              }

              return true
            }

            if (self.reader.eventStatus[block["eventCode"]] === undefined) return true

            if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
            

          },
          onChange: function () {
            self.testCollision(self, block, o)
          },
          onComplete: function onComplete() {
            
            self.stopObjectAnimationFrame(block["obj_id"], 'fadeobject')
            if (self.page && pageSelected == self.reader.pageSelected) {
              if (effect === 0) {
                o.visible = false
              }
              o.fade = false
              if (o.type === 'textbox') {
                o.ownCaching = true
                o.objectCaching = true
              }

              if (block.dontAddToActiveStack == undefined) {
                self.reader.eventStatus[block["eventCode"]].activeStack.pop()
              }
              typeof callback === 'function' && callback()
            }
          }
        })
      }, 500)
    )
  },

  textEffectBlock: function (self, block, callback) {
    if (!this.page) return

    let o = self.page.getItemById(block["obj_id"])

    if (!o) return
    if (o.texteffect1) return

    

    let textLength = 0
    for (var i = 0; i < o._unwrappedTextLines.length; i++) {
      for (var j = 0; j < o._unwrappedTextLines[i].length; j++) {
        textLength += 1
      }
    }

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    self.startObjectAnimationFrame(block["obj_id"], 'textEffect')
    
    o._renderTextCommon(o.canvas.getContext('2d'), 'fillText')
    // this._renderTextCommon(ctx, 'fillText')

    o.texteffect1 = true
    
    o.originalStyles = JSON.parse(JSON.stringify(o.styles))

    let s = {
      fill: new fabric.Color(o.fill).setAlpha(0).toRgba()
      // fill: this.hexToRGBA(o.fill, 0),
    }

    // if (block["effect"] == 'pop') {
    //   s['fontSize'] = 0
    // }

    // get text color and convert to rgba
    o.setSelectionStyles(s, 0, o.text.length)

    let pageSelected = self.reader.pageSelected

    if (!o.fade) {
      let fade_options = JSON.parse(JSON.stringify(block))
      fade_options.effect = 'fade in'
      fade_options.direction = 'none'
      fade_options.dontAddToActiveStack = true
      self.fadeBlock(this, fade_options)
    }
    
    let speed = 40
    if (block["speed"] == 'fast') {
      speed = 60
    } else if (block["speed"] == 'medium') {
      speed = 100
    } else if (block["speed"] == 'slow') {
      speed = 200
    }

    if (block["effect"] == 'typeWriter') {
      this.appendLogMsg(this.reader.$t('reader.inspect.typeTextEffect', {
        name: this.getObjectBlockName(o)
      }))
    } else {
      this.appendLogMsg(this.reader.$t('reader.inspect.fadeTextEffect', {
        name: this.getObjectBlockName(o)
      }))
    }

    self.reader.timeouts.push(
      setTimeout(() => {

        self.reader.timeouts.pop()

        // necessary to avoid text flickering and text not being rendered
        if (o.type === 'textbox') {
          o.objectCaching = false
        }
        
        if (
          block["effect"] == 'typeWriter' ||
          block["effect"] == 'scrap'
        ) {

          o.texteffect2 = true

          let s = {
            speed: speed,
            pageSelected: pageSelected,
            block: block,
            textLength: textLength
          }

          self.typeTextEffect(o, 0, 0, 0, o._originalProperties.opacity, s,
            function () {
              self.stopObjectAnimationFrame(block["obj_id"], 'textEffect')
              if (pageSelected == self.reader.pageSelected) {
                o.texteffect1 = false
                o.texteffect2 = false
                if (o.type === 'textbox') o.objectCaching = true
                
                o.styles = JSON.parse(JSON.stringify(o.originalStyles))
                
                delete o.originalStyles
                self.reader.eventStatus[block["eventCode"]].activeStack.pop()

                typeof callback === 'function' && callback()
              }
            }
          )
          return
        // } else if (block["effect"] == 'fadeIn') {
        }

        let s = {
          totalSteps: 30,
          speed: speed,
          pageSelected: pageSelected,
          textLength: textLength,
          block: block
        }

        // here, adjust speed if necessary
        if (block["effect"] == 'pop') {

        } else if (block["effect"] == 'pan') {
          // horizontal slide in
        } else if (block["effect"] == 'rise') {
          // vertical slide in
        } else if (block["effect"] == 'wave') {
          // fade in then grow fontsize
          s["totalSteps"] = 300
        } else if (block["effect"] == 'tumble') {
          // rotate letter while slide in          
          // s["totalSteps"] = 20
          // s["speed"] = s["speed"] / 2
          o.tumbleAnimation = true
        } else if (block["effect"] == 'flipObject') {
          o.flipAnimation = true
        } else if (block["effect"] == 'scrap') {
          // pop up in random position then set in right position
        } else if (block["effect"] == 'stomp') {
          // like pop but all char pop at the same time
        } else if (block["effect"] == 'starfall') {
          
        } else if (block["effect"] == 'breathe') {
          o.breatheAnimation = true
          s["totalSteps"] = 150
        } else if (block["effect"] == 'whirlpool') {
          o.whirlpoolAnimation = true
        } else if (block["effect"] == 'roll') {
          o.rollAnimation = true
          s["totalSteps"] = 60
          s["thirdSteps"] = s["totalSteps"] / 3
        }

        self.fadeTextEffect(o, -1, 0, -1, s,
          function () {
            // end animation
            self.stopObjectAnimationFrame(block["obj_id"], 'textEffect')
            if (self.page && pageSelected == self.reader.pageSelected && o.originalStyles) {
              delete o.texteffect1
              delete o.texteffect2
              delete o.breatheAnimation
              delete o.whirlpoolAnimation
              delete o.tumbleAnimation
              delete o.flipAnimation
              delete o.rollAnimation
              if (o.type === 'textbox') o.objectCaching = true
              
              o.styles = JSON.parse(JSON.stringify(o.originalStyles))
              delete o.originalStyles
              self.reader.eventStatus[block["eventCode"]].activeStack.pop()

              typeof callback === 'function' && callback()
            }
          }
        )
        
      }, 1000)
    )
  },

  typeTextEffect: function (o, lineIndex, charIndex, index, opacity, options, callback) {
    
    if (this.reader.pageSelected != options.pageSelected) return

    if (o._text[index] == "\n") {
      // skip line break
      index++
      charIndex++
    }

    const urls = [
      '/sounds/typewriter-1.mp3',
      '/sounds/typewriter-2.mp3',
      '/sounds/typewriter-3.mp3',
      '/sounds/typewriter-4.mp3',
      '/sounds/typewriter-5.mp3',
      '/sounds/typewriter-6.mp3',
      '/sounds/typewriter-7.mp3',
      '/sounds/typewriter-8.mp3'
    ]

    this.reader.timeouts.push(
      setTimeout(() => {

        this.reader.timeouts.pop()

        if (o._unwrappedTextLines[lineIndex] == undefined) {
          if (options.block.effect == "typeWriter") {
            typeof callback === 'function' && callback()
          }
          return
        }
    
        // index++
        // charIndex++
        if (o._unwrappedTextLines[lineIndex][charIndex] == undefined) {
          charIndex = 0
          // index = index - 1
          lineIndex++
        }
    
        if (o._unwrappedTextLines[lineIndex] == undefined) {
          if (options.block.effect == "typeWriter") {
            typeof callback === 'function' && callback()
          }
          return
        }

        if (options.block.effect == "typeWriter") {
          this.playSoundEffectBlock(
            this, {
              url: urls[Math.floor(Math.random() * urls.length)],
              volume: 20,
              pageSelected: options.pageSelected,
              eventCode: options.block["eventCode"],
              dontAddToActiveStack: true
            }
          )
        }

        var style = {}
        style["fill"] = new fabric.Color(o.originalStyles[lineIndex][charIndex].fill).toRgba()

        if (options.block.effect == "scrap") {
          style["deltaX"] = this.getRandomNumber(400)
          style["deltaY"] = this.getRandomNumber(400)
        }

        o.setSelectionStyles(
          style,
          index,
          index + 1
        )
        // }

        if (options.block.effect == "scrap") {
          this.reader.timeouts.push(
            setTimeout(() => {
              
              if (this.reader.pageSelected != options.pageSelected) return

              this.reader.timeouts.pop()
              o.setSelectionStyles({
                  deltaX: 0,
                  deltaY: 0
                },
                index,
                index + 1
              )
                            
              if (index < options.textLength - 1) return
              
              typeof callback === 'function' && callback()

            }, options.speed * 10)
          )
        }
        
        if (index < o.text.length - 1) {
        // if (index < options.textLength - 1) {

          this.typeTextEffect(o, lineIndex, charIndex + 1, index + 1, opacity, options, function () {
            typeof callback === 'function' && callback()
          })
        } else if (options.block.effect == "typeWriter") {
          typeof callback === 'function' && callback()
        }
      }, options.speed)
    )
  },

  fadeTextEffect: function (o, index, lineIndex, charIndex, options, callback) {

    if (this.reader.pageSelected != options.pageSelected) return

    if (o._unwrappedTextLines[lineIndex] == undefined) {
      return
    }

    index++
    charIndex++

    if (o._text[index] == "\n") {
      // skip line break
      index++
      charIndex++
    }

    if (o._unwrappedTextLines[lineIndex][charIndex] == undefined) {
      charIndex = 0
      lineIndex++
    }

    if (o._unwrappedTextLines[lineIndex] == undefined) {
      return
    }
    
    const _index = index
    const _lineIndex = lineIndex
    const _charIndex = charIndex

    o.isAnimating = true
    this.reader.timeouts.push(
      setTimeout(() => {
        
        this.reader.timeouts.pop()
        if (o.objectCaching && o.type === 'textbox') o.objectCaching = false
        
        if (index < o.text.length) {
          
          this.fadeTextEffect(o, _index, _lineIndex, _charIndex,  options, function () {
            typeof callback === 'function' && callback()
          })
        }
      }, options.speed)
    )

    // this.fadeCharEffect(o, index, opacity, opacity / 30, 0, pageSelected, 0, block, function () {

    
    this.fadeCharEffect(o, _index, _lineIndex, _charIndex, 0, options, function () {
      o.isAnimating = false
      typeof callback === 'function' && callback()
    })
  },

  _fadeCharEffect: function (o, index, lineIndex, charIndex, step, options, callback) {

    if (this.reader.pageSelected != options.pageSelected)
      return;
    
    if (o.originalStyles && o.originalStyles[lineIndex] && o.originalStyles[lineIndex][charIndex]) {
          
      var style = {};

      if (step < 11) {
        style["fill"] = new fabric.Color(o.originalStyles[lineIndex][charIndex].fill).setAlpha(step / 10).toRgba()
      }

      if (options.block["effect"] == 'pop') {
        style["fontSize"] = o.originalStyles[lineIndex][charIndex].fontSize * this.easeOutElastic(step / options.totalSteps);
      } else if (options.block["effect"] == 'pan') {
        // horizontal slide in
        style["deltaX"] = 1 - options.totalSteps / step;
      } else if (options.block["effect"] == 'rise') {
        // vertical slide in
        style["deltaY"] = options.totalSteps / step - 1;
      } else if (options.block["effect"] == 'wave') {
        // fade in then grow fontsize
        style["fontSize"] = o.originalStyles[lineIndex][charIndex].fontSize * this.easeOutElastic(step / options.totalSteps);
        // } else if (options.block["effect"] == 'tumble') {
        // rotate letter while slide in
        // if (index % 2 == 1) {
        //   style["deltaX"] = options.totalSteps / step - 1
        //   style["angle"] = 360 * step / options.totalSteps
        // } else {
        //   style["deltaX"] = 1 - options.totalSteps / step 
        //   style["angle"] = -360 * step / options.totalSteps
        // }
      } else if (options.block["effect"] == 'flipObject') {
        style["angle"] = 360 * this.easeOutElastic(step / options.totalSteps);
      } else if (options.block["effect"] == 'whirlpool') {
        // rotate letter while slide in
        style["angle"] = 360 * this.easeOutElastic(step / options.totalSteps);
      } else if (options.block["effect"] == 'scrap') {
        // pop up in random position then set in right position
      } else if (options.block["effect"] == 'stomp') {
        // like pop but all char pop at the same time
      } else if (options.block["effect"] == 'starfall') {
        style["deltaY"] = -500 * this.easeInBounce((options.totalSteps - step) / options.totalSteps);
      } else if (options.block["effect"] == 'breathe') {
        // const t = o.originalStyles[options.loc["lineIndex"].toString()][options.loc["charIndex"].toString()].fontSize / 2;
        const t = o.originalStyles[lineIndex][charIndex].fontSize / 2;

        style["fontSize"] = t + t * this.easeOutBack(step / options.totalSteps);
      } else if (options.block["effect"] == 'roll') {
        if (options.thirdSteps >= step) {
          // fade in + vert down to 0
          style["deltaY"] = -100 * ((options.thirdSteps - step) / options.thirdSteps);
          let c = step / options.thirdSteps * 0.6
          if (c > 1) {
            c = 1
          }
        } else if (options.thirdSteps < step && 2 * options.thirdSteps >= step) {
          
          style["deltaY"] = 100 * ((step - options.thirdSteps) / options.thirdSteps);

        } else {
          const t = 2 * options.thirdSteps;
          style["deltaY"] = -100 * ((options.thirdSteps - (step - t)) / options.thirdSteps);

        }

      }
      
      o.setSelectionStyles(style, index, index + 1);
    }
    this.reader.timeouts.push(
      setTimeout(() => {
        this.reader.timeouts.pop();
        
        if (options.totalSteps > step) {
          step += 1;
          this.fadeCharEffect(o, index, lineIndex, charIndex, step, options, function () {
            typeof callback === 'function' && callback();
          });
        } else if (index >= options.textLength - 1) {
          typeof callback === 'function' && callback();
        }
      }, 30)
    );
  },
  get fadeCharEffect () {
    return this._fadeCharEffect;
  },
  set fadeCharEffect (value) {
    this._fadeCharEffect = value;
  },

  getRandomNumber: function(max) {
    const rand_sign = Math.random()
    let sign = 1
    if (rand_sign > 0.5) {
      sign = -1
    }

    return sign * Math.floor(Math.random() * max)

  },

  easeOutExpo: function(x) {
    return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
  },

  easeInExpo: function(x) {
    return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
  },

  easeOutBack: function (x) {
    const c1 = 1.70158;
    const c3 = c1 + 1;

    return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
  },

  easeInQuint: function (x) {
    // return 1 - Math.pow(1 - x, 5);
    // return x * x * x * x * x;
    return x * x * x * x * x;
  },

  easeOutQuint: function (x) {
    return 1 - Math.pow(1 - x, 5);
  },

  easeOutBounce: function(x) {
    const n1 = 7.5625;
    const d1 = 2.75;
    
    if (x < 1 / d1) {
        return n1 * x * x;
    } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
  },

  easeInBounce: function(x) {
    return 1 - this.easeOutBounce(1 - x);
  },
  
  easeInElastic: function(x) {
    const c4 = (2 * Math.PI) / 3;

    return x === 0
      ? 0
      : x === 1
      ? 1
      : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
  },


  easeOutElastic: function (x) {
    const c4 = (2 * Math.PI) / 3;

    return x === 0
      ? 0
      : x === 1
      ? 1
      : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
  },

  easeInOutElastic: function(x) {
    const c5 = (2 * Math.PI) / 4.5;
    
    return x === 0
      ? 0
      : x === 1
      ? 1
      : x < 0.5
      ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
      : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
  },
    
  hexToRGBA: function (hex, opacity) {
    hex = hex.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16),
      result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
    return result
  },

  blinkBlock: function (self, block, callback) {
    if (!this.page) return

    let o = self.page.getItemById(block["obj_id"]),
      pageSelected = self.reader.pageSelected

    if (o.blink) return
    o.blink = true

    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    self.startObjectAnimationFrame(block["obj_id"], 'blinkObject')

    this.appendLogMsg(this.reader.$t('reader.inspect.blink', {
      name: this.getObjectBlockName(o)
    }))

    o.animate('opacity', 0, {
      duration: 700,
      easing: fabric.util.ease.easeInExpo,
      abort: function () {        
        if (!self.page || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(block["obj_id"], 'blinkObject')
          return true
        }
        if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
        if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
        
      },
      onComplete: function onComplete() {
        if (self.page && pageSelected == self.reader.pageSelected) {
          o.animate('opacity', o._originalProperties.opacity, {
            duration: 700,
            easing: fabric.util.ease.easeInExpo,
            abort: function () {
              
              if (!self.page || pageSelected != self.reader.pageSelected) {
                self.stopObjectAnimationFrame(block["obj_id"], 'blinkObject')
                return true
              }

              if (self.reader.eventStatus[block["eventCode"]] === undefined) return true
              if (self.reader.eventStatus[block["eventCode"]].inspectMode !== $nuxt.$store.state.inspectMode) return true
              
            },
            onComplete: function () {
              self.stopObjectAnimationFrame(block["obj_id"], 'blinkObject')
              if (self.page && pageSelected == self.reader.pageSelected) {
                o.blink = false
                
                self.reader.eventStatus[block["eventCode"]].activeStack.pop()

                typeof callback === 'function' && callback()
              } else {
                self.stopObjectAnimationFrame(block["obj_id"], 'blinkObject')
              }
            }
          })
        } else {
          self.stopObjectAnimationFrame(block["obj_id"], 'blinkObject')
        }
      }
    })
  },

  animateSpineBlock: function (self, block, callback) {
    if (!this.page) return

    if (block["name"] == 'none') return

    let o = self.page.getItemById(block["obj_id"]),
      pageSelected = self.reader.pageSelected

    if (!o) return
    o.endless = true
    // o.skeleton.updateCache()
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    // push to event active stack
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    if (o.state.tracks[0]) {
      // o.state.data.setMix(o.state.tracks[0].name, options.animation, 0.5)
    } else {
      self.startObjectAnimationFrame(block["obj_id"], 'animateSpineObject')
    }
    o.state.setAnimation(0, block["name"], o.endless)
    o.state.tracks[0].name = block["name"]

    this.appendLogMsg(this.reader.$tc('reader.inspect.animateSpine', 
      parseInt(block["duration"]),
      {
        name: this.getObjectBlockName(o),
        animation: block['name'],
        duration: block['duration']
      }
    ))

    // return
    self.reader.timeouts.push(
      setTimeout(function () {
        self.reader.timeouts.pop()
        if (pageSelected != self.reader.pageSelected) return
        if (!o.state.tracks[0]) return
        
        if (parseInt(block["duration"]) == 0) return

        self.reader.eventStatus[block["eventCode"]].activeStack.pop()
        
        //check if object must stop being animated
        // if (
        //   o.state.tracks[0].name != block["animation"] &&
        //   o.state.tracks[0].name != 'none'
        // ) {
        //   self.reader.eventStatus[block["eventCode"]].activeStack.pop()
        //   // if (typeof callback !== 'function') {
        //   self.checkIfEventCodeIsCompleted(block)
        //   // }
        //   return
        // }

        // self.reader.eventStatus[block["eventCode"]].activeStack.pop()
        //stop all animations
        o.state.clearTrack(0)

        self.stopObjectAnimationFrame(block["obj_id"], 'animateSpineObject')

        typeof callback === 'function' && callback()
                      
      }, parseInt(block["duration"] * 1000))
    )
  },

  setVarVal: function (self, block, callback) {
    
    if (block["nameA"] == "none") {
      callback()
      return
    }    

    if (this.reader.eventStatus[block["eventCode"]] == undefined) return
    // check if nameB is undefined
    // if yes, block is
    if (typeof block["nameB"] == 'object') {
      if (block["nameB"].showplaceholder) {
        block["nameB"] = ''
      } else {
        block["nameB"] = block["nameB"].text 
      }
    } else if (typeof block["nameB"] == 'string') {
      block["nameB"] = this.transformStringValue(block["nameB"])
    }
    
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    // if variable is equal to variable block
    if (block["cssClass"] == "textVariableEqualsVariableBlock" || block["cssClass"] == "numberVariableEqualsVariableBlock"  || block["cssClass"] == "booleanVariableEqualsVariableBlock"){
      // get variable B current value
      // this.reader.global_variables[block["nameA"]]['value'] = this.reader.global_variables[block["nameB"]]['value']
      // this.reader.$set(this.reader.global_variables[block["nameA"]], 'value', this.reader.global_variables[block["nameB"]]['value'])
      this.reader.$set(this.reader.global_variables, block["nameA"], 
        {
          value: this.reader.global_variables[block["nameB"]]['value'],
          type: this.reader.global_variables[block["nameA"]]['type']
        }
      )
      
      this.appendLogMsg(this.reader.$t('reader.inspect.setVarVal', {
        variable: block["nameA"],
        value: this.reader.global_variables[block["nameB"]]['value']
      }))
    }else{
      // this.reader.global_variables[block["nameA"]]['value'] = block["nameB"]
      this.reader.$set(this.reader.global_variables, block["nameA"], 
        {
          value: block["nameB"],
          type: this.reader.global_variables[block["nameA"]]['type']
        }
      )

      this.appendLogMsg(this.reader.$t('reader.inspect.setVarVal', {
        variable: block["nameA"],
        value: block['nameB']
      }))

    }

    this.updateObjectsTextValue(true, block)
    // check for wait for glow list
    for (var i = 0, l = this.reader.waitForGlowList.length; i < l; i++) {
      // update condition
      var conn = this.getBlockConnections(this.reader.waitForGlowList[i]["event_id"])
      for (let m = 0; m < conn.length; m++) {
        if (conn[m]["target"]["port"] != "conditionLogicPort") continue
        this.reader.waitForGlowList[i].condition = this.generateConditionCode(conn[m]["source"]["node"])
        break
      }

      // retest glowobject
      var layout_obj = this.page.getItemById(this.reader.waitForGlowList[i].obj_id)
      if (layout_obj) {
        this.glowObject(
          this,
          layout_obj,
          this.reader.waitForGlowList[i].condition,
          block['id'],
          block['glow']
        )
      }
    }

    this.reader.setCookie(
      block["variable"],
      this.reader.global_variables[block["variable"]],
      'path=/stories/' + this.reader.project.slug
    )

    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      typeof callback === 'function' && callback()
    }, 600))
  },

  numberVariableEqualsRandomBlock: function (self, block, callback) {
    
    if (block["nameA"] == "none") {
      callback()
      return
    }

    if (this.reader.eventStatus[block["eventCode"]] == undefined) return

    var nameB = this.isValueAVariableField(block["nameB"])
    if (!this.isNumber(nameB, true)) return

    var nameC = this.isValueAVariableField(block["nameC"])
    if (!this.isNumber(nameC, true)) return

    nameB.value = Math.floor(parseInt(nameB.value))
    nameC.value = Math.ceil(parseInt(nameC.value))
    // block["nameC"] = Math.ceil(parseInt(block["nameC"]))
    // block["nameB"] = Math.floor(parseInt(block["nameB"]))

    // window[options.variable] = Math.floor(Math.random() * (options.max - options.min)) + options.min
    // this.reader.global_variables[block["nameA"]]['value'] = Math.floor(Math.random() * (block["nameB"] + 1 - block["nameC"])) + block["nameC"]
    this.reader.$set(this.reader.global_variables, block["nameA"], 
      {
        value: Math.floor(Math.random() * (nameB.value + 1 - nameC.value)) + nameC.value,
        type: this.reader.global_variables[block["nameA"]]['type']
      }
    )
    // post message in log if inspectmode == true
    this.appendLogMsg(this.reader.$t('reader.inspect.setRandomValue', {
      variable: block["nameA"],
      value: this.reader.global_variables[block["nameA"]]['value']
    }))

    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    this.updateObjectsTextValue(true, block)
    this.reader.setCookie(
      block["nameA"],
      this.reader.global_variables[block["nameA"]],
      'path=/stories/' + this.reader.project.slug
    )
    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  incrementBlock: function (self, block, callback) {
    if (block["variable"] == "none") {
      callback()
      return
    }


    if (this.reader.eventStatus[block["eventCode"]] == undefined) return

    var value = this.isValueAVariableField(block["value"])
    if (!this.isNumber(value, true)) return

    // this.reader.global_variables[block["variable"]]['value'] = parseFloat(this.reader.global_variables[block["variable"]]['value']) + parseInt(block["value"])
    this.reader.$set(this.reader.global_variables, block["variable"], 
      {
        value: parseFloat(this.reader.global_variables[block["variable"]]['value']) + parseInt(value.value),
        type: this.reader.global_variables[block["variable"]]['type']
      }
    )
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    this.appendLogMsg(this.reader.$t('reader.inspect.increment', {
      variable: block["variable"],
      value: block['value']
    }))

    this.updateObjectsTextValue(true, block)
    this.reader.setCookie(
      block["variable"],
      this.reader.global_variables[block["variable"]],
      'path=/stories/' + this.reader.project.slug
    )

    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  decrementBlock: function (self, block, callback) {

    if (block["variable"] == "none") {
      callback()
      return
    }

    if (this.reader.eventStatus[block["eventCode"]] == undefined) return

    let value = this.isValueAVariableField(block["value"])
    if (!this.isNumber(value, true)) return
    
    // this.reader.global_variables[block["variable"]]['value'] = parseFloat(this.reader.global_variables[block["variable"]]['value']) - parseInt(block["value"])
    this.reader.$set(this.reader.global_variables, block["variable"], 
      {
        value: parseFloat(this.reader.global_variables[block["variable"]]['value']) - parseInt(value.value),
        type: this.reader.global_variables[block["variable"]]['type']
      }
    )
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    this.appendLogMsg(this.reader.$t('reader.inspect.decrement', {
      variable: block["variable"],
      value: block['value']
    }))

    this.updateObjectsTextValue(true, block)

    this.reader.setCookie(
      block["variable"],
      this.reader.global_variables[block["variable"]],
      'path=/stories/' + this.reader.project.slug
    )

    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  insertTextBlock: function (self, block, callback) {

    if (block["variable"] == "none") {
      callback()
      return
    }

    if (this.reader.eventStatus[block["eventCode"]] == undefined) return

    let value = this.isValueAVariableField(block["value"])
    value.value = value.value.toString()    

    if (block["operation"] != "prepend") {
      // this.reader.global_variables[block["variable"]]["value"] = this.reader.global_variables[block["variable"]]["value"].toString().concat(block['value'])

      this.reader.$set(this.reader.global_variables, block["variable"], 
        {
          value: this.reader.global_variables[block["variable"]]["value"].toString().concat(value.value),
          type: this.reader.global_variables[block["variable"]]['type']
        }
      )
      // post message in log if inspectmode == true
      this.appendLogMsg(this.reader.$t('reader.inspect.insertTextBefore', {
        variable: block["variable"],
        value: value.value
      }))
    } else {
      // this.reader.global_variables[block["variable"]]["value"] = block["value"].concat(this.reader.global_variables[block["variable"]].toString())
      this.reader.$set(this.reader.global_variables, block["variable"], 
        {
          value: value.value.concat(this.reader.global_variables[block["variable"]].toString()),
          type: this.reader.global_variables[block["variable"]]['type']
        }
      )
      this.appendLogMsg(this.reader.$t('reader.inspect.insertTextBefore', {
        variable: block["variable"],
        value: value.value
      }))
    }
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    

    this.updateObjectsTextValue(true, block)

    this.reader.setCookie(
      block["variable"],
      this.reader.global_variables[block["variable"]],
      'path=/stories/' + this.reader.project.slug
    )
    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  setObjAttr: function (id, attribute, value, __callback) {
    // if (this.reader.eventStatus[block["eventCode"]] == undefined) return
    if (!this.page) return

    let o = this.page.getItemById(id)
    if (!o) return
    switch (attribute) {
      case 'background color':
        attribute = 'backgroundColor'
        break
      case 'text fill color':
        attribute = 'fill'
        break
      case 'text stroke color':
        attribute = 'stroke'
        break
      case 'flip horizontally':
        if (value === 'true' || value === 'yes') value = true
        if (value === 'false' || value === 'no') value = false
        attribute = 'flipX'
        break
      case 'flip vertically':
        if (value === 'true' || value === 'yes') value = true
        if (value === 'false' || value === 'no') value = false
        attribute = 'flipY'
        break
      case 'font family':
        attribute = 'fontFamily'
        break
      case 'font size':
        attribute = 'fontSize'
        break
      case 'font style':
        attribute = 'fontStyle'
        break
      case 'lock movement':
        if (value === 'true' || value === 'yes') value = true
        if (value === 'false' || value === 'no') value = false
        attribute = 'lockMovementX'
        break
      case 'text alignment':
        attribute = 'textAlign'
        break
      case 'source image':
        attribute = 'src'
        break
        // case "background color":
    }
    switch (attribute) {
      case 'text':
        o.text = value.toString()
        break
      case 'backgroundColor':
        o.set('backgroundColor', value)
        break
      case 'editable':
        o.editable = value
        break
      case 'fill':
        o.setColor(value)
        break
      case 'stroke':
        o.stroke = value
        break
      case 'flipX':
        o.flipX = value
        break
      case 'flipY':
        o.flipY = value
        break
      case 'fontFamily':
        o.fontFamily = '"' + value + '"'
        break
      case 'fontSize':
        o.fontSize = value
        break
      case 'fontStyle':
        o.fontStyle = value
        break
      case 'height':
        o.height = value * this.reader.pageScales[this.reader.pageSelected]
        break
      case 'left':
        o.left = value * this.reader.pageScales[this.reader.pageSelected]
        break
      case 'lockMovementX':
        o.lockMovementY = value
        o.lockMovementX = value
        break
      case 'opacity':
        value /= 100
        if (value > 1) {
          value = 1
        } else if (value < 0) {
          value = 0
        }
        o.opacity = value
        break
      case 'shadow':
        if (value === true) {
          o.shadow = this.shadow
        } else {
          o.shadow = null
        }

        break
      case 'textAlign':
        value = value.toLowerCase()
        o.textAlign = value
        break
      case 'top':
        o.top = value * this.reader.pageScales[this.reader.pageSelected]
        break
      case 'width':
        o.width = value * this.reader.pageScales[this.reader.pageSelected]
        break
      case 'src':
        o.srcArray.forEach(function (e) {
          if (e.title === value) {
            o.setSrc(e.url, function () {})
            return
          }
        })
        this.popObject(this, o.id)
        break
    }
    o.setColor(o.fill)
    o.setCoords()
    this.page.requestRenderAll()
    typeof __callback === 'function' && __callback()
  },

  glowObject: function (self, o, condition, eventId, makeObjectGlow) {

    if (!self.page) return

    var pageSelected = self.reader.pageSelected

    if (eventId) {
      this.reader.waitForGlowList.push({
        obj_id: o.id,
        condition: condition,
        event_id: eventId
      })
      // this.reader.waitForGlowList[eventId] = condition
    }

    if (this.parseBoolStr(condition) == false) {
      o.hoverCursor = 'default'
      o.stopGlow = true
      return
    }

    if (o.isGlowing) return

    o.hoverCursor = 'pointer'

    if (
      o.type == 'Textbutton' ||
      o.type == 'Quiz' ||
      o.type == 'Spine' ||
      o.type == 'textbox' ||
      o.type == 'textfield'
    )
      return

    if (makeObjectGlow == false) return

    self.startObjectAnimationFrame(o.id, 'glowObject')
    o.isGlowing = true
    o.stopGlow = false
    
    o.setShadow(self.glow)
    o.animate('shadow.blur', 30, {
      duration: 1000,
      easing: fabric.util.ease.easeInQuad,
      abort: function () {
        
        if (!o) {
          self.stopObjectAnimationFrame(o.id, 'glowObject')
          o.isGlowing = false
          return true
        }

        if (!o.evented || !self.page || o.stopGlow || pageSelected != self.reader.pageSelected) {
          o.setShadow({
            offsetX: 0,
            offsetY: 0,
            blur: 0,
            color: 'rgba(0,0,0,0.3)',
            opacity: 0.6
          })
          o.hoverCursor = 'default'
          o.clicked = false
          o.isGlowing = false
          self.stopObjectAnimationFrame(o.id, 'glowObject')
          return true
        }
      },
      onComplete: function onComplete() {
        if (!self.page || pageSelected != self.reader.pageSelected) {
          self.stopObjectAnimationFrame(o.id, 'glowObject')
          return
        }

        if (!o.shadow || o.stopGlow || !o.evented) {
          o.isGlowing = false
          self.stopObjectAnimationFrame(o.id, 'glowObject')
          return
        }

        o.animate('shadow.blur', o.shadow.blur === 30 ? 1 : 30, {
          duration: 1000,
          easing: fabric.util.ease.easeOutQuad,
          abort: function () {
            
            if (!self.page || pageSelected != self.reader.pageSelected) {
              self.stopObjectAnimationFrame(o.id, 'glowObject')
              return true
            }

            if (!o.evented || !self.page || o.stopGlow) {
              o.hoverCursor = 'default'
              o.setShadow({
                offsetX: 0,
                offsetY: 0,
                blur: 0,
                color: 'rgba(0,0,0,0.3)',
                opacity: 0.6
              })
              o.clicked = false
              o.isGlowing = false
              self.stopObjectAnimationFrame(o.id, 'glowObject')
              return true
            }            
            
          },
          onComplete: onComplete
        })
      }
    })
  },

  playSoundEffectBlock: function (self, block, callback) {
    // if (options.pageSelected != self.reader.pageSelected) return
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter

    if (block.dontAddToActiveStack == undefined) {
      self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    }
    
    const pageSelected = self.reader.pageSelected

    if (typeof block["volume"] == 'string') {
      block["volume"] = parseInt(block["volume"].slice(0, 2))
    }

    let soundUrl = block["url"]
    if (block["soundUrl"]){
      // case of change image, we use url for the image path => we want another another keyname for the sound url
      soundUrl = block["soundUrl"]
    }

    if (block["dontAddToActiveStack"] == undefined) {
      this.appendLogMsg(this.reader.$t('reader.inspect.playSoundEffect', {
        sound: block["sound"],
        artist: block["artist"]
      }))
    }
    // Check if the value is greater than 1
    let volume = block["volume"] / 50
    if (volume > 1) {
      volume = 1;
    }
    var sound = new Howl({
      src: [soundUrl],
      volume: volume,
      // html5: true,
      preload: true,
      onload: function () {
        // if (options.pageSelected != self.reader.pageSelected) return
        self.reader.soundEffects.push(sound)
        sound.play()
        if (self.reader.mute) {
          sound.mute(true)
        }
      },
      onplay: function () {
        if (self.reader.soundEffects.length == 0) return
        if (pageSelected != self.reader.pageSelected) {
          for (var i = 0, l = self.reader.soundEffects.length; i < l; i++) {
            self.reader.soundEffects[i].unload()
          }
        }
      },
      onend: function () {
        self.reader.soundEffects.forEach(function (e, i) {
          if (e._src == block["url"]) {
            self.reader.soundEffects.splice(i, 1)
            return
          }
        })
        if (pageSelected == self.reader.pageSelected) {
          if (block.dontAddToActiveStack == undefined) {
            self.reader.eventStatus[block["eventCode"]].activeStack.pop()
          }

          typeof callback === 'function' && callback()
        }
      }
    })
  },

  playRecordBlock: function (self, block, callback) {
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    const pageNumber = self.reader.pageSelected

    this.appendLogMsg(this.reader.$t('reader.inspect.playRecord', {
      sound: block["sound"],
      artist: block["artist"]
    }))

    // Check if the value is greater than 1
    let volume = parseInt(block["volume"]) / 100
    if (volume > 1) {
      volume = 1;
    }

    var sound = new Howl({
      src: [block["url"]],
      // html5: true,
      preload: true,
      volume: volume,
      onload: function () {
        if (pageNumber != self.reader.pageSelected) return
        self.reader.recordSounds.push(sound)
        sound.play()
        if (self.reader.mute) {
          sound.mute(true)
        }
      },
      onplay: function () {
        if (self.reader.recordSounds.length == 0) return

        if (pageNumber != self.reader.pageSelected) {
          sound.unload()
        }
      },
      onend: function () {
        self.reader.recordSounds.forEach(function (e, i) {
          if (e._src == block["url"]) {
            self.reader.recordSounds.splice(i, 1)
            return
          }
        })

        if (pageNumber == self.reader.pageSelected) {

          self.reader.displayNextPageHint(self.reader, pageNumber, 500)
          self.reader.eventStatus[block["eventCode"]].activeStack.pop()

          typeof callback === 'function' && callback()
        }
      }
    })
  },

  stopMusicBlock: function (self, block, callback) {
    if (!this.reader.music) return
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.reader.music.unload()
    this.reader.music = null

    this.appendLogMsg(this.reader.$t('reader.inspect.stopMusic'))

    this.reader.eventStatus[block["eventCode"]].activeStack.pop()

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  playMusicBlock: function (self, block, callback) {

    // if (options.pageNumber != self.reader.pageSelected) return
    block["currentIteration"] = self.reader.eventStatus[block["eventCode"]].counter
    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    let repeat = false
    if (block["repeat"] == "true") {
      repeat = true
    }

    const pageNumber = self.reader.pageSelected

    this.appendLogMsg(this.reader.$t('reader.inspect.playMusic', {
      sound: block["sound"],
      artist: block["artist"]
    }))

    if (!self.reader.music) {
      let volume = parseInt(block["volume"]) / 600
      if (volume > 1) {
        volume = 1;
      }
      self.reader.music = new Howl({
        src: [block["url"]],
        loop: repeat,
        volume: volume,
        // preload: true,
        // html5: true,
        onload: function () {
          if (!self.reader.music) return
          self.reader.music.play()
          if (self.reader.mute) {
            self.reader.music.mute(true)
          }
          // self.core.reader.music.fade(0, volume / 500, 1500);
        },
        onplay: function () {
          if (!self.reader.music) return
          if (self.reader.music._loop) return
          if (pageNumber != self.reader.pageSelected) {
            self.reader.music.unload()
          }
        },
        onend: function () {
          if (self.reader.music == null) return
          if (!self.reader.music._loop) {
            self.reader.music = null
          }

          if (pageNumber == self.reader.pageSelected) {
            self.reader.eventStatus[block["eventCode"]].activeStack.pop()
            
            typeof callback === 'function' && callback()
          }
        }
      })
    } else if (self.reader.music._src != block["url"]) {
      self.reader.music.fade(parseInt(block["volume"]) / 600, 0, 2000)
      self.reader.timeouts.push(setTimeout(() => {
        self.reader.timeouts.pop()
        let bg_sound = new Howl({
          src: [block["url"]],
          loop: repeat,
          volume: parseInt(block["volume"]) / 600,
          preload: true,
          // html5: true,
          onload: function () {
            if (!self.reader.music) return
            // setTimeout(function() {
            // if (self.reader.music) {
            self.reader.music.unload()
            // }
            self.reader.music = bg_sound
            bg_sound = null
            self.reader.music.play()
            if (self.reader.mute) {
              self.reader.music.mute(true)
            }
            // self.reader.music.fade(0, volume / 600, 2000)
            // }, 1500)
          },
          onplay: function () {
            if (!self.reader.music) return
            if (self.reader.music._loop) return
            if (pageNumber != self.reader.pageSelected) {
              self.reader.music.unload()
            }
          },
          onend: function () {
            if (!self.reader.music) return
            if (!self.reader.music._loop) {
              self.reader.music = null
            }
            if (pageNumber == self.reader.pageSelected) {
              self.reader.eventStatus[block["eventCode"]].activeStack.pop()
              typeof callback === 'function' && callback()
            }
          }
        })
      }, 1500))
    }
  },

  changeImageBlock: function (self, block, callback) {

    let o = this.page.getItemById(block["obj_id"])
    if (!o) return

    if (block["url"].includes($nuxt.$store.state.imagePrefixPath)){
      block["url"] = block["url"]
    }else{
      block["url"] = $nuxt.$store.state.imagePrefixPath + block["url"]
    }
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter

    if (o.src === block["url"] || o.sourcePath === block["url"]) {
      typeof callback === 'function' && callback()
      return
    }

    this.appendLogMsg(this.reader.$t('reader.inspect.changeImage', {
      name: this.getObjectBlockName(o)
    }))

    self.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    if (o.type == 'group' || o.type == 'path') {
      block["url"] = block["url"].replace('/assets/', '/svgs/')
      block["url"] = block["url"] + '?x-request=html'

      o.sourcePath = block["url"]
      o.objects = block["url"]
      fabric.loadSVGFromURL(block["url"], function (objects, options) {
        var obj = fabric.util.groupSVGElements(objects, block["url"])
        o._objects = obj._objects
      })
      self.popObject(self, block)
    } else {
      o.setSrc(
        block["url"],
        function () {
          self.popObject(self, block)
          typeof callback === 'function' && callback()
        }, {
          crossOrigin: 'Anonymous'
        }
      )
    }
    self.reader.eventStatus[block["eventCode"]].activeStack.pop()
  },

  arithmeticCalculatorBlock: function (self, block, callback) {
    if (block["nameA"] == "none" || block["nameB"] == "none") {
      typeof callback === 'function' && callback()
      return
    }



    try {
      // this.reader.global_variables[block["nameB"]]['value'] = String(eval(this.reader.global_variables[block["nameA"]]['value']))
      this.reader.$set(this.reader.global_variables, block["nameB"], 
        {
          value: String(eval(this.reader.global_variables[block["nameA"]]['value'])),
          type: this.reader.global_variables[block["nameB"]]['type']
        }
      )

      this.appendLogMsg(this.reader.$t('reader.inspect.calculate', {
        operation: this.reader.global_variables[block["nameA"]]['value']
      }))
    } catch (e) {
      // this.reader.global_variables[block["nameB"]]['value'] = 'error'
      this.reader.$set(this.reader.global_variables, block["nameB"], 
        {
          value: 'error',
          type: this.reader.global_variables[block["nameB"]]['type']
        }
      )

      this.appendLogMsg(this.reader.$t('reader.inspect.errors.cannotCalculate', {
        name: block["nameA"],
        operation: this.reader.global_variables[block["nameA"]]['value']
      }))
    }
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    this.updateObjectsTextValue(true, block)
    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  textObjectEqualsVariableBlock: function (self, block, callback) {
    let obj = this.page.getItemById(block["obj_id"])
    if (!obj) return
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter

    this.appendLogMsg(this.reader.$t('reader.inspect.textObjEqualsVariable', {
      variable: block["nameA"],
      value: this.reader.global_variables[block["nameA"]]['value']
    }))

    this.newTransformStringValue(obj, '{{' + block["nameA"] + '}}', obj.getSelectionStyles(0, obj.text.length, true))
    obj._originalProperties.text = obj.text

    const eventBlock = this.getBlock(block["eventCode"])
    let pop = true
    if (eventBlock['cssClass'] == "onPageStartBlock") pop = false
    if (pop) {
      this.popObject(this, block)
    } else if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }

    this.testCollision(this, block, obj)

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))

  },

  textObjectEqualsValueBlock: function (self, block, callback) {
    let obj = this.page.getItemById(block["obj_id"])
    if (!obj) return
    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter

    this.appendLogMsg(this.reader.$t('reader.inspect.textObjEqualsValue', {
      value: block["nameA"]
    }))

    // obj.text = this.transformStringValue(options.text)
    this.newTransformStringValue(obj, block["nameA"], obj.getSelectionStyles(0, obj.text.length, true))
    obj._originalProperties.text = obj.text

    let pop = true
    if (eventBlock['cssClass'] == "onPageStartBlock") pop = false

    if (pop) {
      this.popObject(this, block)
    } else if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }

    this.testCollision(this, block, obj)

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  newTransformStringValue(obj, original_text, original_style) {
    let isVariable = false
    // reset to original styles and value
    obj.removeChars(0, obj.text.length)

      do {
        original_style = original_style.concat(original_style)
      } while (original_text.length > original_style.length);
      //make sure original styles is same length as inserted varValue
      if (original_text.length <= original_style.length) {
        original_style.length = original_text.length
      }


    obj.insertChars(original_text, original_style, 0)

    // get substring between the 2 mustaches
    const start = original_text.indexOf('{{'),
      end = original_text.indexOf('}}')


    if (start == -1 || end == -1 || start >= end) return
    const substring = original_text.substring(start + 2, end).trim()
    const substring_start = original_text.indexOf(substring, start)
    // remove empty space and bracket {{
    obj.removeChars(start, substring_start)
    // remove empty space and }}
    obj.removeChars(obj.text.indexOf(substring, start) + substring.length, obj.text.indexOf('}}') + 2)

    // lookup if substring is a variable
    for (var varName in this.reader.global_variables) {
      if (varName != substring) continue
      isVariable = true
      break
    }

    let varValue = substring
    if (isVariable) {
      varValue = this.getVariableValue(substring)
      // check if value is a variable (variableEqualsVariableBlock)
    }
    const styles_start = obj.text.indexOf(substring, start)
    // get styles
    let original_styles = obj.getSelectionStyles(styles_start, styles_start + substring.length, true)
    // remove substring
    obj.removeChars(styles_start, styles_start + substring.length)

    do {
      original_styles = original_styles.concat(original_styles)
    } while (varValue.length > original_styles.length);
    //make sure original styles is same length as inserted varValue
    if (varValue.length <= original_styles.length) {
      original_styles.length = varValue.length
    }
    // insert variable value
    obj.insertChars(varValue, original_styles, styles_start)
    // call itself again for next  {{ variable }}
    this.newTransformStringValue(obj, obj.text, obj.getSelectionStyles(0, obj.text.length, true))
  },

  isValueAVariableField: function (original_value) {

    if (typeof original_value == 'number') {
      return { value: original_value }
    }

    const start = original_value.indexOf('{{'),
    end = original_value.indexOf('}}')

    if (start >= end) return { isVar: false, value: original_value }

    let substring = original_value

    if (start !== -1 || end !== -1) substring = original_value.substring(start + 2, end).trim()

    // lookup if substring is a variable
    for (var varName in this.reader.global_variables) {
      if (varName === substring) return { varName: substring, value: this.getVariableValue(substring)}
    }
    return { value: original_value }

  },

  getVariableValue: function (varName) {
    return String(this.reader.global_variables[varName]['value'])
  },

  transformStringValue: function (value, type ) {
    let isVariable = false
    let varType = null

    // get substring betwween the 2 mustaches
    const start = value.indexOf('{{'),
      end = value.indexOf('}}')

    if (start > -1 && end > -1 && start < end) {

      let substring = value.substring(start + 2, end).trim()

      for (var varName in this.reader.global_variables) {
        if (varName != substring) continue
        isVariable = true
        break
      }

      if (!isVariable) {

        value =
          value.substring(0, start) +
          substring +
          value.substring(end + 2, value.length)
      } else {

        value =
          value.substring(0, start) +
          this.reader.global_variables[substring].value.toString() +
          value.substring(end + 2, value.length)
      }
      return this.transformStringValue(value, 'Number')
    }

    if (varType == 'Number' || type == 'Number') {
      if (/^-?\d+$/.test(value)) {
        return parseInt(value)
      }
    }
    return value
  },

  lockSwipeRightBlock: function (self, block, callback) {
    this.page.lockSwipeRight = true

    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    // this.checkIfEventCodeIsCompleted(block)

    this.appendLogMsg(this.reader.$t('reader.inspect.lockNextPage'))

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  lockSwipeLeftBlock: function (self, block, callback) {
    this.page.lockSwipeLeft = true

    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    // this.checkIfEventCodeIsCompleted(block)

    this.appendLogMsg(this.reader.$t('reader.inspect.lockPreviousPage'))

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  updateObjectsTextValue: function (variableHasChanged, block) {

    if (this.reader === undefined) return

    let objects = this.page.getObjects()
    if (block) {
      block.dontPopActiveStack = false
    }

    for (var i = 0, l = objects.length; i < l; i++) {
      if (
        objects[i].type === 'textbox' ||
        objects[i].type === 'Textbutton' ||
        objects[i].type === 'speechbubble'
      ) {
        var oldText = objects[i].text
        this.newTransformStringValue(objects[i], objects[i]._originalProperties.text, objects[i]._originalProperties.style)

        if (variableHasChanged && oldText != objects[i].text && block["pop"]) {
          block["obj_id"] = objects[i].id
          this.popObject(this, block)
          block['dontPopActiveStack'] = true
        }
      }
    }
    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }

    if (!block) return
    // this.reader.eventStatus[block["eventCode"]].activeStack.pop()
    this.checkIfEventCodeIsCompleted(block)
  },

  flipObjectBlock: function (self, block, callback) {
    // this.reader.eventStatus[block["eventCode"]].activeStack.push(true)
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    var o = this.page.getItemById(block["obj_id"])

    if (!o) {
      typeof callback === 'function' && callback()  
      return
    }

    o[block["flip"]] = !o[block["flip"]]

    this.appendLogMsg(this.reader.$t('reader.inspect.flip', {
      name: this.getObjectBlockName(o)
    }))

    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))
  },

  setObjectPositionBlock: function (self, block, callback) {
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter    
    

    var o = this.page.getItemById(block["obj_id"])
    if (!o) {
      typeof callback === 'function' && callback()  
      return
    }

    this.reader.eventStatus[block["eventCode"]].activeStack.push(true)

    let pageScale = self.reader.pageScales[self.reader.pageSelected]

    var testX = this.isValueAVariableField(block["xCoord"])
    if (!this.isNumber(testX)) return
    var testY = this.isValueAVariableField(block["yCoord"])
    if (!this.isNumber(testY)) return

    o.left = Number(testX.value) * pageScale
    o.top = Number(testY.value) * pageScale

    this.appendLogMsg(this.reader.$t('reader.inspect.setObjectPosition', {
      name: this.getObjectBlockName(o),
      x: Number(testX.value),
      y: Number(testY.value)
    }))

    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }
    
    this.testCollision(this, block, o)
    
    this.reader.timeouts.push(setTimeout(() => {
      self.reader.eventStatus[block["eventCode"]].activeStack.pop()
      this.reader.timeouts.pop()
      callback()
    }, 600))

  },

  setDraggableBlock: function (self, block, callback) {
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter

    var o = this.page.getItemById(block["obj_id"])
    if (!o) {
      typeof callback === 'function' && callback()  
      return
    }
    
    let drag = false
    if (block["drag"] == "true") drag = true

    o.lockMovementX = !drag
    o.lockMovementY = !drag

    this.appendLogMsg(this.reader.$t('reader.inspect.setDraggable', {
      name: this.getObjectBlockName(o)
    }))

    if (this.reader.animatedObjects.length == 0) {
      this.page.requestRenderAll()
    }

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))

  },

  restoreObjectBlock: function (self, block, callback, o) {
    
    block["currentIteration"] = this.reader.eventStatus[block["eventCode"]].counter
    if (!o) {
      o = this.page.getItemById(block["obj_id"])
    }

    if (o && o._originalProperties) {
      
      if (o.bounce || o.isScaling || o.isRotating || o.moveX || o.moveY || o.fade) {
        o.abortAnimation = true
        setTimeout(() => {
          this.restoreObjectBlock(self, block, callback, o)  
        }, 100);
        return
      } else if (o.abortAnimation) {
        return
      }
      
      o.left = o._originalProperties.left * self.reader.pageScales[self.reader.pageSelected]
      o.top = o._originalProperties.top * self.reader.pageScales[self.reader.pageSelected]
      o.scaleX = o._originalProperties.scaleX * self.reader.pageScales[self.reader.pageSelected]
      o.scaleY = o._originalProperties.scaleY * self.reader.pageScales[self.reader.pageSelected]
      o.flipX = o._originalProperties.flipX
      o.flipY = o._originalProperties.flipY
      o.lockMovementX = o._originalProperties.lockMovementX
      o.lockMovementY = o._originalProperties.lockMovementY
      o.opacity = o._originalProperties.opacity

      this.appendLogMsg(this.reader.$t('reader.inspect.restoreObj', {
        name: this.getObjectBlockName(o)
      }))

      if (
        o.type === 'textbox' ||
        o.type === 'Textbutton' ||
        o.type === 'speechbubble' ||
        o.type === 'textfield'
      ) {
        o.removeChars(0, o.text.length)
        o.insertChars(o._originalProperties['text'], o._originalProperties['style'], 0)
      }
      // }

      o.setCoords()

      if (this.reader.animatedObjects.length == 0) {
        this.page.requestRenderAll()
      }
      this.testCollision(this, block, o)
    }

    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      callback()
    }, 600))

  },

  btnHandler: function (self, btn) {

    var tinycolor = require('tinycolor2')

    btn.on('mouseover', function() {
      // btn.set('btnBgColor', btn.btnBgColorActive)
      btn._originalProperties.btnBgColor = btn.btnBgColor
      btn.isHovered = true
      if (btn.isActive == true) return

      btn.set('btnBgColor', tinycolor(btn.btnBgColor).lighten(5).toString())  
      if (self.reader.animatedObjects.length > 0) return
      self.page.requestRenderAll()
    })

    btn.on('mouseout', function() {
      btn.set('btnBgColor', btn._originalProperties.btnBgColor)
      btn.isHovered = false
      if (self.reader.animatedObjects.length > 0) return
      self.page.requestRenderAll()
    })

    btn.on('mousedown', function() {
      btn.isActive = true
      btn.set('btnBgColor', tinycolor(btn._originalProperties.btnBgColor).darken(5).toString())
      if (self.reader.animatedObjects.length > 0) return
      self.page.requestRenderAll()
    })

    btn.on('mouseup', function() {
      btn.isActive = false
      if (btn.isHovered == true) {
        btn.set('btnBgColor', tinycolor(btn._originalProperties.btnBgColor).lighten(5).toString())
      } else {
        btn.set('btnBgColor', btn._originalProperties.btnBgColor)
      }
      
      if (self.reader.animatedObjects.length > 0) return
      self.page.requestRenderAll()
    })
  },

  parseBoolStr: function(str) {
    var expressions = {};
    var expressionRegex = new RegExp("\\((?:(?:!*true)|(?:!*false)|(?:&&)|(?:\\|\\|)|\\s|(?:!*\\w+))+\\)");
    var expressionIndex = 0;
    
    if (typeof str == 'string') {
      str = str.trim();
    }

    while (str.match(expressionRegex)) {
      var match = str.match(expressionRegex)[0];
      var expression = 'boolExpr' + expressionIndex;
      str = str.replace(match, expression);
      match = match.replace('(', '').replace(')', '');
      expressions[expression] = match;
      expressionIndex++;
    }
    return this.evalBoolStr(str, expressions);
  },

  evalBoolStr: function(str, expressions) {

    var conditions = str.split(' ');
    if (conditions.length > 0) {
      var validity = this.toBoolean(conditions[0], expressions);
      for (var i = 1; i + 1 < conditions.length; i += 2) {
        var comparer = conditions[i];
        var value = this.toBoolean(conditions[i + 1], expressions);
        switch (comparer) {
          case '&&':
            validity = validity && value;
            break;
          case '||':
            validity = validity || value;
            break;
        }
      }
      return validity;
    }
    return 'Invalid input';
  },

  toBoolean: function(str, expressions) {

    var inversed = 0;
    while (str.indexOf('!') === 0) {
      str = str.replace('!', '');
      inversed++;
    }
    var validity;
    if (str.indexOf('boolExpr') === 0) {
      validity = this.evalBoolStr(expressions[str], expressions);
    } else if (str == 'true' || str == 'false' || str == 'undefined') {
      validity = str == 'true';
    } else if (window[str]){
      validity = window[str]();
    }
    for (var i = 0; i < inversed; i++) {
      validity = !validity;
    }
    return validity;
  },

  initCode: function(blocks) {

    if (this.reader === undefined) return

    this.blocks = JSON.parse(JSON.stringify(blocks))

    // first get init setup code (e.g play text effect. needs to hide the text object on page start)
    for (var i = 0; i < blocks.length; i++) {

      if (!this.eventBlocks.includes(blocks[i]['cssClass'])) continue

      this.generateInitCode(blocks[i])

    }
  },

  generateCode: function (blocks) {
    // then execute regular script
    for (var i = 0; i < blocks.length; i++) {
      if (this.eventBlocks.includes(blocks[i]['cssClass'])) {

        if (blocks[i]['loop'] == undefined){
          blocks[i]['loop'] = 1
        }

        this.initEventStatus(blocks[i]['id'], parseInt(blocks[i]['loop']))
        
        this[blocks[i]['cssClass']](
          this,
          blocks[i],
          this.getBlockConnections(blocks[i]['id'])
        )
      }
    }
  },

  generateInitCode: function(block) {
    // console.log('generateInitCode', block)
    // get event block conn
    var conn = this.getBlockConnections(block['id'])
    for (var i = 0; i < conn.length; i++) {

      if (conn[i]['target']['node'] == block['id']) continue

      var nextBlock = this.getBlock(conn[i]['target']['node'])
      if (
        (nextBlock['cssClass'] == 'fadeBlock' && nextBlock['effect'] == "fade in") ||
        nextBlock['cssClass'] == 'textEffectBlock'
      ) {
        
        var nextConn = this.getBlockConnections(conn[i]['target']['node'])
        for (let l = 0; l < nextConn.length; l++) {
          // look up for connection to object object
          if (nextConn[l]["target"]["port"] != "objectPort") continue

          var layout_obj = this.page.getItemById(nextConn[l]["source"]["port"])

          if (layout_obj == null) continue
          layout_obj.opacity = 0
          layout_obj.visible = false
        }
      }
      // go to next function block
      this.generateInitCode(nextBlock)
    }
  },

  getBlock: function (id) {
    let block = null
    for (let i = 0; i < this.blocks.length; i++) {
      if (this.blocks[i]['id'] != id) continue
      block = this.blocks[i]
      break
    }
    return block
  },

  getBlockConnections: function (id) {
    let connections = []
    for (var i = 0; i < this.blocks.length; i++) {
      if (this.blocks[i]['cssClass'] != 'draw2d_Connection') continue
      if (this.blocks[i]['target']['node'] == id || this.blocks[i]['source']['node'] == id) {
        connections.push(this.blocks[i])
      }
    }
    return connections
  },

  getBlockOutputConnections: function(id) {
    let connections = []
    for (var i = 0; i < this.blocks.length; i++) {
      if (this.blocks[i]['cssClass'] != 'draw2d_Connection') continue
      if (this.blocks[i]['source']['node'] == id) {
        connections.push(this.blocks[i])
      }
    }
    return connections
  },

  checkLogicGatePorts: function (id, conn) {
    let portCounter = 0
      for (let i = 0; i < conn.length; i++) {
        if (conn[i]["target"]["node"] != id) continue
        portCounter += 1
      }
        
      if (portCounter > 0) return true

      return false    
  },

  getLogicGateChildren: function(id, conn) {
    let children = []
    for (let i = 0; i < conn.length; i++) {
      if (conn[i]["target"]["node"] != id) continue

      var child = {
        block: this.getBlock(conn[i]["source"]["node"]),
        conn: conn[i]
      }

      if (child) children.push(child)
    }
    return children
  },

  isNumber: function (text, canNegative) {

    var value = typeof text == 'object' ? text.value : text
    var varName = typeof text == 'object' ? text.varName : null
    
    if (typeof value == 'string' && value.includes('\u25be')) {
      var index = value.indexOf('\u25be')
      value = value.substring(0, index - 1)
    }

    const valueIsNumber = 
    (/^\d+$/.test(value) ||
      /^-\d+$/.test(value) ||
      this.isInt(parseInt(value)) ||
      this.isFloat(parseFloat(value))
    ) && !isNaN(value)
    
    if (!valueIsNumber) {
      this.appendLogMsg(
        this.reader.$t('reader.inspect.errors.valueIsNotNumber', {
          name: varName
        }),
        null,
        null,
        null,
        true
      )
      return false
    }

    

    if (value < 0) {
      if (!canNegative) {
        this.appendLogMsg(
          this.reader.$t('reader.inspect.errors.valueCannotBeNegativeNumber', {
            name: varName
          }),
          null,
          null,
          null,
          true
        )
        return false
      }
    }


    // if (canNegative) {
    //   if (
    //     isnum ||
    //     isNegativeNum ||
    //     this.isInt(parseInt(value)) ||
    //     this.isFloat(parseFloat(value))
    //   ) {
    //     if (!isNaN(value)) {
    //       // testNumber = true
    //       valueIsNotNumber = true
    //     }
    //   }
    // } else if (
    //   (this.isInt(parseInt(value)) || this.isFloat(parseFloat(value))) &&
    //   value >= 0
    // ) {
    //   // testNumber = true
    //   valueIsNegative = true
    // }

    // if (!isnum || !testNumber) {
    //   // display error log in inspector
    //   if (canNegative)

    //   return false
    // }
    return true
  },
  
  isInt: function (n) {
    return Number(n) === n && n % 1 === 0
  },

  isFloat: function (n) {
    return Number(n) === n && n % 1 !== 0
  },

  generateConditionCode: function(id, term1, term2) {

    if (term1 == undefined) term1 = ''
    if (term2 == undefined) term2 = ''
    let result = "false"
    let logicConnections = this.getBlockConnections(id)
    let block = this.getBlock(id)

    if (block == null) return result

    if (block['cssClass'] == "ANDGateBlock" || block['cssClass'] == "ORGateBlock") {
      if (this.checkLogicGatePorts(id, logicConnections)) {
        const children = this.getLogicGateChildren(id, logicConnections)
        // test 1st child
        if (children[0]["block"]["cssClass"] == "variableBlock") {
          
          this.highlightBlock({
            block:  children[0]["block"],
            hasTimeout: true,
            color: "#ffeb3b"
          })

          if (this.reader.global_variables[children[0]["block"]["name"]] == undefined) {
            term1 = "false"
          } else {
            term1 = this.reader.global_variables[children[0]["block"]["name"]]["value"].toString()
            // highlight connection

            if (term1 == "true") {
              this.highlightConnection({
                conn:  children[0]["conn"],
                hasTimeout: true,
              })
            }
          }
        } else if (children[0]["block"]["cssClass"] == "ANDGateBlock" || children[0]["block"]["cssClass"] == "ORGateBlock") {
          term1 = this.generateConditionCode(children[0]["block"]["id"], term1, term2)
        }    
        
        if (children.length > 1) {
          // test 2nd child
          if (children[1]["block"]["cssClass"] == "variableBlock") {

            this.highlightBlock({
              block:  children[1]["block"],
              hasTimeout: true,
              color: "#ffeb3b"
            })
            
            if (this.reader.global_variables[children[1]["block"]["name"]] == undefined) {
              term2 = "false"
            } else {
              term2 = this.reader.global_variables[children[1]["block"]["name"]]["value"].toString()
              
              if (term2 == "true") {
                this.highlightConnection({
                  conn:  children[1]["conn"],
                  hasTimeout: true
                })
              }
            }
          } else if (children[1]["block"]["cssClass"] == "ANDGateBlock" || children[1]["block"]["cssClass"] == "ORGateBlock") {
            term2 = this.generateConditionCode(children[1]["block"]["id"], term1, term2)
          }
        } else {
          term2 = "false"
        }
        

        if (block["cssClass"] == "ANDGateBlock") {

          if ($nuxt.$store.state.inspectMode && this.parseBoolStr(term1) == true && this.parseBoolStr(term2) == true) {

            for (let i = 0; i < logicConnections.length; i++) {
              if (logicConnections[i]["source"]["node"] != block["id"]) continue

              this.highlightConnection({
                conn: logicConnections[i],
                hasTimeout: true
              })
            }

            this.highlightBlock({
              block:  block,
              hasTimeout: true,
              color: "#ffeb3b"
            })
          }

          result = term1 + " && " + term2
        } else {

          if ($nuxt.$store.state.inspectMode && (this.parseBoolStr(term1) == true || this.parseBoolStr(term2) == true)) {

            for (let i = 0; i < logicConnections.length; i++) {
              if (logicConnections[i]["source"]["node"] != block["id"]) continue

              this.highlightConnection({
                conn: logicConnections[i],
                hasTimeout: true
              })
            }

            this.highlightBlock({
              block:  block,
              hasTimeout: true,
              color: "#ffeb3b"
            })
          }

          result = term1 + " || " + term2
        }      
      }
    } else if (block["cssClass"] == "variableBlock") {
      if (this.reader.global_variables[block["name"]] == undefined) {
        result = "false"
      } else {
        result = this.reader.global_variables[block["name"]]["value"].toString()

        this.highlightBlock({
          block:  block,
          hasTimeout: true,
          color: "#ffeb3b"
        })

        if (result == "true") {
 
          for (let i = 0; i < logicConnections.length; i++) {
            if (logicConnections[i]['source']['node'] != block["id"]) continue

            this.highlightConnection({
              conn: logicConnections[i],
              hasTimeout: true
            })
          }
        }
      }
    }
    return result
  },

  onPageStartBlock: function (self, block, conn) {
    // console.log('onPageStartBlock', block)
    // loop through block connections
    // search uniquely for conditionLogicPort first
    let condition = 'true'
    let hasOutputFlowConn = false
    for (let i = 0; i < conn.length; i++) {
      if (conn[i]["target"]["port"] == "conditionLogicPort") {
        condition = this.generateConditionCode(conn[i]["source"]["node"])  
      }

      if (conn[i]["source"]["node"] == block["id"]) {
        hasOutputFlowConn = true
      }
    }
    // test if event block has output connections
    if (hasOutputFlowConn == false) return
    // // test if condition is true or false
    // // if false, return and do nothing
    if (this.parseBoolStr(condition) == false) return
    // // init event status

    var delay = this.isValueAVariableField(block["delay"])

    if (!this.isNumber(delay, false)) return

    if (block['loop'] == undefined) block['loop'] = 1

    if (Number(delay.value) > 0) {
     
      this.reader.timeouts.push(
        setTimeout(() => {
           // highlight Event block
          this.highlightBlock({
            block: block
          })
          
          this.reader.timeouts.pop()
          this.executeOnEventCode(block, conn, null, true)
        }, parseFloat(delay.value) * 1000)
      )
      return
    }

    // highlight Event block
    this.highlightBlock({
      block: block
    })
    this.executeOnEventCode(block, conn, null, true)

  },

  // onTouchBlock: function (self, block, conn) {
  //   // loop through block connections
  //   // search uniquely for conditionLogicPort first
  //   let condition = 'true', hasOutputFlowConn = false
  //   for (let l = 0; l < conn.length; l++) {

  //     if (conn[l]["target"]["port"] == "conditionLogicPort") {
  //       condition = this.generateConditionCode(conn[l]["source"]["node"])  
  //     }

  //     if (conn[l]["source"]["node"] == block["id"]) {
  //       hasOutputFlowConn = true
  //     }
  //   }

  //   // test if event block has output connections
  //   if (hasOutputFlowConn == false) return
    
  //   for (let i = 0; i < conn.length; i++) { 

  //     if (conn[i]["target"]["port"] != "objectPort") continue

  //     var layout_obj = this.page.getItemById(conn[i]["source"]["port"])

  //     if (layout_obj == null) return
  //     // make object glow
  //     this.glowObject(this, layout_obj, condition, block['id'])
      
  //     layout_obj.on('mouseup', function () {
  //       self.reader.disableObjectIsTouched()
  //     })
      
  //     // add mouse up listerner to trigger the event
  //     layout_obj.on('mousedown', function () {

  //       self.reader.enableObjectIsTouched()

  //       conn[i]['name'] = layout_obj['name']

  //       const isExecuting = self.executeOnEventCode(block, conn, conn[i], true)

  //       if (isExecuting == false) return

  //       // check if layout_obj is already evented
  //       if (block["once"] == true) {
  //         layout_obj.evented = false
  //         // look for other objects linked to the same event to disable them as well
  //         for (let n = 0; n < conn.length; n++) { 

  //           if (conn[n]["target"]["port"] != "objectPort") continue

  //           var other_layout_obj = self.page.getItemById(conn[n]["source"]["port"])

  //           if (other_layout_obj == null) continue

  //           other_layout_obj.evented = false
  //         }
  //       }
  //     })
  //   }
  // },

  onPronounceBlock: function (self, block, conn) {
    // loop through block connections
    // search uniquely for conditionLogicPort first
    let condition = 'true'
    let hasOutputFlowConn = false
    for (let i = 0; i < conn.length; i++) {
      if (conn[i]["target"]["port"] == "conditionLogicPort") {
        condition = this.generateConditionCode(conn[i]["source"]["node"])  
      }

      if (conn[i]["source"]["node"] == block["id"]) {
        hasOutputFlowConn = true
      }
    }
    // test if event block has output connections
    if (hasOutputFlowConn == false) return
    // // test if condition is true or false
    // // if false, return and do nothing
    if (this.parseBoolStr(condition) == false) return
    // // init event status
    if (block['loop'] == undefined) block['loop'] = 1


    let word = this.transformStringValue(block["word"])
    block.array_words = word.toLowerCase().split(" ")

    // block.array_words = word.value.toLowerCase().split(" ")
    // store block with string to recognize
    // this.SpeechTargetsList.push({
    //   block: block,
    //   conn: conn
    // })

    this.reader.initSpeechRecognition(this.reader, block, conn)
    this.reader.isRecording = true
    // if (Object.keys(this.reader.SpeechRecognition).length !== 0) return
    
    // let SpeechRecognition = SpeechRecognition || window.webkitSpeechRecognition
    // let SpeechRecognitionEvent = SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent
    
    // this.reader.SpeechRecognition = new SpeechRecognition();

    // this.reader.SpeechRecognition.continuous = true;
    // this.reader.SpeechRecognition.lang = $nuxt.$store.state.StudioProps.languages[block.lang]
    // this.reader.SpeechRecognition.interimResults = false;
    // this.reader.SpeechRecognition.maxAlternatives = 1;

    // this.reader.SpeechRecognition.start();

    // this.reader.SpeechRecognition.onend = function(event) {
      
    //   if (self.reader.not_transition && self.reader.pageSelected != null) {
    //     // console.log('onend')
    //     self.reader.SpeechRecognition.start();
    //   }
    // }
    
    // this.reader.SpeechRecognition.onresult = function(event) {
    //   var res = event.results[event.results.length - 1][0].transcript.toLowerCase();
    //   self.searchForSpeechMatch(res.split(" "))
    // }
  },

  searchForSpeechMatch: function(self, res) {
    
    let full_match = false
    let colored = res.split(" ").map(x => {
        return {
          word: x,
          match: null
        }
    })

    const array_res = res.toLowerCase().split(" ")
    let post_log = false
    let indexes_to_delete = []

    this.reader.speechTargetsList.forEach(function(e, index) {

      if (e.block.word == undefined) return

      let match_counter = 0
      let target_index = 0
      let v = false
      let event_triggered = false
      
      for (var i = 0; i < array_res.length; i++) {
        
        if (e.block.array_words.includes(array_res[i])) {
          // turning word yellow
          colored[i].match = false
        }
        
        for (var l = target_index; l < e.block.array_words.length; l++) {
        
          if (e.block.array_words[l] !== array_res[i]) {
            match_counter = 0
            full_match = false
            
          } else {
            v =true
            // colored[i].match = false
            match_counter++
          }

          if (match_counter == e.block.array_words.length) {
            full_match = true
            post_log = true

            // turning words green
            for (var m = 0; m < match_counter; m++) {
              colored[i - m].match = true
            }

            if (event_triggered == false) {

              event_triggered = true

              self.highlightBlock({
                block: e.block
              })
              self.executeOnEventCode(e.block, e.conn, null, true)
              if (e.block["once"]) {
                indexes_to_delete.push(index)
              }              
            }            
            match_counter = 0
          }

          if (match_counter != 0) {
            if (e.block.array_words.length > l + 1) {
              target_index = l + 1
            }
            break
          } else {
            if (v) {
              v = false
              l = l - 2
              if (l < 0) l = 0
              continue
            }
            target_index = 0
          }
        }
      }
    })


    this.reader.removeFromSpeechTargetsList(indexes_to_delete)
    
    self.appendLogMsg(colored, false, false, true)

    return colored
  },

  onClickBlock: function (self, block, conn) {
    // loop through block connections
    // search uniquely for conditionLogicPort first
    let condition = 'true', hasOutputFlowConn = false
    for (let l = 0; l < conn.length; l++) {

      if (conn[l]["target"]["port"] == "conditionLogicPort") {
        condition = this.generateConditionCode(conn[l]["source"]["node"])  
      }

      if (conn[l]["source"]["node"] == block["id"]) {
        hasOutputFlowConn = true
      }
    }

    // test if event block has output connections
    if (hasOutputFlowConn == false) return
    
    for (let i = 0; i < conn.length; i++) { 

      if (conn[i]["target"]["port"] != "objectPort") continue

      var layout_obj = this.page.getItemById(conn[i]["source"]["port"])

      if (layout_obj == null) return
      // make object glow
      this.glowObject(this, layout_obj, condition, block['id'], block['glow'])

      layout_obj.on('mousedown', function () {
        self.reader.enableObjectIsTouched()
        
        block['triggerObjId'] = layout_obj["id"]

        conn[i]['name'] = layout_obj['name']

        const isExecuting = self.executeOnEventCode(block, conn, conn[i], true)

        if (isExecuting == false) return

        // check if layout_obj is already evented
        if (block["once"] == true) {
          // console.log('disable click event')
          layout_obj.evented = false
          // look for other objects linked to the same event to disable them as well
          for (let n = 0; n < conn.length; n++) { 

            if (conn[n]["target"]["port"] != "objectPort") continue

            var other_layout_obj = self.page.getItemById(conn[n]["source"]["port"])

            if (other_layout_obj == null) continue

            other_layout_obj.evented = false
          }
        }

      })
      // add mouse up listerner to trigger the event
      layout_obj.on('mouseup', function () {

        self.reader.disableObjectIsTouched()

      })
    }
  },

  onClickOnceBlock: function (self, block, conn) {
    this.onClickBlock(self, block, conn)
  },

  onCollisionBlock: function(self, block, conn) {
    
    // let source_object = null
    let target_objects = []
    let collider_object = null

    for (let i = 0; i < conn.length; i++) { 
      // get source object
      if (conn[i]["target"]["port"] === "objectPort") {
        collider_object = this.page.getItemById(conn[i]["source"]["port"])
        break
      }
    }

    if (collider_object == null) return

    for (let l = 0; l < conn.length; l++) { 
      // get target object
      if (conn[l]["target"]["port"] === "targetedObjectPort") {
        // target_objects.push(this.page.getItemById(conn[i]["source"]["port"]))
        var o = this.page.getItemById(conn[l]["source"]["port"])

        if (!o) continue
          
        target_objects.push(o)

        if (o.collisionWatch == undefined) {
          o.collisionWatch = {}
        }

        if (o.collisionWatch[collider_object["id"]] === undefined) {
          o.collisionWatch[collider_object["id"]] = {
            // collision_is_active: false,
            events: {}
          }
        }

        o.collisionWatch[collider_object["id"]]["events"][block["id"]] = {
          block: block,
          conn: conn,
          // objectConn: objectConn
        }
      }
    }

    if (target_objects.length === 0) return
    
    // store objects in list      
    this.reader.initCollisionWatch(collider_object.id, {
      collision_is_active: false,
      targets: target_objects,
      threshold: block['threshold']
    })
  },

  onDragOverBlock: function (self, block, conn) {
    // loop through block connections
    // search uniquely for conditionLogicPort first
    let condition = 'true', hasOutputFlowConn = false
    for (let l = 0; l < conn.length; l++) {

      if (conn[l]["target"]["port"] == "conditionLogicPort") {
        condition = this.generateConditionCode(conn[l]["source"]["node"])  
      }

      if (conn[l]["source"]["node"] == block["id"]) {
        hasOutputFlowConn = true
      }
      
    }
    // test if event block has output connections
    if (hasOutputFlowConn == false) return
    // set target
    let target_object = null
    let targetObjConnection = null
    for (let m = 0; m < conn.length; m++) {

      if (conn[m]["target"]["port"] != "targetedObjectPort") continue

      targetObjConnection = conn[m]
        
      target_object = this.page.getItemById(conn[m]["source"]["port"])

      break

    }

    if (target_object == null) return

    for (let i = 0; i < conn.length; i++) {

      if (conn[i]["target"]["port"] != "objectPort") continue
        
      var dragged_obj = this.page.getItemById(conn[i]["source"]["port"])
      // make object glow
      this.glowObject(this, dragged_obj, condition, block['id'], block['glow'])

      if (dragged_obj == null) return

      dragged_obj.lockMovementX = false
      dragged_obj.lockMovementY = false

      dragged_obj.on('moving', function() {

        if(!dragged_obj.intersectsWithObject(target_object, undefined, undefined, block['threshold']) || dragged_obj.dragEventTriggered) return

        conn[i]['name1'] = dragged_obj['name']
        conn[i]['name2'] = target_object['name']

        self.appendLogMsg(self.reader.$t('reader.inspect.onDragOver', {
          name1: self.getObjectBlockName(dragged_obj),
          name2: self.getObjectBlockName(target_object),          
        }), true)

        const isExecuting = self.executeOnEventCode(block, conn, conn[i], true)

        if (isExecuting == false) return
        // check if layout_obj is already evented
        if (block["once"] == true) {
          // dragged_obj.evented = false
          dragged_obj.dragEventTriggered = true
        }
        // highlight target object block
        self.highlightBlock({
          block: self.getBlock(targetObjConnection["source"]["node"]),
          hasTimeout: true,
          color: '#9a67ea'
        })
        // highlight target object connection
        self.highlightConnection({
          conn: targetObjConnection,
          hasTimeout: true
        })
      });
    }
  },

  onDragOverOnceBlock: function (self, block, conn) {
    block["once"] = true
    this.onDragOverBlock(self, block, conn)
  },

  onTextInputBlock: function (self, block, conn) {
    // loop through block connections
    // search uniquely for conditionLogicPort first
    let hasOutputFlowConn = false
    for (let l = 0; l < conn.length; l++) {
      if (conn[l]["source"]["node"] != block["id"]) continue
      hasOutputFlowConn = true
      break
    }

    // test if event block has output connections
    if (hasOutputFlowConn == false) return
    var layout_obj = {}

    for (let i = 0; i < conn.length; i++) { 
      if (conn[i]["target"]["port"] != "objectPort") continue

      layout_obj[i] = this.page.getItemById(conn[i]["source"]["port"])
    
      if (layout_obj[i.toString()] == null) return
      // add event listerner to trigger the event
      layout_obj[i].on('editing:exited', function () {        
        self.reader.exitTextEditing()

        if(layout_obj[i].inputTriggered || layout_obj[i].showplaceholder) return
        conn[i]['name'] = layout_obj[i]['name']        
        const isExecuting = self.executeOnEventCode(block, conn, conn[i], true)

        if (isExecuting == false) return

        if (block["once"] == true) {
          layout_obj[i].inputTriggered = true
        }
      })
    }
  },

  onTextInputOnceBlock: function (self, block, conn) {
    block["once"] = true
    this.onTextInputBlock(self, block, conn)
  },

  generateOnCompleteCode: function(block, conn, eventId) {
    // console.log('generateOnCompleteCode', block)
    if (this.reader === undefined) return
    
    for (let i = 0; i < conn.length; i++) {
      
      if (conn[i]["source"]["node"] != block["id"]) continue

      var nextBlock = this.getBlock(conn[i]["target"]["node"])


      if (nextBlock == null) continue

      if (block["pop"] == true) nextBlock["pop"] = true

      nextBlock["depth"] = block["depth"] + 1

      this.highlightConnection({
        conn: conn[i],
        hasTimeout: true
      })

      this.executeScript(
        this,
        nextBlock,
        this.getBlockConnections(nextBlock["id"]),
        eventId
      )
    }
  },

  executeOnEventCode: function(block, conn, objectConn, testCondition) {
    // check if this event is already running
    // console.log('executeOnEventCode', block)
    if (this.checkIfEventIsActive(block["id"])) return false
    
    if (this.reader === undefined) return false

    if (testCondition) {
      // loop through block connections
      // search uniquely for conditionLogicPort first
      let condition = 'true'
      for (let m = 0; m < conn.length; m++) {
        if (conn[m]["target"]["port"] != "conditionLogicPort") continue
        condition = this.generateConditionCode(conn[m]["source"]["node"])
        break
      }
      // test if condition is true or false
      // if false, return and do nothing
      if (this.parseBoolStr(condition) == false) return false

      // var triggeredObject = null
      if (block["cssClass"] == 'onPageStartBlock') {
        this.appendLogMsg(
          this.reader.$t('reader.inspect.onPageStart', {
            page:  this.reader.pageSelected + 1,
          }),
          true
        )
      // } else {
      //   // necessary for 'self' object block
      //   console.log(objectConn)
      //   triggeredObject = this.page.getItemById(objectConn["source"]["port"])
      }
      
      if (block["cssClass"] == 'onClickBlock' || block["cssClass"] == 'onClickOnceBlock') {
        // this.appendLogMsg(
        //   this.reader.$t('reader.inspect.onClick', {
        //     name:  objectConn["name"]
        //   }),
        //   true
        // )
        let obj_name

        if(objectConn.name == undefined){

          obj_name = objectConn["source"]["port"]

        }else{

          var clicked_object = this.page.getItemById(objectConn["source"]["port"])

          if (clicked_object) {
            obj_name = clicked_object['name']
          }
        }

        this.appendLogMsg(
          this.reader.$t('reader.inspect.onClick', {
            name:  obj_name
          }),
          true
        )
        
        
      }

      if (block["cssClass"] == 'onDragOverBlock' || block["cssClass"] == 'onDragOverOnceBlock') {

        let obj_name1, obj_name2
        if(objectConn['name1'] == undefined){
          obj_name1 = objectConn["source"]["port"]
        }else{
          obj_name1 = objectConn["name1"]
        }

        if(objectConn['name2'] == undefined){
          obj_name2 = objectConn["source"]["port"]
        }else{
          obj_name2 = objectConn["name2"]
        }

        this.appendLogMsg(
          this.reader.$t('reader.inspect.onDragOver', {
            name1:  obj_name1,
            name2:  obj_name2
          }),
          true
        )
      }

      if (block["cssClass"] == 'onTextInputBlock' || block["cssClass"] == 'onTextInputOnceBlock') {
        // this.appendLogMsg(
        //   this.reader.$t('reader.inspect.onInput', {
        //     name:  objectConn["name"]
        //   }),
        //   true
        // )
        let obj_name
        if(objectConn['name'] == undefined){
          obj_name = objectConn["source"]["port"]
        }else{
          obj_name = this.page.getItemById(objectConn["source"]["port"])["name"]
        }
        this.appendLogMsg(
          this.reader.$t('reader.inspect.onInput', {
            name:  obj_name
          }),
          true
        ) 
      }
    }

    if (objectConn) {
      // highlight object block
      this.highlightBlock({
        block: this.getBlock(objectConn["source"]["node"]),
        hasTimeout: true,
        color: '#9a67ea'
      })
      // highlight object connection
      this.highlightConnection({
        conn: objectConn,
        hasTimeout: true
      })
    }
    // highlight Event block
    this.highlightBlock({
      block: block,
      hasTimeout: true
    })

    // loop through connections
    // search for flow connections only
    for (let o = 0; o < conn.length; o++) {
      
      if (conn[o]["target"]["port"] == "conditionLogicPort") continue
      
      if (conn[o]["target"]["port"] == "objectPort") continue

      if (conn[o]["target"]["port"] == "targetedObjectPort") continue

      var functionBlock = this.getBlock(conn[o]["target"]["node"])
      
      if (functionBlock == null) continue

      functionBlock['pop'] = true

      var connections = this.getBlockConnections(functionBlock["id"])
      functionBlock['depth'] = 1
      
      this.highlightConnection({
        conn: conn[o],
        hasTimeout: true
      })
      this.executeScript(this, functionBlock, connections, block['id'])
    }

    return true
  },

  executeScript: function (self, block, conn, eventId) {
    let onCompleteTrigger = false
    // if (this.reader.eventStatus[eventId]) {
    //   console.log(this.page.getItemById(this.reader.eventStatus[eventId]["triggerObjId"]))

    // }
    
    block['eventCode'] = eventId
    // first check if block has an object port
    let block_has_objectPort = false
    if (this.blocksWithObjectPort.includes(block["cssClass"])) block_has_objectPort = true

    if (block_has_objectPort) {
      for (let i = 0; i < conn.length; i++) {

        if (conn[i]["target"]["port"] != "objectPort") continue
        
        if (conn[i]["source"]["port"] !== 'self') {
          block['obj_id'] = conn[i]["source"]["port"]
        } else if (this.reader.eventStatus[eventId]["triggerObjId"]) {
          block['obj_id'] = this.reader.eventStatus[eventId]["triggerObjId"]
        } else {
          continue
        }
        
        // highlight object block
        this.highlightBlock({
          block:  this.getBlock(conn[i]["source"]["node"]),
          hasTimeout: true,
          color: "#9a67ea"
        })
        // highlight object block
        this.highlightConnection({
          conn:  conn[i],
          hasTimeout: true
        })
        // hightlight block
        this.highlightBlock({
          block:  block,
          hasTimeout: false
        })
    
        this[block['cssClass']](this, block, function() {

          if (self.reader === undefined) return

          if ($nuxt.$store.state.inspectMode) {
            if (self.reader.eventGraphIsReady) {
              // remove hightlight box
              self.stopHighlightInspectBlock(block["id"], self.highlightBlocksList)
            }
          }

          if (onCompleteTrigger) return

          onCompleteTrigger = true
          // check if output connections exists
          const outPutConn = self.getBlockOutputConnections(block['id'])

          if (outPutConn.length == 0) {
            if (self.checkIfEventCodeIsCompleted(block) == false) onCompleteTrigger = false
            return
          }

          self.generateOnCompleteCode(block, conn, eventId)
        })
      }
      return
    }

    // also check if block is setvarval block
    if (this.setVarValBlocks.includes(block["cssClass"])) {

      // TextVariableEqualsTextObject => set nameB to the object text
      if (block["cssClass"] == "textVariableEqualsTextObjectBlock"){
        for (let m = 0; m < conn.length; m++) {
          if (conn[m]["target"]["port"] != "objectPort") continue
          var layout_obj = this.page.getItemById(conn[m]["source"]["port"])
          block['nameB'] = layout_obj.text
        }
      }

      this.highlightBlock({
        block:  block,
        hasTimeout: true
      })

      this.setVarVal(this, block, function() {
        if (onCompleteTrigger) return

        onCompleteTrigger = true

        // check if output connections exists
        const outPutConn = self.getBlockOutputConnections(block['id'])

        if (outPutConn.length == 0) {
          self.checkIfEventCodeIsCompleted(block)
          return
        }

        self.generateOnCompleteCode(block, conn, eventId)
      })

      return
    }

    // check for logic block
    let block_has_logicPort = false
    if (this.blockWithLogicPort.includes(block["cssClass"])) block_has_logicPort = true
    

    if (block_has_logicPort) {
      this[block['cssClass']](this, block, conn, eventId)
      return
    }

    this.highlightBlock({
      block:  block,
      hasTimeout: false
    })

    // else, block has only a flow port (e.g playSoundEffect)
    this[block['cssClass']](this, block, function() {
  
      if ($nuxt.$store.state.inspectMode) {
        if (self.reader.eventGraphIsReady) {
          // remove hightlight box
          self.stopHighlightInspectBlock(block["id"], self.highlightBlocksList)
        }
      }
      
      if (onCompleteTrigger) return
  
      onCompleteTrigger = true

      // check if output connections exists
      const outPutConn = self.getBlockOutputConnections(block['id'])

      if (outPutConn.length == 0) {
        self.checkIfEventCodeIsCompleted(block)
        return
      }

      self.generateOnCompleteCode(block, conn, eventId)
    })
  },

  branchBlock: function (self, block, conn, eventId) {
    let condition = 'false'
    // first get the condition result
    for (let l = 0; l < conn.length; l++) {
      if (conn[l]["target"]["port"] != "conditionLogicPort") continue
      condition = this.generateConditionCode(conn[l]["source"]["node"])
      break
    }

    const conditionResult = this.parseBoolStr(condition)

    for (let i = 0; i < conn.length; i++) {
      var targetBlock = this.getBlock(conn[i]["target"]["node"])
      var sourceBlock = this.getBlock(conn[i]["source"]["node"])
      targetBlock["depth"] = block["depth"] + 1
      // test if condition is true or false
      if (
        conditionResult == true &&
        conn[i]['source']['port'] == "trueOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[i],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      } else if (
        conditionResult == false &&
        conn[i]['source']['port'] == "falseOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[i],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      }
    }
  },

  numberComparisonBlock: function (self, block, conn, eventId) {
    if (block["nameA"] == "none") return

    for (let l = 0; l < conn.length; l++) {

      var targetBlock = this.getBlock(conn[l]["target"]["node"])
      var sourceBlock = this.getBlock(conn[l]["source"]["node"])

      let nameB = this.isValueAVariableField(block["nameB"])
      if (!this.isNumber(nameB, true)) return
      // var nameB = this.transformStringValue(block["nameB"])
      targetBlock["depth"] = block["depth"] + 1

      // if (this.reader.global_variables[block["nameB"]] == undefined) {
      //   block["nameB"] = this.transformStringValue(block["nameB"])
      // } else {
      //   block["nameB"] = this.reader.global_variables[block["nameB"].value]
      // }

      if (
        parseFloat(this.reader.global_variables[block["nameA"]]["value"]) > parseFloat(nameB.value) &&
        conn[l]['source']['port'] == "greaterOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      } else if (
        parseFloat(this.reader.global_variables[block["nameA"]]["value"]) == parseFloat(nameB.value) &&
        conn[l]['source']['port'] == "equalsOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      } else if (
        parseFloat(this.reader.global_variables[block["nameA"]]["value"]) < parseFloat(nameB.value) &&
        conn[l]['source']['port'] == "smallerOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      }
    }
  },

  textComparisonBlock: function (self, block, conn, eventId) {
    // console.log('textcomparison block')
    if (block["nameA"] == "none") return
    // console.log("rawr")

    let includes_port_connected = false
    for (let m = 0; m < conn.length; m++) {
      if (conn[m]['source']['port'] == "includesOutputBranchPort") {
        includes_port_connected = true
        break
      }
    }

    for (let l = 0; l < conn.length; l++) {

      var targetBlock = this.getBlock(conn[l]["target"]["node"])
      var sourceBlock = this.getBlock(conn[l]["source"]["node"])
      var nameB = this.transformStringValue(block["nameB"])
      targetBlock["depth"] = block["depth"] + 1
  
      // if (this.reader.global_variables.hasOwnProperty(block["nameB"])) {
      //   nameB = this.reader.global_variables[this.transformStringValue(nameB)]['value']
      // }
      
      var is_exact = this.reader.global_variables[block["nameA"]]["value"] == nameB
      var is_includes = this.reader.global_variables[block["nameA"]]["value"] && this.reader.global_variables[block["nameA"]]["value"].includes(nameB) && includes_port_connected
      // console.log('is_exact', is_exact)
      // console.log('is_includes', is_includes)
      // console.log(conn[l]['source']['port'])
      // console.log(sourceBlock['id'] == block['id'])

      if (
        is_exact &&
        conn[l]['source']['port'] == "equalsOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      } else if (
        is_includes &&
        conn[l]['source']['port'] == "includesOutputBranchPort" &&
        sourceBlock['id'] == block['id']
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      } else if (
        conn[l]['source']['port'] == "elseOutputBranchPort" &&
        sourceBlock['id'] == block['id'] &&
        !is_includes && !is_exact
      ) {
        this.highlightConnection({
          conn: conn[l],
          hasTimeout: true
        })
        this.executeScript(self, targetBlock, this.getBlockConnections(targetBlock['id']), eventId)
      }
    }
  },

  highlightBlock: function(options) {
    if (!$nuxt.$store.state.inspectMode) return

    if (!this.reader.eventGraphIsReady) return

    this.startHighlightInspectBlock(this, options.block, this.highlightBlocksList, options.color)

    if (options.hasTimeout == false) return

    if (options.duration == undefined) options.duration = 600
    
    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      // remove block highlight
      this.stopHighlightInspectBlock(options.block["id"], this.highlightBlocksList)
    }, options.duration));
  },

  highlightConnection: function(options) {
    if (!$nuxt.$store.state.inspectMode) return

    if (!this.reader.eventGraphIsReady) return

    this.startHighlightInspectConnection(options.conn, this.highlightBlocksList)

    if (options.hasTimeout == false) return

    if (options.duration == undefined) options.duration = 600
    
    this.reader.timeouts.push(setTimeout(() => {
      this.reader.timeouts.pop()
      // remove block highlight
      this.stopHighlightInspectConnection(options.conn["id"], this.highlightBlocksList)
    }, options.duration));
    
  },


  startHighlightInspectBlock: function(self, figure, highlightList, color) {
    try {
    if (!figure) return

    if (highlightList[figure["id"]]) {
      highlightList[figure["id"]].array.push(true)
      return
    }

    const f = this.reader.eventPage.getFigure(figure["id"])

    if (!f) return

    if (color == undefined) color = "#FFFFFF"

    const rect = new draw2d.shape.basic.Rectangle({
      bgColor: null,
      stroke: 7,
      color: color,
      dasharray: '-'
    })

    rect.show = async function (canvas) {
      rect.groupId = figure["id"]
      await rect.setCanvas(canvas)
      rect.shape[0].style.transition = "stroke-width 0.3s linear"
      rect.shape[0].style.animation = "dash 1000s linear"
      
      await rect.shape.toFront()
      rect.shape[0].style.strokeDashoffset = 50000    
    }

    rect.hide = function (callback) {

      rect.groupId = figure["id"]
      
      rect.shape[0].style.strokeWidth = 0
      
      self.reader.timeouts.push(setTimeout(() => {
        self.reader.timeouts.pop()
        rect.setCanvas(null)
        callback()  
      }, 300))
    }

    rect.show(this.reader.eventPage)
    rect.setPosition(f.getAbsolutePosition().translate(-7, -7))
    rect.setDimension(f.getWidth(), f.getHeight())

    highlightList[figure["id"]] = {
      el: rect,
      array: [true]
    }
  } catch (e) {
    console.log(e)
    this.reader.sendError({
      method: 'readerLauncher/startHighlightInspectBlock', 
      message: e.toString(),
      traceback: e.traceback,
    })
  }
  },

  stopHighlightInspectBlock: function(id, highlightList) {
    try {
    if (highlightList[id] == undefined) return

    highlightList[id].array.pop()

    if (highlightList[id].array > 0) return

    let rect = highlightList[id].el

    let self = this.reader.eventPage
    rect.hide(function() {
      self.remove(rect, true)
    })

    delete highlightList[id]
  } catch (e) {
    console.log(e)
    this.reader.sendError({
      method: 'readerLauncher/stopHighlightInspectBlock', 
      message: e.toString(),
      traceback: e.traceback,
    })
  }
  },

  startHighlightInspectConnection: async function(conn, highlightList) {
    try {
    if (!conn) return

    if (highlightList[conn["id"]]) {
      highlightList[conn["id"]].array.push(true)
      return
    }

    // const c = this.getFigure(conn["id"])
    let c = null
    for (var i = 0; i < this.reader.eventPage.lines.data.length; i++) {
      if (this.reader.eventPage.lines.data[i]["id"] != conn["id"]) continue

      c = this.reader.eventPage.lines.data[i]

      break
      
    }

    if (!c) return

    c.shape[1][0].style.transition = "stroke-dashoffset 1000s linear, stroke-width 0.3s linear, stroke-dasharray 0.3s linear"
    c.shape[1][0].style.strokeWidth = 7
    c.shape[1][0].style.strokeDasharray = "7, 11"
    
    await this.reader.$nextTick()

    if (c.shape == null) return

    c.shape[1][0].style.strokeDashoffset = -50000  
    // }, 10)

    highlightList[conn["id"]] = {
      el: c.shape[1][0],
      array: [true]
    }
  } catch (e) {
    console.log(e)
    this.reader.sendError({
      method: 'readerLauncher/startHighlightInspectConnection', 
      message: e.toString(),
      traceback: e.traceback,
    })
  }
  },

  stopHighlightInspectConnection: function(id, highlightList) {
    try {
    if (highlightList[id] == undefined) return

    highlightList[id].array.pop()

    if (highlightList[id].array > 0) return

    let conn = highlightList[id].el

    conn.style.transition = "stroke-width 0.3s linear, stroke-dasharray 0.3s linear"
    conn.style.strokeDashoffset = 0
    conn.style.strokeWidth = 2
    conn.style.strokeDasharray = "0, 0"

    delete highlightList[id]
  } catch (e) {
    console.log(e)
    this.reader.sendError({
      method: 'readerLauncher/stopHighlightInspectConnection', 
      message: e.toString(),
      traceback: e.traceback,
    })
  }
  },

  appendLogMsg: async function(msg, isEvent, isGoToPage, isSpeech, isError) {
    // post message in log if inspectmode == true
    if (!this.reader.inspectMode) return

    let container = document.getElementById("inspectLogContainer")
    let scrollDown = false

    if (
      container.offsetHeight + container.scrollTop ==
      container.scrollHeight
    ) {
      scrollDown = true
    } else scrollDown = false

    this.reader.inspectLog.push({
      msg: msg,
      isSpeech: isSpeech,
      isEvent: isEvent,
      isError: isError,
      isGoToPage: isGoToPage,
      time: new Date()
    })

    if(!scrollDown) return
    
    await this.reader.$nextTick()

    container.scrollTop = container.scrollHeight
    
  }

}