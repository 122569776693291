const state = {
  languages: {
    Afrikaans: 'af-ZA',
    አማርኛ: 'am-ET',
    العربية: 'ar-EG',
    Azərbaycanca: 'az-AZ',
    বাংলা: 'bn-IN',
    Indonesian: 'id-ID',
    Melayu: 'ms-MY',
    Català: 'ca-ES',
    Čeština: 'cs-CZ',
    Dansk: 'da-DK',
    Deutsch: 'de-DE',
    English: 'en-US',
    Español: 'es-ES',
    Filipino: 'fil-PH',
    Français: 'fr-FR',
    Basa_Jawa: 'jv-ID',
    Galego: 'gl-ES',
    ગુજરાતી: 'gu-IN',
    Hrvatski: 'hr-HR',
    IsiZulu: 'zu-ZA',
    Íslenska: 'is-IS',
    Italiano: 'it-IT',
    ಕನ್ನಡ: 'kn-IN',
    ភាសាខ្មែរ: 'km-KH',
    Latviešu: 'lv-LV',
    Lietuvių: 'lt-LV',
    മലയാളം: 'ml-IN',
    मराठी: 'mr-IN',
    Magyar: 'hu-HU',
    ລາວ: 'lo-LA',
    Nederlands: 'nl-NL',
    नेपाली: 'ne-NP',
    Norsk_bokmål: 'nb-NO',
    Polski: 'pl-PL',
    Português: 'pt-PT',
    Português_Brasil: 'pt-BR',
    Română: 'ro-RO',
    සිංහල: 'si-LK',
    Slovenščina: 'sl-SI',
    Sunda: 'su-ID',
    Slovenčina: 'sk-SK',
    Suomi: 'sl-SI',
    Svenska: 'sv-SE',
    Kiswahili_Kenya: 'sw-KE',
    Kiswahili_Tanzania: 'sw-TZ',
    ქართული: 'ka-GE',
    Հայերեն: 'hy-AM',
    தமிழ்: 'ta-IN',
    తెలుగు: 'te-IN',
    Tiếng_Việt: 'vi-VN',
    Türkçe: 'tr-TR',
    اُردُو: 'ur-PK',
    Ελληνικά: 'el-GR',
    български: 'bg-BG',
    Pусский: 'ru-RU',
    Српски: 'sr-RS',
    Українська: 'uk-UA',
    한국어: 'ko-KR',
    普通话_中国大陆: 'cmn-Hans-TW',
    普通话_台灣: 'cmn-Hant-TW',
    粵語: 'yue-Hant-HK',
    日本語: 'ja-JP',
    हिन्दी: 'hi-IN',
    ภาษาไทย: 'th-TH'
  },
  fonts: [
    'Lexend',
    'Abril Fatface',
    'Acme',
    'Alegreya',
    'Alex Brush',
    'Alfa Slab One',
    'Allerta Stencil',
    'Allura',
    'Alice',
    'Almendra Display',
    'Amatic SC',
    'Annie Use Your Telescope',
    'Anton',
    'Architects Daughter',
    'Aref Ruqaa',
    'Arvo',
    'Atma',
    'Audiowide',
    'Averia Sans Libre',
    'Bad Script',
    'Bangers',
    'Barrio',
    'Bebas Neue',
    'BenchNine',
    'Berkshire Swash',
    'Bigelow Rules',
    'Boogaloo',
    'Cabin Sketch',
    'Caesar Dressing',
    'Carter One',
    'Caveat Brush',
    'Chakra Petch',
    'Cherry Swash',
    'Chewy',
    'Chilanka',
    'Cinzel',
    'Codystar',
    'Combo',
    'Cormorant Garamond',
    'Cormorant Unicase',
    'Courgette',
    'Crafty Girls',
    'Creepster',
    'Damion',
    'Dancing Script',
    'Devonshire',
    'Dosis',
    'Dr Sugiyama',
    'Eater',
    'Emilys Candy',
    'Exo',
    'Finger Paint',
    'Flavors',
    'Fredoka One',
    'Fredericka the Great',
    'Frijole',
    'Gentium Basic',
    'Girassol',
    'Glass Antiqua',
    'Gloria Hallelujah',
    'Great Vibes',
    'Gochi Hand',
    'Grand Hotel',
    'Great Vibes',
    'Griffy',
    'Handlee',
    'Henny Penny',
    'Homemade Apple',
    'IM Fell French Canon SC',
    'Indie Flower',
    'Irish Grover',
    'Jolly Lodger',
    'Josefin Sans',
    'Josefin Slab',
    'Julius Sans One',
    'Kalam',
    'Kaushan Script',
    'Kite One',
    'Kreon',
    'Lateef',
    'Lato',
    'Lato Heavy',
    'Libre Baskerville',
    'Libre Franklin',
    'Limelight',
    'Lobster Two',
    'Londrina Shadow',
    'Londrina Sketch',
    'Lora',
    'Love Ya Like A Sister',
    'Luckiest Guy',
    'Marck Script',
    'Margarine',
    'Merienda',
    'Merriweather',
    'Metal Mania',
    'Miltonian Tattoo',
    'Monoton',
    'Montserrat',
    'Mystery Quest',
    'Nanum Brush Script',
    'Nanum Pen Script',
    'Neucha',
    'Nothing You Could Do',
    'Nunito',
    'Open Sans',
    'Orbitron',
    'Oregano',
    'Oswald',
    'Over the Rainbow',
    'Pacifico',
    'Pangolin',
    'Parisienne',
    'Patrick Hand',
    'Permanent Marker',
    'Poppins',
    'Port Lligat Slab',
    'Press Start 2P',
    'Prosto One', 
    'Princess Sofia',
    'Proza Libre',
    'Quando',
    'Quattrocento',
    'Quicksand',
    'Racing Sans One',
    'Raleway',
    'Rammetto One',
    'Ranga',
    'Ravi Prakash',
    'Reenie Beanie',
    'Ribeye',
    'Roboto',
    'Roboto Condensed',
    'Roboto Slab',
    'Rock Salt',
    'Rosario',
    'Rubik 80s Fade',
    'Ruge Boogie',
    'Sacramento',
    'Satisfy',
    'Secular One',
    'Sevillana',
    'Sigmar One',
    'Spicy Rice',
    'Schoolbell',
    'Sevillana',
    'Shadows Into Light Two',
    'Share Tech Mono',
    'Shojumaru',
    'Sirin Stencil',
    'Slackey',
    'Snowburst One',
    'Special Elite',
    'Staatliches',
    'Tangerine',
    'Taviraj',
    'Teko',
    'Tienne',
    'Tillana',
    'Times Neue Roman',
    'Trade Winds',
    'Ultra',
    'Unica One',
    'Vast Shadow',
    'Voltaire',
    'VT323',
    'Yellowtail',
    'ZCOOL KuaiLe'
  ],
  templates: {
    left: '/img/studio/templates/template-left.jpg',
    bottom: '/img/studio/templates/template-bottom.jpg',
    right: '/img/studio/templates/template-right.jpg',
    top: '/img/studio/templates/template-top.jpg'
  },
  fontSizes: [20, 22, 24, 26, 28, 36, 40, 48, 60, 72, 80, 88, 96, 106, 114],
  assetCategories: [
    
    {
      icon: 'mdiFormatTitle',
      name: 'text',
    },
    {
      icon: 'mdiWallpaper',
      name: 'images'
    },
    {
      icon: 'mdiTexture',
      name: 'backgrounds'
    },
    {
      icon: 'mdiRun',
      name: 'animations'
    },
    {
      icon: 'mdiShapeOutline',
      name: 'shapes'
    },
    {
      icon: 'mdiLightbulbOnOutline',
      name: 'widgets'
    },
    {
      icon: 'mdiLandPlots',
      name: 'templates'
    },
    {
      icon: 'mdiCloudUploadOutline',
      name: 'uploads'
    },
    
  ],
  blockCategories: [
    {
      icon: 'mdiFlaskOutline',
      name: 'transform', 
      color: 'rgb(67, 160, 71)'
    },
    {
      icon: 'mdiMusicNote',
      name: 'sounds',
      color: 'rgb(67, 160, 71)'
    },
    {
      icon: 'mdiShipWheel',
      name: 'controls',
      color: 'rgb(67, 160, 71)'
    },
    {
      icon: 'mdiVariable',
      name: 'variables',
    },
    {
      icon: 'mdiCallSplit',
      name: 'logic',
      color: 'rgb(255,255,255)'
    },
    {
      icon: 'mdiCursorDefaultClickOutline',
      name: 'events',
      color: 'rgb(251, 140, 0)' 

    },
    {
      icon: 'mdiWidgetsOutline',
      name: 'objects',
      color: 'rgb(154, 103, 234)'
    }
  ],
  blocks: {
    functions: [
      {
        id: 'textEffectBlock',
        src: '/functions/textEffectBlock.svg',
        thumb_height: 0
      },
      {
        id: 'animateSpineBlock',
        src: '/functions/animateSpineBlock.svg',
        thumb_height: 182
      },
      {
        id: 'fadeBlock',
        src: '/functions/fadeBlock.svg',
        thumb_height: 242
      },
      {
        id: 'moveToBlockAbsolute',
        src: '/functions/moveToBlockAbsolute.svg',
        thumb_height: 242
      },
      {
        id: 'moveToBlockRelative',
        src: '/functions/moveToBlockRelative.svg',
        thumb_height: 122
      },
      {
        id: 'blinkBlock',
        src: '/functions/blinkBlock.svg',
        thumb_height: 122
      },
      {
        id: 'bounceBlock',
        src: '/functions/bounceBlock.svg',
        thumb_height: 212
      },
      // {
      //   id: 'setObjectAttribute',
      //   src: '/functions/set-obj-attr.png'
      // },
      {
        id: 'scaleBlock',
        src: '/functions/scaleBlock.svg',
        thumb_height: 212
      },
      {
        id: 'rotateBlock',
        src: '/functions/rotateBlock.svg',
        thumb_height: 157
      },
      // {
      //   id: 'scrollingObjectsBlock',
      //   src: '/functions/scrolling-obj.png'
      // },
      {
        id: 'changeImageBlock',
        src: '/functions/changeImageBlock.svg',
        thumb_height: 122
      },
      {
        id: 'showBlock',
        src: '/functions/showBlock.svg',
        thumb_height: 122
      },
      {
        id: 'hideBlock',
        src: '/functions/hideBlock.svg',
        thumb_height: 149.7
      },
      {
        id: 'textObjectEqualsValueBlock',
        src: '/functions/textObjectEqualsValueBlock.svg',
        thumb_height: 149.7
      },
      {
        id: 'textObjectEqualsVariableBlock',
        src: '/functions/textObjectEqualsVariableBlock.svg',
        thumb_height: 122
      },
      {
        id: 'restoreObjectBlock',
        src: '/functions/restoreObjectBlock.svg',
        thumb_height: 182
      },
      {
        id: 'setObjectPositionBlock',
        src: '/functions/setObjectPositionBlock.svg',
        thumb_height: 152
      },
      {
        id: 'flipObjectBlock',
        src: '/functions/flipObjectBlock.svg',
        thumb_height: 145
      },
    ],
    sounds: [
      {
        id: 'playRecordBlock',
        src: '/functions/playRecordBlock.svg',
        thumb_height: 0
      },
      {
        id: 'playMusicBlock',
        src: '/functions/playMusicBlock.svg',
        thumb_height: 152
      },
      {
        id: 'playSoundEffectBlock',
        src: '/functions/playSoundEffectBlock.svg',
        thumb_height: 182
      },
      {
        id: 'stopMusicBlock',
        src: '/functions/stopMusicBlock.svg',
        thumb_height: 152
      },
    ],
    controls: [
      {
        id: 'goToPageBlock',
        src: '/functions/goToPageBlock.svg',
        thumb_height: 0
      },
      {
        id: 'goToLinkBlock',
        src: '/functions/goToLinkBlock.svg',
        thumb_height: 122

      },
      {
        id: 'lockSwipeRightBlock',
        src: '/functions/lockSwipeRightBlock.svg',
        thumb_height: 127
      },
      {
        id: 'lockSwipeLeftBlock',
        src: '/functions/lockSwipeLeftBlock.svg',
        thumb_height: 84.22
      },
      {
        id: 'waitBlock',
        src: '/functions/waitBlock.svg',
        thumb_height: 78.67
      },
      {
        id: 'setDraggableBlock',
        src: '/functions/setDraggableBlock.svg',
        thumb_height: 122
      }
    ],
    variables: [
      {
        id: 'createTextVariables',
        src: '/variables/createTextVariables.svg',
        type: 'text',
        thumb_height: 0
      },
      {
        id: 'textVariableEqualsValueBlock',
        src: '/variables/textVariableEqualsValueBlock.svg',
        type: 'text',
        thumb_height: 99.16
      },
      {
        id: 'textVariableEqualsVariableBlock',
        src: '/variables/textVariableEqualsVariableBlock.svg',
        type: 'text',
        thumb_height: 159.75
      },
      {
        id: 'textVariableEqualsTextObjectBlock',
        src: '/variables/textVariableEqualsTextObjectBlock.svg',
        type: 'text',
        thumb_height: 149.48
      },
      {
        id: 'insertTextBlock',
        src: '/variables/insertTextBlock.svg',
        type: 'text',
        thumb_height: 140.45
      },
      {
        id: 'arithmeticCalculatorBlock',
        src: '/variables/arithmeticCalculatorBlock.svg',
        type: 'text',
        thumb_height: 173.39
      },
      {
        id: 'createBooleanVariables',
        src: '/variables/createBooleanVariables.svg',
        type: 'boolean',
        thumb_height: 154.42
      },
      {
        id: 'variableBlock',
        src: '/variables/variableBlock.svg',
        type: 'boolean',
        thumb_height: 93.59
      },
      {
        id: 'booleanVariableEqualsValueBlock',
        src: '/variables/booleanVariableEqualsValueBlock.svg',
        type: 'boolean',
        thumb_height: 97
      },
      {
        id: 'booleanVariableEqualsVariableBlock',
        src: '/variables/booleanVariableEqualsVariableBlock.svg',
        type: 'boolean',
        thumb_height: 159.75
      },
      {
        id: 'createNumberVariables',
        src: '/variables/createNumberVariables.svg',
        type: 'number',
        thumb_height: 159.75
      },
      {
        id: 'numberVariableEqualsValueBlock',
        src: '/variables/numberVariableEqualsValueBlock.svg',
        type: 'number',
        thumb_height: 93.59
      },
      {
        id: 'numberVariableEqualsVariableBlock',
        src: '/variables/numberVariableEqualsVariableBlock.svg',
        type: 'number',
        thumb_height: 159.75
      },
      {
        id: 'numberVariableEqualsRandomBlock',
        src: '/variables/numberVariableEqualsRandomBlock.svg',
        type: 'number',
        thumb_height: 149.48
      },
      {
        id: 'incrementBlock',
        src: '/variables/incrementBlock.svg',
        type: 'number',
        thumb_height: 170.47
      },
      {
        id: 'decrementBlock',
        src: '/variables/decrementBlock.svg',
        type: 'number',
        thumb_height: 144.94
      }
    ],
    logic: [
      {
        id: 'ANDGateBlock',
        src: '/logics/ANDGateBlock.svg',
        thumb_height: 0
      },
      {
        id: 'ORGateBlock',
        src: '/logics/ORGateBlock.svg',
        thumb_height: 137
      },
      {
        id: 'branchBlock',
        src: '/logics/branchBlock.svg',
        thumb_height: 137
      },
      {
        id: 'numberComparisonBlock',
        src: '/logics/numberComparisonBlock.svg',
        thumb_height: 116.48
      },
      {
        id: 'textComparisonBlock',
        src: '/logics/textComparisonBlock.svg',
        thumb_height: 154.42
      }
    ],
    events: [
      {
        id: 'onPageStartBlock',
        src: '/events/onPageStartBlock.svg',
        thumb_height: 0
      },
      {
        id: 'onClickBlock',
        src: '/events/onClickBlock.svg',
        thumb_height: 152
      },
      // {
      //   id: 'onTouchBlock',
      //   src: '/events/onClickBlock.svg'
      // },
      // {
      //   id: 'onClickOnceBlock',
      //   src: '/events/onClickOnceBlock.png'
      // },
      {
        id: 'onDragOverBlock',
        src: '/events/onDragOverBlock.svg',
        thumb_height: 212
      },
      // {
      //   id: 'onDragOverOnceBlock',
      //   src: '/events/onDragOverOnceBlock.png'
      // },
      {
        id: 'onTextInputBlock',
        src: '/events/onTextInputBlock.svg',
        thumb_height: 242
      },
      {
        id: 'onPronounceBlock',
        src: '/events/onPronounceBlock.svg',
        thumb_height: 182
      },
      {
        id: 'onCollisionBlock',
        src: '/events/onCollisionBlock.svg',
        thumb_height: 212
      },
      // {
      //   id: 'onTextInputOnceBlock',
      //   src: '/events/onTextInputOnceBlock.png'
      // }
    ]
  },

  textEffects:  [
    'typeWriter',
    'fadeIn',
    'breathe',
    'pop',
    'pan',
    'rise',
    // 'tumble',
    'flipObject',
    'scrap',
    // 'stomp',
    'wave',
    'starfall',
    'whirlpool',
    // 'roll'
  ],

  easings: [
    {
      'name': 'InSine',
      'path': 'M1 84c14 1 47.75 1 123-83',
      'transition': '0.12, 0, 0.39, 0',
      'maths': 'return 1 - cos((x * PI) / 2);',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutSine',
      'path': 'M1 84C76.25 0 110 0 124 1',
      'transition': '0.61, 1, 0.88, 1',
      'maths': 'return sin((x * PI) / 2);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutSine',
      'path': 'M1 84C46.25 85 78.75 0 124 1',
      'transition': '0.37, 0, 0.63, 1',
      'maths': 'return -(cos(PI * x) - 1) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InQuad',
      'path': 'M1 84c12.75 1 61.5 1 123-83',
      'transition': '0.11, 0, 0.5, 0',
      'maths': 'return x * x;',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutQuad',
      'path': 'M1 84C62.5 0 111.25 0 124 1',
      'transition': '0.5, 1, 0.89, 1',
      'maths': 'return 1 - (1 - x) * (1 - x);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutQuad',
      'path': 'M1 84C56.25 85 68.75 0 124 1',
      'transition': '0.45, 0, 0.55, 1',
      'maths': 'return x < 0.5 ? 2 * x * x : 1 - pow(-2 * x + 2, 2) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InCubic',
      'path': 'M1 84c39 1 82.75 1 123-83',
      'transition': '0.32, 0, 0.67, 0',
      'maths': 'return x * x * x;',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutCubic',
      'path': 'M1 84C41.25 0 85 0 124 1',
      'transition': '0.33, 1, 0.68, 1',
      'maths': 'return 1 - pow(1 - x, 3);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutCubic',
      'path': 'M1 84C81.25 85 43.75 0 124 1',
      'transition': '0.65, 0, 0.35, 1',
      'maths': 'return x < 0.5 ? 4 * x * x * x : 1 - pow(-2 * x + 2, 3) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InQuart',
      'path': 'M1 84c61.5 1 92.75 1 123-83',
      'transition': '0.5, 0, 0.75, 0',
      'maths': 'return x * x * x * x;',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutQuart',
      'path': 'M1 84C31.25 0 62.5 0 124 1',
      'transition': '0.25, 1, 0.5, 1',
      'maths': 'return 1 - pow(1 - x, 4);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutQuart',
      'path': 'M1 84C95 85 30 0 124 1',
      'transition': '0.76, 0, 0.24, 1',
      'maths': 'return x < 0.5 ? 8 * x * x * x * x : 1 - pow(-2 * x + 2, 4) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InQuint',
      'path': 'M1 84c79 1 96.5 1 123-83',
      'transition': '0.64, 0, 0.78, 0',
      'maths': 'return x * x * x * x * x;',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutQuint',
      'path': 'M1 84C27.5 0 45 0 124 1',
      'transition': '0.22, 1, 0.36, 1',
      'maths': 'return 1 - pow(1 - x, 5);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutQuint',
      'path': 'M1 84C103.75 85 21.25 0 124 1',
      'transition': '0.83, 0, 0.17, 1',
      'maths': 'return x < 0.5 ? 16 * x * x * x * x * x : 1 - pow(-2 * x + 2, 5) / 2;',
      'gradient': 'url(#inOut)'
    },
    
    {
      'name': 'InExpo',
      'path': 'M1 84c86.5 1 104 1 123-83',
      'transition': '0.7, 0, 0.84, 0',
      'maths': 'return x === 0 ? 0 : pow(2, 10 * x - 10);',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutExpo',
      'path': 'M1 84C20 0 37.5 0 124 1',
      'transition': '0.16, 1, 0.3, 1',
      'maths': 'return x === 1 ? 1 : 1 - pow(2, -10 * x);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutExpo',
      'path': 'M1 84C108.75 85 16.25 0 124 1',
      'transition': '0.87, 0, 0.13, 1',
      'maths': 'return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? pow(2, 20 * x - 10) / 2 : (2 - pow(2, -20 * x + 10)) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InCirc',
      'path': 'M1 84c67.75 1 124-37.25 123-83',
      'transition': '0.55, 0, 1, 0.45',
      'maths': 'return 1 - sqrt(1 - pow(x, 2));',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutCirc',
      'path': 'M1 84C0 38.25 56.25 0 124 1',
      'transition': '0, 0.55, 0.45, 1',
      'maths': 'return sqrt(1 - pow(x - 1, 2));',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutCirc',
      'path': 'M1 84C106.25 85 18.75 0 124 1',
      'transition': '0.85, 0, 0.15, 1)"',
      'maths': 'return x < 0.5 ? (1 - sqrt(1 - pow(2 * x, 2))) / 2 : (sqrt(1 - pow(-2 * x + 2, 2)) + 1) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InBack',
      'path': 'M1 84c44 1 81.5 48.6 123-83',
      'transition': '0.36, 0, 0.66, -0.56',
      'maths': 'const c1 = 1.70158;const c3 = c1 + 1;return c3 * x * x * x - c1 * x * x;',
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutBack',
      'path': 'M1 84C42.5-47.6 80 0 124 1',
      'transition': '0.34, 1.56, 0.64, 1',
      'maths': 'const c1 = 1.70158;const c3 = c1 + 1;return 1 + c3 * pow(x - 1, 3) + c1 * pow(x - 1, 2);',
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutBack',
      'path': 'M1 84C85 136 40-51 124 1',
      'transition': '0.68, -0.6, 0.32, 1.6',
      'maths': 'const c1 = 1.70158; const c2 = c1 * 1.525; return x < 0.5 ? (pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2 : (pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;',
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InElastic',
      'path': 'M1 84l.24.03 1.24-.02 1.24-.02 1.24-.02 1.24-.03 1.24-.02 1.24-.03 1.24-.02 1.24-.02 1.24-.01 1.24-.01h1.24l1.24.01 1.24.02 1.24.02 1.24.04 1.24.05 1.24.06 1.24.06 1.24.07 1.24.08 1.24.06 1.24.07 1.24.05 1.24.04 1.24.03h1.24l1.24-.03 1.24-.05 1.24-.08 1.24-.11 1.24-.14 1.24-.16 1.24-.19 1.24-.19 1.24-.21 1.24-.19 1.24-.18 1.24-.16 1.24-.11 1.24-.07h1.24l1.24.07 1.24.15 1.24.23 1.24.32 1.24.39 1.24.46 1.24.52 1.24.55 1.24.57 1.24.56 1.24.51 1.24.44 1.24.32 1.24.18 1.24.01 1.24-.2 1.24-.42 1.24-.66 1.24-.88 1.24-1.11 1.24-1.31 1.24-1.46 1.24-1.57 1.24-1.61 1.24-1.58 1.24-1.45 1.24-1.23 1.24-.92 1.24-.51 1.24-.01 1.24.56 1.24 1.19L93 76.58l1.24 2.5 1.24 3.14 1.24 3.69 1.24 4.14 1.24 4.45 1.24 4.56 1.24 4.46 1.24 4.1 1.24 3.48 1.24 2.6 1.24 1.43 1.24.04 1.24-1.59 1.24-3.36 1.24-5.22 1.24-7.09 1.24-8.87 1.24-10.44 1.24-11.73 1.24-12.57 1.24-12.9 1.24-12.6 1.24-11.6 1.24-9.86',
      'transition': '', 
      'maths': 'const c4 = (2 * Math.PI) / 3; return x === 0 ? 0 : x === 1 ? 1 : -pow(2, 10 * x - 10) * sin((x * 10 - 10.75) * c4);',
      'InElastic': true,
      'gradient': 'url(#in)'
    },
    {
      'name': 'OutElastic',
      'path': 'M1 84l.24-7.34 1.24-9.86 1.24-11.6 1.24-12.6L6.2 29.7l1.24-12.57L8.68 5.4 9.92-5.04l1.24-8.87L12.4-21l1.24-5.22 1.24-3.36 1.24-1.59 1.24.04 1.24 1.43 1.24 2.6 1.24 3.48 1.24 4.1 1.24 4.46 1.24 4.56 1.24 4.45 1.24 4.14 1.24 3.69 1.24 3.14L31 7.42l1.24 1.85 1.24 1.19 1.24.56 1.24-.01 1.24-.51 1.24-.92 1.24-1.23 1.24-1.45 1.24-1.58 1.24-1.61 1.24-1.57L45.88.68l1.24-1.31 1.24-1.11 1.24-.89 1.24-.65 1.24-.42 1.24-.2 1.24.01 1.24.18 1.24.32 1.24.44 1.24.51 1.24.56 1.24.57 1.24.55 1.24.52 1.24.46 1.24.39 1.24.32 1.24.23 1.24.15 1.24.07h1.24l1.24-.07 1.24-.11 1.24-.16 1.24-.18 1.24-.19L80.6.46l1.24-.19 1.24-.19 1.24-.16 1.24-.14 1.24-.11 1.24-.08 1.24-.05 1.24-.03h1.24L93-.46l1.24.04 1.24.05 1.24.07 1.24.06 1.24.08 1.24.07 1.24.06 1.24.06 1.24.05 1.24.04 1.24.02 1.24.02 1.24.01h1.24l1.24-.01 1.24-.01 1.24-.02 1.24-.02 1.24-.03 1.24-.02 1.24-.03 1.24-.02 1.24-.02 1.24-.02',
      'transition': '',
      'maths': 'const c4 = (2 * Math.PI) / 3; return x === 0 ? 0 : x === 1 ? 1 : pow(2, -10 * x) * sin((x * 10 - 0.75) * c4) + 1;',
      'OutElastic': true,
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutElastic',
      'path': 'M1 84l.24-.02 1.24-.02 1.24-.01 1.24-.02 1.24-.01 1.24-.01h1.24l1.24.01 1.24.02 1.24.03 1.24.05 1.24.06 1.24.07 1.24.08 1.24.08 1.24.07 1.24.04 1.24.02 1.24-.03 1.24-.08 1.24-.15 1.24-.21 1.24-.28 1.24-.33 1.24-.37 1.24-.37 1.24-.33 1.24-.26 1.24-.12 1.24.08 1.24.32 1.24.62 1.24.93 1.24 1.25 1.24 1.52 1.24 1.71 1.24 1.78 1.24 1.66 1.24 1.34 1.24.75 1.24-.11 1.24-1.23 1.24-2.57 1.24-4.05 1.24-5.56 1.24-6.92 1.24-7.97 1.24-8.45 1.24-8.16L62 42l1.24-6.85 1.24-8.16 1.24-8.45 1.24-7.97 1.24-6.92 1.24-5.56 1.24-4.05 1.24-2.57 1.24-1.23 1.24-.11 1.24.75 1.24 1.34 1.24 1.66 1.24 1.78 1.24 1.72 1.24 1.51L83.08.14l1.24.93 1.24.62 1.24.32 1.24.08 1.24-.12 1.24-.26 1.24-.33L93 1.01l1.24-.37 1.24-.33 1.24-.28 1.24-.21 1.24-.15 1.24-.08 1.24-.03 1.24.02 1.24.04 1.24.07 1.24.08 1.24.08 1.24.07 1.24.06 1.24.05 1.24.03 1.24.02 1.24.01h1.24l1.24-.01 1.24-.01 1.24-.02 1.24-.01 1.24-.02',
      'transition': '',
      'maths': 'const c5 = (2 * Math.PI) / 4.5; return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? -(pow(2, 20 * x - 10) * sin((20 * x - 11.125) * c5)) / 2 : (pow(2, -20 * x + 10) * sin((20 * x - 11.125) * c5)) / 2 + 1;',
      'InOutElastic': true,
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'InBounce',
      'path': 'M1 84l.24-.51 1.24-.39 1.24-.26 1.24-.13 1.24-.01 1.24.12 1.24.25 1.24.38 1.24.5L12.4 83l1.24-.97 1.24-.85 1.24-.72 1.24-.6 1.24-.47 1.24-.34 1.24-.21 1.24-.09 1.24.04 1.24.17 1.24.29 1.24.43 1.24.54 1.24.68 1.24.8 1.24.93 1.24 1.06 1.24-1.34 1.24-2.15 1.24-2.03 1.24-1.9 1.24-1.77 1.24-1.65 1.24-1.52 1.24-1.39 1.24-1.26 1.24-1.14 1.24-1.01 1.24-.88 1.24-.76 1.24-.63 1.24-.5 1.24-.38 1.24-.25 1.24-.12 1.24.01 1.24.13 1.24.26 1.24.39 1.24.51 1.24.64 1.24.77 1.24.9 1.24 1.02 1.24 1.15 1.24 1.27 1.24 1.41L71.92 73l1.24 1.66 1.24 1.78 1.24 1.91 1.24 2.04 1.24 2.17 1.24-.23 1.24-4.51 1.24-4.39 1.24-4.25 1.24-4.13 1.24-4 1.24-3.88 1.24-3.75 1.24-3.62 1.24-3.49 1.24-3.37L93 39.7l1.24-3.11 1.24-2.99 1.24-2.85 1.24-2.74 1.24-2.6 1.24-2.48 1.24-2.35 1.24-2.22 1.24-2.1 1.24-1.97 1.24-1.84 1.24-1.71 1.24-1.59 1.24-1.46 1.24-1.34 1.24-1.2 1.24-1.08 1.24-.96 1.24-.82 1.24-.7 1.24-.57 1.24-.45 1.24-.32 1.24-.19',
      'transition': '',
      'maths': 'return 1 - easeOutBounce(1 - x);',
      'InBounce': true,
      'gradient': 'url(#in)'
      
    },
    {
      'name': 'OutBounce',
      'path': 'M1 84l.24-.06 1.24-.19 1.24-.32 1.24-.45 1.24-.57 1.24-.7 1.24-.82 1.24-.96 1.24-1.08 1.24-1.2 1.24-1.34 1.24-1.46 1.24-1.59 1.24-1.71 1.24-1.84 1.24-1.97 1.24-2.1 1.24-2.22 1.24-2.35 1.24-2.48 1.24-2.6 1.24-2.74 1.24-2.85 1.24-2.99L31 44.3l1.24-3.24 1.24-3.37 1.24-3.49 1.24-3.62 1.24-3.75 1.24-3.88 1.24-4 1.24-4.13 1.24-4.25 1.24-4.39 1.24-4.51 1.24-.23 1.24 2.17 1.24 2.04 1.24 1.91 1.24 1.78L52.08 11l1.24 1.53 1.24 1.41 1.24 1.27 1.24 1.15 1.24 1.02 1.24.9 1.24.77 1.24.64 1.24.51 1.24.39 1.24.26 1.24.13 1.24.01 1.24-.12 1.24-.25 1.24-.38 1.24-.5 1.24-.63 1.24-.76 1.24-.88 1.24-1.01 1.24-1.14 1.24-1.26 1.24-1.39 1.24-1.52 1.24-1.65 1.24-1.77 1.24-1.9 1.24-2.03 1.24-2.15L90.52.31l1.24 1.06L93 2.3l1.24.8 1.24.68 1.24.54 1.24.43 1.24.29 1.24.17 1.24.04 1.24-.09 1.24-.21 1.24-.34 1.24-.47 1.24-.6 1.24-.72 1.24-.85L111.6 1l1.24-.95 1.24.5 1.24.38 1.24.25 1.24.12 1.24-.01 1.24-.13 1.24-.26 1.24-.39',
      'transition': '',
      'maths': 'const n1 = 7.5625; const d1 = 2.75; if (x < 1 / d1) { return n1 * x * x; } else if (x < 2 / d1) { return n1 * (x -= 1.5 / d1) * x + 0.75; } else if (x < 2.5 / d1) { return n1 * (x -= 2.25 / d1) * x + 0.9375; } else { return n1 * (x -= 2.625 / d1) * x + 0.984375; }',
      'OutBounce': true,
      'gradient': 'url(#out)'
    },
    {
      'name': 'InOutBounce',
      'path': 'M1 84l.24-.45 1.24-.2 1.24.06 1.24.31 1.24-.22 1.24-.91 1.24-.66 1.24-.4 1.24-.15 1.24.1 1.24.36 1.24.61 1.24.87 1.24-.14 1.24-2.09 1.24-1.84 1.24-1.58 1.24-1.33 1.24-1.07 1.24-.83 1.24-.56 1.24-.31 1.24-.06 1.24.2 1.24.45 1.24.7 1.24.96 1.24 1.21 1.24 1.47 1.24 1.72 1.24 1.98 1.24.96 1.24-4.44 1.24-4.2 1.24-3.93 1.24-3.69 1.24-3.43 1.24-3.17 1.24-2.93 1.24-2.67 1.24-2.41 1.24-2.16 1.24-1.9 1.24-1.66 1.24-1.39 1.24-1.15 1.24-.89 1.24-.63 1.24-.38L62 42l1.24-.13 1.24-.38 1.24-.63 1.24-.89 1.24-1.15 1.24-1.39 1.24-1.66 1.24-1.9 1.24-2.16 1.24-2.42 1.24-2.66 1.24-2.93 1.24-3.17 1.24-3.43 1.24-3.69 1.24-3.93 1.24-4.2L84.32.84l1.24.96 1.24 1.98 1.24 1.72 1.24 1.47 1.24 1.21 1.24.96 1.24.7 1.24.45 1.24.2 1.24-.06 1.24-.31 1.24-.57 1.24-.82 1.24-1.07 1.24-1.33 1.24-1.58 1.24-1.84 1.24-2.09 1.24-.14 1.24.87 1.24.61 1.24.36 1.24.1 1.24-.15 1.24-.4 1.24-.66L117.8.5l1.24-.22 1.24.31 1.24.06 1.24-.2',
      'transition': '',
      'maths': 'return x < 0.5 ? (1 - easeOutBounce(1 - 2 * x)) / 2: (1 + easeOutBounce(2 * x - 1)) / 2;',
      'InOutBounce': true,
      'gradient': 'url(#inOut)'
    },
    {
      'name': 'default',
      'path': 'M 0,85	L 125,0',
      'transition': '',
      'maths': '',
      'default': true,
      'gradient': 'url(#inOut)'
    }
  ],

  percents: [
    '100%',
    '90%',
    '80%',
    '70%',
    '60%',
    '50%',
    '40%',
    '30%',
    '20%',
    '10%'
  ],

  durations: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60'
  ],

  objectAttributes: [
    { attr: 'left', type: 0 },
    { attr: 'top', type: 0 },
    { attr: 'lock_movement', type: 0 },
    { attr: 'opacity', type: 0 },
    { attr: 'shadow', type: 0 },
    { attr: 'opacity', type: 0 },
    { attr: 'flip_horizontally', type: 0 },
    { attr: 'flip_vertically', type: 0 },
    { attr: 'text', type: 1 },
    { attr: 'editable', type: 1 },
    { attr: 'text_fill_color', type: 1 },
    { attr: 'fill_color', type: 1 },
    { attr: 'background_color', type: 0 },
    { attr: 'font_family', type: 1 },
    { attr: 'font_size', type: 1 },
    { attr: 'text_alignment', type: 1 },
    { attr: 'source_image', type: 2 },
    { attr: 'height', type: 0 },
    { attr: 'width', type: 0 }
  ],

  moveDirections: [
    'none',
    'left-to-right',
    'right-to-left',
    'top-to-bottom',
    'bottom-to-top'
  ],

  speeds: ['slow', 'medium', 'fast', 'veryFast'],

  textAssets: [
    {
      padding: 20,
      src: 'bubble1.png',
      style: '1'
    },
    {
      padding: 40,
      src: 'bubble2.png',
      style: '2'
    },
    {
      padding: 5,
      src: 'bubble3.png',
      style: '3'
    },
    {
      padding: 20,
      src: 'bubble4.png',
      style: '4'
    }
  ],

  spineAssets: [
    {
      src: '/img/spineboy.png',
      json: "/img/Spine/spineboy/spineboy.json",
      tintedImg: false
    },
    {
      src: '/img/hero.png',
      json: "/img/Spine/hero/hero.json",
      tintedImg: false
    },
    {
      src: '/img/alien.png',
      json: "/img/Spine/alien/alien.json",
      tintedImg: false
    },
    {
      src: '/img/dragon.png',
      json: "/img/Spine/dragon/dragon.json",
      tintedImg: false
    },
    {
      src: '/img/yeti.png',
      json: "/img/Spine/yeti/yeti.json",
      tintedImg: false
    },
    {
      src: '/img/bluebuck2.png',
      json: "/img/Spine/bluebuck2/bluebuck2.json",
      tintedImg: false
    },
    {
      src: '/img/Goat.png',
      json: "/img/Spine/Goat/Goat.json",
      tintedImg: false
    },
    {
      src: '/img/TasmanianTiger.png',
      json: "/img/Spine/TasmanianTiger/TasmanianTiger.json",
      tintedImg: false
    },
    {
      src: '/img/JerseyDevil.png',
      json: "/img/Spine/JerseyDevil/JerseyDevil.json",
      tintedImg: false
    },
    {
      src: '/img/girl.png',
      json: "/img/Spine/girl/girl.json",
      tintedImg: true
    },
    {
      src: '/img/boy.png',
      json: "/img/Spine/boy/boy.json",
      tintedImg: true
    },
    // {
    //   src: '/img/raptor.png',
    //   json: "/img/Spine/raptor/raptor.json",
    //   tintedImg: true
    // }
  ],

  basicShapes: [
    {
      src: '/img/square-thumb.png',
      // type: 'squ'
      type: 'rect'
    },
    {
      src: '/img/circle-thumb.png',
      // type: 'cir'
      type: 'circle'
    },
    {
      src: '/img/triangle-thumb.png',
      // type: 'tri'
      type: 'triangle'

    }
  ],
  widgetAssets: [
    {
      src: 'button.png',
      type: 'Textbutton'
    },
    {
      src: 'textfield.png',
      type: 'textfield'
    }
  ],
  paletteColors: [
    // pallette colors
    // first row
    '#ff5c5cff',
    '#ffbd4aff',
    '#fff952ff',
    '#99e265ff',
    '#35b729ff',
    // second row
    '#44d9e6ff',
    '#2eb2ffff',
    '#5271ffff',
    '#b760e6ff',
    '#ff63b1ff',
    '#663300ff',
    // third row
    '#000000ff',
    '#333333ff',
    '#666666ff',
    '#a8a8a8ff',
    '#d9d9d9ff',
    '#ffffffff'
  ],

  userColors: [
    // pallette colors
    '#ff5c5cff',
    '#ffbd4aff',
    '#fff952ff',
    '#99e265ff',
    '#35b729ff',
    '#44d9e6ff',
    '#2eb2ffff',
    '#5271ffff',
    '#b760e6ff',
    '#ff63b1ff',
    '#663300ff',
    // darker
    '#db0000FF',
    '#C98000FF',
    '#D1CA00FF',
    '#58A820FF',
    '#174F12FF',
    '#148C96FF',
    '#006EADFF',
    '#0026D1FF',
    '#F7A0FFFF',
    '#E20071FF',
    '#000000FF',
    // lighten
    '#FF9C9CFF',
    '#FFFD8AFF',
    '#FFFF92FF',
    '#D9FFA5FF',
    '#75F769FF',
    '#84FFFFFF',
    '#6EF2FFFF',
    '#92B1FFFF',
    '#791BACFF',
    '#FFA3F1FF',
    '#A67340FF'
  ],
  musicMoods: {
    Happy: 'mdiEmoticonHappyOutline',
    Aggressive: 'mdiFlashOutline',
    Sad: 'mdiEmoticonSadOutline',
    Relaxing: 'mdiCloudOutline',
    Romantic: 'mdiHeart',
    Suspenseful: 'mdiBrightness2',
    Epic: 'mdiFire'
  },
  soundEffectGenres: {
    Animals: 'mdiCat',
    Bells: 'mdiBellRingOutline',
    Human: 'mdiAccountTieVoiceOutline',
    Foley: 'mdiChartBubble',
    Instrument: 'mdiMusicNote',
    Cartoon: 'mdiBabyFaceOutline',
    Nature: 'mdiPineTree',
    PublicPlaces: 'mdiSubwayVariant'
  },

  tween: {
    tweenThreshold: '50%'
  },
  layout: {
    layoutContainer: null,
    mainObjectsContainer: null,
    mainFunctionsContainer: null,
    rightSidebar: null
  },
  positions: {
    centerContainer: {
      left: 0,
      top: 0
    }
  },

  recordVoiceOver: [
    'recordTutorialGoToStepOne',
    'recordTutorialGoToStepTwo',
    'recordTutorialGoToStepThree',
    'recordTutorialGoToStepFour',
  ],

  // editLayoutKeys: [
  //   "text", 
  //   "fontFamily",
  //   "fill", 
  //   "opacity",
  //   "lockMovementX",
  //   "tailPointx", //speechbubble tail
  //   "scaleX",
  //   "angle",
  //   "currentSkin", //always sent along with 'attachments' when Spine character is modified
  //   "stroke", //for shapes
  //   "strokeWidth", //for shapes
  //   "btnBgColor",
  //   "rx", //button corner radius
  //   "shadow",
  //   "textAlign", 
  //   "fontWeight", 
  //   "fontStyle",
  //   "underline",
  // ],

  templateKeys: [
    {
      name: 'title',
      code: 'ti',
    },
    {
      name: 'forward page',
      code: 'fw',
    },
    {
      name: 'voice over',
      code: 'vo',
    },        
    {
      name: 'multiple choice',
      code: 'mc',
    },
    {
      name: 'game over',
      code: 'go',
    },
    {
      name: 'text input',
      code: 'te',
    },
    {
      name: 'speech recognition',
      code: 'sp',
    },
    {
      name: 'collision',
      code: 'co',
    },
  ], 
  editLayoutKeys: [
    {text: null },
    {fontWeight: "mdiFormatBold" },
    {fontFamily: null},
    {fontSize: null},
    {fill: "mdiFormatColorText"},
    {opacity: "mdiOpacity"},
    {lockMovementX: 'mdiCheckboxBlankOutline'},
    {tailPointx: null},
    {scaleX: null},
    {angle: null},
    {currentSkin: null},
    {stroke: 'mdiSelectColor'},
    {strokeWidth: "mdiFormatLineWeight"},
    {lineWidth: "mdiFormatLineWeight"},    
    {btnBgColor: "mdiFormatColorFill"},
    {rx: "mdiRoundedCorner"},
    {shadow: 'mdiCheckboxBlankOutline'},
    {textAlign: {
      left: "mdiFormatAlignLeft",
      center: "mdiFormatAlignCenter",
      justify: "mdiFormatAlignJustify",
      right: "mdiFormatAlignRight"
    }},
    {fontStyle: "mdiFormatItalic"},
    {underline: "mdiFormatUnderline"},
    {flipX: "mdiFlipHorizontal"},
    {bgColor: "mdiFormatColorFill"}, 
    {toFront: "mdiFlipToFront"},
    {toBack: "mdiFlipToBack"}
  ],
  
  editBlockKeys: [
    "threshold",
    "rotation",
    "angle",
    "delay",
    "direction",
    "drag",
    "duration",
    "easing",
    "effect",
    "flip",
    "glow",
    "lang",
    "loop",
    "name",
    "nameA",
    "nameB",
    "nameC",
    "once",
    "operation",
    "page",
    "repeat",
    "scale",
    "sound",
    "speed",
    "url",
    "value",
    "variable",
    "volume",
    "word",
    "xCoord",
    "yCoord",
    "add_variable", 
    "edit_variable"
    ],
  tutorials: {
    animation: {
      media_url: "/img/studio/tutorials/gifs/animate_tutorial.gif",
      data: [
        {
          task: {
            layout_objects: [
              {
                url: "/img/Spine/dragon/dragon.json",
                type: 'Spine',
                is_added: true,
                proxy: 'animation 0'
              }  
            ]
          },
          initActions: ['addNewPage'],
          layoutMode: true,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: true,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'onPageStartBlock',
                proxy: 'onPageStartBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'animateSpineBlock',
                proxy: 'animateSpineBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'objectBlock', 
                target_object: 'animation 0',
                proxy: 'objectBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'outputPort',
                  node_proxy: 'onPageStartBlock 1'
                },
                target: {
                  port: "conditionPort",
                  node_proxy: 'animateSpineBlock 1'
                },
                proxy: 'connection 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'objectPort',
                  node_proxy: 'objectBlock 1'
                },
                target: {
                  port: "objectPort",
                  node_proxy: 'animateSpineBlock 1'
                },
                proxy: 'connection 2'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                name: "flying",
                cssClass: 'animateSpineBlock',
                proxy: 'animateSpineBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_preview_btn: true
          },
          layoutMode: false,
        }
      ]
    },

    record: {
      data: [
        {
          task: {
            record: 'record 1'
          },
          initActions: ['addNewPage'],
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'onPageStartBlock',
                proxy: 'onPageStartBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'playRecordBlock',
                proxy: 'playRecordBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'outputPort',
                  node_proxy: 'onPageStartBlock 1'
                },
                target: {
                  port: "conditionPort",
                  node_proxy: 'playRecordBlock 1'
                },
                proxy: 'connection 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                cssClass: 'playRecordBlock',
                sound: null,
                proxy: 'playRecordBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        ]
    }, 
    fadeIn: {
      media_url: "/img/studio/tutorials/gifs/fade_in_tutorial.gif",
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'image',
                url: null,
                is_added: true,
                proxy: 'image 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            click_next_btn: true
          },
          layoutMode: true,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'onPageStartBlock',
                proxy: 'onPageStartBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'fadeBlock',
                proxy: 'fadeBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'objectBlock', 
                target_object: 'image 0',
                proxy: 'objectBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'outputPort',
                  node_proxy: 'onPageStartBlock 1'
                },
                target: {
                  port: "conditionPort",
                  node_proxy: 'fadeBlock 1'
                },
                proxy: 'connection 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'objectPort',
                  node_proxy: 'objectBlock 1'
                },
                target: {
                  port: "objectPort",
                  node_proxy: 'fadeBlock 1'
                },
                proxy: 'connection 2'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_preview_btn: true
          },
          layoutMode: false,
        }
      ]
    },
    textEffect: {
      media_url: "/img/studio/tutorials/gifs/text_effect_tutorial.gif",
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                is_added: true,
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                text: null,
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: true,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'onPageStartBlock',
                proxy: 'onPageStartBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'textEffectBlock',
                proxy: 'textEffectBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'objectBlock', 
                target_object: 'textbox 0',
                proxy: 'objectBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'outputPort',
                  node_proxy: 'onPageStartBlock 1'
                },
                target: {
                  port: "conditionPort",
                  node_proxy: 'textEffectBlock 1'
                },
                proxy: 'connection 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'objectPort',
                  node_proxy: 'objectBlock 1'
                },
                target: {
                  port: "objectPort",
                  node_proxy: 'textEffectBlock 1'
                },
                proxy: 'connection 2'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                effect: "fadeIn",
                cssClass: 'textEffectBlock',
                proxy: 'textEffectBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            click_preview_btn: true
          },
          layoutMode: false,
        }
      ]
    },
    createTextVariable: {
      media_url: "/img/studio/tutorials/gifs/variable_tutorial.gif",
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'textfield',
                is_added: true,
                proxy: 'textfield 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                proxy: 'textfield 0',
                text: 'What is your name?'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                add_variable: true,
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1',
                variable_proxy: 'variable 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                edit_variable: true,
                entity: {
                  name: 'name'
                },
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1',
                variable_proxy: 'variable 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'objectBlock', 
                target_object: 'textfield 0',
                proxy: 'objectBlock 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'onTextInputBlock',
                proxy: 'onTextInputBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'textVariableEqualsTextObjectBlock',
                proxy: 'textVariableEqualsTextObjectBlock 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            click_next_btn: true
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'objectPort',
                  node_proxy: 'objectBlock 1'
                },
                target: {
                  port: "objectPort",
                  node_proxy: 'onTextInputBlock 1'
                },
                proxy: 'connection 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'objectPort',
                  node_proxy: 'objectBlock 1'
                },
                target: {
                  port: "objectPort",
                  node_proxy: 'textVariableEqualsTextObjectBlock 1'
                },
                proxy: 'connection 2'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: "draw2d_Connection",
                source: {
                  port: 'outputPort',
                  node_proxy: 'onTextInputBlock 1'
                },
                target: {
                  port: "conditionPort",
                  node_proxy: 'textVariableEqualsTextObjectBlock 1'
                },
                proxy: 'connection 3'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                is_added: true,
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                proxy: 'textbox 0',
                text: 'Hello {{ name }}!'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            click_preview_btn: true
          }
        }
      ]
    },
    createVariable: {
      media_url: "/img/studio/tutorials/gifs/variable_tutorial.gif",
      data: [
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                add_variable: true,
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1',
                variable_proxy: 'variable 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                edit_variable: null,
                cssClass: 'createTextVariables',
                proxy: 'createTextVariables 1',
                variable_proxy: 'variable 1'
              }
            ]
          },
          layoutMode: false,
        },
      
      
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'createBooleanVariables',
                proxy: 'createBooleanVariables 1',
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                add_variable: true,
                cssClass: 'createBooleanVariables',
                proxy: 'createBooleanVariables 1',
                variable_proxy: 'variable 2'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                add_variable: true,
                cssClass: 'createBooleanVariables',
                proxy: 'createBooleanVariables 1',
                variable_proxy: 'variable 3'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                edit_variable: null,
                cssClass: 'createBooleanVariables',
                proxy: 'createBooleanVariables 1',
                variable_proxy: 'variable 3'
              }
            ]
          },
          layoutMode: false,
        },    
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'createNumberVariables',
                proxy: 'createNumberVariables 1'
              }
            ]          
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                is_added: true,
                cssClass: 'createNumberVariables',
                proxy: 'createNumberVariables 1'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                add_variable: true,
                cssClass: 'createNumberVariables',
                proxy: 'createNumberVariables 1',
                variable_proxy: 'variable 4'
              }
            ]
          },
          layoutMode: false,
        },
        {
          task: {
            event_figures: [
              {
                edit_variable: null,
                cssClass: 'createNumberVariables',
                proxy: 'createNumberVariables 1',
                variable_proxy: 'variable 4'
              }
            ]
          },
          layoutMode: false,
        },
      ]
    },
    test: {
      media_url: "/img/studio/tutorials/gifs/animate_tutorial.gif",

          data: [
            {
              "id": "K6Hk46", 
              "task": {
                "page_proxy": "CrL0ae",
                "layout_objects": [
                  {
                    "type": "textbox",
                    "proxy": "bRCV6q",
                    "is_added": true,
                    "page_proxy": "CrL0ae"
                  }
                ]
              }, 
              "layoutMode": true
            }, 
            {
              "id": "QM3AyL",
              "task": {
                "page_proxy": "CrL0ae",
                "layout_objects": [
                  {
                    "proxy": "bRCV6q",
                    "fontFamily": null,
                    "page_proxy": "CrL0ae"
                  }
                ]
              }, 
              "layoutMode": true
            }, {"id": "3tBb0I", "task": {"page_proxy": "CrL0ae", "layout_objects": [{"proxy": "bRCV6q", "fontSize": null, "page_proxy": "CrL0ae"}]}, "layoutMode": true}, {"id": "8mqWjl", "task": {"page_proxy": "CrL0ae", "layout_objects": [{"proxy": "bRCV6q", "shadow": null, "page_proxy": "CrL0ae"}]}, "layoutMode": true}

      //   {
      //     "task": {
      //       layout_objects: [{
      //         "is_added": true,
      //         "proxy": "eqmRwa",
      //         "type": "textbox"
      //       }]
      //     },
      //     "layoutMode": true
      //   },
      //   {
      //     "task": {
      //       layout_objects: [{
      //         "is_paste": true,
      //         "proxy": "gY8O9Q",
      //         "original_proxy": "eqmRwa",
      //       }]
      //     }
      //   },
      //   {
      //     "task": {
      //       "isduplicatepage": true,
      //       "previous_page_proxy": "ulhysp",
      //       "page_proxy": "5oeZNy"
      //     }
      //   },
      //   {
      //     "task": {
      //         "isnewpage": true,
      //         "previous_page_proxy": "5oeZNy",
      //         "page_proxy": "5oeZNr"
      //     }
      // },
      //   {
      //       "task": {
      //           "layout_objects": [
      //               {
      //                   "type": "textbox",
      //                   "left": null,
      //                   "top": null,
      //                   "is_added": true,
      //                   "proxy": "LV6zo5",
      //                   "page_proxy": "5oeZNy"
      //               }
      //           ]
      //       },
      //       "layoutMode": true
      //   },
        // {
        //   task: {
        //     uploadImage: true
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     addImageFolder: true
        //   },
        //   layoutMode: true,
        // },
     
        // {
        //   task: {
        //     addTemplate: true,
        //     slug: "UtiXIlVHIZ",
        //     code: 'ti'            
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         type: 'textbox',
        //         text: null,
        //         is_added: true,
        //         proxy: 'textbox 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         scaleX: null,
        //         scaleY: null,
        //         proxy: 'textbox 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         width: null,
        //         height: null,
        //         proxy: 'textbox 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         angle: null,
        //         proxy: 'textbox 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         is_added: true,
        //         cssClass: 'rotateBlock',
        //         proxy: 'rotateBlock 1'
        //       }
        //     ]          
        //   },
        //   layoutMode: false,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         is_added: true,
        //         cssClass: 'createBooleanVariables',
        //         proxy: 'createBooleanVariables 1'
        //       }
        //     ]          
        //   },
        //   layoutMode: false,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         is_added: true,
        //         cssClass: 'createNumberVariables',
        //         proxy: 'createNumberVariables 1'
        //       }
        //     ]          
        //   },
        //   layoutMode: false,
        // }
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         add_variable: true,
        //         cssClass: 'createTextVariables',
        //         proxy: 'createTextVariables 1',
        //         variable_proxy: 'variable 1'
        //       }
        //     ]
        //   },
        //   layoutMode: false,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         variable_proxy: 'variable 1',
        //         edit_variable: true,
        //         entity: {
        //           name: 'rawr'
        //         },
        //         cssClass: 'createTextVariables',
        //         proxy: 'createTextVariables 1'
        //       }
        //     ]
        //   },
        //   layoutMode: false,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         is_added: true,
        //         cssClass: 'textEffectBlock',
        //         proxy: 'textEffectBlock 1'
        //       }
        //     ]          
        //   },
        //   layoutMode: false,
        // },
        // {
        //   task: {
        //     event_figures: [
        //       {
        //         effect: "fadeIn",
        //         cssClass: 'textEffectBlock',
        //         proxy: 'textEffectBlock 1'
        //       }
        //     ]
        //   },
        //   layoutMode: false,
        // }
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         type: 'image',
        //         is_bg: true
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         type: 'image',
        //         is_added: true,
        //         proxy: 'image 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         type: 'Textbutton',
        //         is_added: true,
        //         proxy: 'button 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
        // {
        //   task: {
        //     layout_objects: [
        //       {
        //         type: 'textfield',
        //         is_added: true,
        //         proxy: 'textfield 0'
        //       }  
        //     ]
        //   },
        //   layoutMode: true,
        // },
      ]
    },
    imagetoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'image',
                is_added: true,
                proxy: 'image 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'image',
                shadow: null, 
                proxy: 'image 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'image',
                flipX: true, 
                proxy: 'image 0'
              }  
            ]
          },
          layoutMode: true,
        },  
      ]
    },

    shapetoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                is_added: true,
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                shadow: null, 
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                fill: null, 
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        },  
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                stroke: null, 
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                strokeWidth: null, 
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        },   
        {
          task: {
            layout_objects: [
              {
                type: 'rect',
                opacity: null, 
                proxy: 'rect 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
      ]
    },

    animationtoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                url: "/img/Spine/girl/girl.json",
                type: 'Spine',
                is_added: true,
                proxy: 'animation 0'
              }  
            ]
          },
          initActions: ['addNewPage'],
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                opacity: null, 
                proxy: 'animation 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                flipX: true, 
                proxy: 'animation 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                currentSkin: null,
                attachments: null, 
                proxy: 'animation 0'
              }  
            ]
          },
          layoutMode: true,
        },
      ]
    },

    texttoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                is_added: true,
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                shadow: null, 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                fontFamily: "Lexend", 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                fontSize: 24, 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                textAlign: "left", 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                fontWeight: "bold", 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                fontStyle: "italic",
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },    
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                underline: true, 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },         
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                fill: null, 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        },        
        {
          task: {
            layout_objects: [
              {
                type: 'textbox',
                opacity: null, 
                proxy: 'textbox 0'
              }  
            ]
          },
          layoutMode: true,
        }   
      ]
    },
    bubbletoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                is_added: true,
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                shadow: null, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                fontFamily: "Lexend", 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                fontSize: 24, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                fontWeight: "bold", 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                fontStyle: "italic",
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },    
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                underline: true, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },         
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                bgColor: null, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },        
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                fill: null, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                lineWidth: null, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        },      
        {
          task: {
            layout_objects: [
              {
                type: 'speechbubble',
                opacity: null, 
                proxy: 'speechbubble 0'
              }  
            ]
          },
          layoutMode: true,
        }   
      ]
    },
    buttontoolbar: {
      data: [
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                is_added: true,
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                shadow: null, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                fontFamily: "Lexend", 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                fontSize: 24, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        }, 
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                fontWeight: "bold", 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                fontStyle: "italic",
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        },            
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                btnBgColor: null, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        },  
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                fill: null, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        },        
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                opacity: null, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        },
        {
          task: {
            layout_objects: [
              {
                type: 'Textbutton',
                rx: null, 
                proxy: 'Textbutton 0'
              }  
            ]
          },
          layoutMode: true,
        }      
      ]
    }
  }

  // dimensions: {
  //   page: {
  //     height: 0,
  //     width: 0
  //   }
  // }
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  // setPageDimensions(state, dimensions) {
  //   state.dimensions.page = dimensions;
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
