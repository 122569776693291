
import LZString from 'lz-string'

function initialState() {
  return {
    createProject: null,
    previewDialog: false,
    soundPanel: null,
    chatHistoryDialog: false,
    chatHistory: null,
    project: {
      author_username: null,
      layout: [],
      event: [],
      thumb: [],
      sounds: [
        []
      ],
      global_variables: [],
      pageScale: [],
      fonts: {},
      slug: null
    },
    projectDetails: {
      title: '',
      synoposis: '',
      tags: [],
      genre: null,
      status: null,
      slug: null,
      classroom: null,
      assignment: {},
      studentmilestone: {},
      has_room_edit_perms: false,
      student_proxy: {}
    },
    tabIndex: 0,
    tabActive: false,
    activeFonts: {},
    transferStoryDialog: false,
    transferIsOn: false,
    fontsLoaded: false,
    generateCover: false,
    isLessonEditorMode: false,
    nextMilestone: {
      nextMilestoneDialog: false,
      editDialog: false,
      showConfirmMilestone: true,
      showProgress: false,
      hideConfirmMilestone: false,
      fadeProgress: false,
      showNextMilestone: false,
      hideCloseBtn: false,
      selectedEmoji: null,
      selectedSatisfactionEmoji: null,
      progressValue: 0
    },
    addFeedback: {
      comment: null,
      category: null
    },
    lessonEditorAssignment: {},
    currentTutorial: {}
  }
}

const state = {
  initialState: initialState(),
  createProject: initialState().createProject,
  previewDialog: initialState().previewDialog,
  soundPanel: initialState().soundPanel,
  chatHistoryDialog: initialState().chatHistoryDialog,
  chatHistory: initialState().chatHistory,
  project: {
    author_username: initialState().project.author_username,
    layout: initialState().project.layout,
    event: initialState().project.event,
    thumb: initialState().project.thumb,
    sounds: initialState().project.sounds,
    global_variables: initialState().project.global_variables,
    pageScale: initialState().project.pageScale,
    fonts: initialState().project.fonts,
    slug: initialState().project.slug,
  },
  projectDetails: {
    title: initialState().projectDetails.title,
    synoposis: initialState().projectDetails.synoposis,
    tags: initialState().projectDetails.tags,
    genre: initialState().projectDetails.genre,
    status: initialState().projectDetails.status,
    slug: initialState().projectDetails.slug,
    classroom: initialState().projectDetails.classroom,
    assignment: initialState().projectDetails.assignment,
    studentmilestone: initialState().projectDetails.studentmilestone,
    has_room_edit_perms: initialState().projectDetails.has_room_edit_perms,
    student_proxy: initialState().projectDetails.student_proxy
  },
  tabIndex: initialState().tabIndex,
  tabActive: initialState().tabActive,
  activeFonts: initialState().activeFonts,
  transferStoryDialog:  initialState().transferStoryDialog,
  transferIsOn:  initialState().transferIsOn,
  fontsLoaded:  initialState().fontsLoaded,
  generateCover:  initialState().generateCover,
  isLessonEditorMode:  initialState().isLessonEditorMode,
  nextMilestone: {
    nextMilestoneDialog: initialState().nextMilestone.nextMilestoneDialog,
    editDialog: initialState().nextMilestone.editDialog,
    showConfirmMilestone: initialState().nextMilestone.showConfirmMilestone,
    showProgress: initialState().nextMilestone.showProgress,
    hideConfirmMilestone: initialState().nextMilestone.hideConfirmMilestone,
    fadeProgress: initialState().nextMilestone.fadeProgress,
    showNextMilestone: initialState().nextMilestone.showNextMilestone,
    hideCloseBtn: initialState().nextMilestone.hideCloseBtn,
    selectedEmoji: initialState().nextMilestone.selectedEmoji,
    selectedSatisfactionEmoji: initialState().nextMilestone.selectedSatisfactionEmoji,
    progressValue: initialState().nextMilestone.progressValue
  },
  addFeedback: {
    comment: initialState().addFeedback.comment,
    category: initialState().addFeedback.category
  },
  lessonEditorAssignment: initialState().lessonEditorAssignment,
  currentTutorial: initialState().currentTutorial
}

// getters
const getters = {}

// actions
const actions = {
  GENERATE_COVER({
    commit
  }, status) {
    commit('GENERATE_COVER', status)
  },
  FONTS_LOADED({
    commit
  }) {
    commit('FONTS_LOADED')
  },
  resetStudioLauncherState({
    commit
  }) {
    commit('RESET_STATE')
  },
  SET_PREVIEW_DIALOG({
    commit
  }, previewDialog) {
    commit('SET_PREVIEW_DIALOG', previewDialog)
  },
  SET_SOUND_PANEL({
    commit
  }, soundPanel) {
    commit('SET_SOUND_PANEL', soundPanel)
  },
  RESTORE_LAYOUT_PAGE({
    commit
  }, data) {
    commit('RESTORE_LAYOUT_PAGE', data)
  }
}

// mutations
const mutations = {
  CHAT_HISTORY_DIALOG(state, value) {
    state.chatHistoryDialog = value
  },
  CHAT_HISTORY(stat, value){
    stat.chatHistory = value
  },
  GENERATE_COVER(state, status) {
    state.generateCover = status
  },
  FONTS_LOADED(state) {
    state.fontsLoaded = true
  },
  SET_PREVIEW_DIALOG(state, previewDialog) {
    state.previewDialog = previewDialog
  },
  SET_SOUND_PANEL(state, soundPanel) {
    state.soundPanel = soundPanel
  },
  RESET_STATE(state) {
    Object.assign(state, initialState())
  },
  SET_CREATE_PROJECT_LAYOUT(state, layoutName) {
    state.createProject = {
      layout: layoutName
    }
  },
  IS_LESSON_EDITOR_MODE(state, value) {
    state.isLessonEditorMode = value
  },
  SET_CURRENT_TUTORIAL(state, value){
    state.currentTutorial = value
  },
  RESET_CREATE_PROJECT_LAYOUT(state) {
    state.createProject = null
  },
  SET_PROJECT_AUTHOR_USERNAME(state, id) {
    state.project.author_username = id
  },
  SET_PROJECT_SLUG(state, slug) {
    state.project.slug = slug
    state.projectDetails.slug = slug
  },
  SET_PROJECT_LAYOUT(state, layout) {
    state.project.layout = []
    if (layout) {
      for (var i = 0, l = layout.length; i < l; i++) {    
        if (typeof layout[i] == 'string') {
          state.project.layout.push(
            JSON.parse(LZString.decompressFromBase64(layout[i]))
          )
        } else {
          state.project.layout.push(
            layout[i]
          )
        }
        
      }
    }
  },
  SET_PROJECT_EVENTGRAPH(state, event) {
    state.project.event = []
    if (event) {
      for (var i = 0, l = event.length; i < l; i++) {
        if (typeof event[i] == 'string') {
          state.project.event.push(
            JSON.parse(LZString.decompressFromBase64(event[i]))
          )
        } else {
          state.project.event.push(event[i])
        }
      }
    }
  },
  SET_PROJECT_SOUNDS(state, sounds) {
    if (sounds) {
      state.project.sounds = sounds
    } else {
      state.project.sounds = [
        []
      ]
    }
  },
  SET_TRANSFER_IS_ON(state, value) {
    state.transferIsOn = value
  },
  SET_TRANSFER_STORY_DIALOG(state, value) {
    state.transferStoryDialog = value
  },
  SET_PROJECT_GLOBAL_VARIABLES(state, global_variables) {
    if (global_variables) {
      state.project.global_variables = JSON.parse(global_variables)
    } else {
      state.project.global_variables = []
    }
  },
  SET_PROJECT_FONTS(state, fonts) {
    if (fonts) {
      state.project.fonts = JSON.parse(fonts)
    } else {
      state.project.fonts = {}
    }
  },

  // feedback form details
  SET_ADDFEEDBACK_COMMENT(state, comment) {
    state.addFeedback.comment = comment
  },
  SET_ADDFEEDBACK_CATEGORY(state, category) {
    state.addFeedback.category = category
  },

  // form details
  SET_PROJECT_TITLE(state, title) {
    state.projectDetails.title = title
  },
  SET_PROJECT_SYNOPOSIS(state, synoposis) {
    state.projectDetails.synoposis = synoposis
  },
  SET_PROJECT_TAGS(state, tags) {
    state.projectDetails.tags = tags
  },
  SET_PROJECT_ASSIGNMENT(state, assignment) {
    if (assignment) {
      state.projectDetails.assignment = assignment
    } else {
      state.projectDetails.assignment = {}
    }
  },

  SET_PROJECT_STUDENT_PROXY(state, student_proxy) {
    state.projectDetails.student_proxy = student_proxy
  },
  SET_PROJECT_CLASSROOM(state, classroom) {
    if (classroom) {
      state.projectDetails.classroom = classroom
    } else {
      state.projectDetails.classroom = null
    }
  },
  SET_LESSON_EDITOR_ASSIGNMENT(state, assignment) {
    if(assignment){
      state.lessonEditorAssignment = assignment      
    }else {
      state.lessonEditorAssignment = null
    }
  },
  SET_NEXT_MILESTONE_EDIT_DIALOG(state, value) {
    state.nextMilestone.editDialog = value
  },

  SHOW_NEXT_MILESTONE_DIALOG(state) {
    state.nextMilestone.nextMilestoneDialog = true
  },

  CLOSE_NEXT_MILESTONE_DIALOG(state) {
    state.nextMilestone.nextMilestoneDialog = false
    state.nextMilestone.showProgress = false
    state.nextMilestone.showNextMilestone = false
    state.nextMilestone.hideConfirmMilestone = false
    state.nextMilestone.showConfirmMilestone = true
  },

  SET_NEXT_MILESTONE_HIDE_CLOSE_BTN(state, value) {
    state.nextMilestone.hideCloseBtn = value
  },

  SET_NEXT_MILESTONE_SHOW_PROGRESS(state, value) {
    state.nextMilestone.showProgress = value
  },

  SET_NEXT_MILESTONE_FADE_PROGRESS(state, value) {
    state.nextMilestone.fadeProgress = value
  },

  SET_NEXT_MILESTONE_SHOW_NEXT_MILESTONE(state, value) {
    state.nextMilestone.showNextMilestone = value
  },

  SET_NEXT_MILESTONE_HIDE_CONFIRM_MILESTONE(state, value) {
    state.nextMilestone.hideConfirmMilestone = value
  },

  SET_NEXT_MILESTONE_SHOW_CONFIRM_MILESTONE(state, value) {
    state.nextMilestone.showConfirmMilestone = value
  },

  SET_NEXT_MILESTONE_PROGRESS_VALUE(state, completed) {
    if (completed){
      state.nextMilestone.progressValue = 100
    }else{
      state.nextMilestone.progressValue = ((state.projectDetails.studentmilestone.milestone.index - 1) / state.projectDetails.studentmilestone.milestone.total_milestones) * 100
    }
  },

  SET_PROJECT_ROOM_PERMISSIONS(state, has_room_edit_perms) {
    state.projectDetails.has_room_edit_perms = has_room_edit_perms
  },
  SUBMIT_STUDENT_MILESTONE(state) {
    if (state.projectDetails.studentmilestone) {
      state.projectDetails.studentmilestone.status = '1'
      state.projectDetails.studentmilestone.is_submitted = true
      state.projectDetails.studentmilestone.is_ongoing = false
    }
  },
  SET_STUDENT_PROXY(state, student_proxy) {
    if (typeof student_proxy === 'string') {
      try {
        state.projectDetails.student_proxy = JSON.parse(student_proxy);
      } catch (e) {
        console.error('Invalid JSON:', e);
        state.projectDetails.student_proxy = [];
      }
    } else if (student_proxy) {
      state.projectDetails.student_proxy = student_proxy;
    } else {
      state.projectDetails.student_proxy = [];
    }
  },
  SET_PROJECT_MILESTONE(state, studentmilestone) {
    if (studentmilestone) {
      state.projectDetails.studentmilestone = studentmilestone
    } else {
      state.projectDetails.studentmilestone = {}
    }
  },
  SET_PROJECT_GENRE(state, genre) {
    state.projectDetails.genre = genre
  },
  SET_PROJECT_STATUS(state, status) {
    state.projectDetails.status = status
  },

  RESTORE_LAYOUT_PAGE(state, data) {
    // console.log(JSON.parse(data.layout))
    // state.project.layout[data.pageNumber] = JSON.parse(data.layout)
    state.project.layout.splice(data.pageNumber, 0, JSON.parse(data.layout))
  },
  // methods
  switchMode: state => {
    state.tabActive = false
    state.eventGraphMode = !state.eventGraphMode
  },
  addPage: state => {
    state.project.layout.splice(state.pageSelected, 0, 'newPage')
    state.project.event.splice(state.pageSelected, 0, 'newPage')
    state.project.thumb.splice(state.pageSelected, 0, 'newPage')
    state.pageSelected++
  },

  deletePage: state => {
    if (state.project.layout.length <= 1) return

    state.project.layout.splice(state.pageSelected, 1)
    state.project.event.splice(state.pageSelected, 1)
    state.project.thumb.splice(state.pageSelected, 1)

    if (state.pageSelected > 0) {
      state.pageSelected--
    }
  },
  setPageIndex(state, index) {
    state.pageSelected = index
  },
  setTabIndex(state, index) {
    state.tabIndex = index
    state.tabActive = true
  },
  setPageScale(state, data) {
    state.project.pageScale[data.pageNumber] = data.pageScale
  },
  addActiveFont(state, data) {
    if (!state.activeFonts[state.pageSelected]) {
      state.activeFonts[state.pageSelected] = {}
    }
    state.activeFonts[state.pageSelected][data.id] = data.font
  },
  setLayoutPageDimensions(state, data) {
    state.project.layout[state.pageSelected].setHeight(data.height)
    state.project.layout[state.pageSelected].setWidth(data.width)
  },
  setLayoutPageDirty: state => {
    state.project.layout[state.pageSelected].dirty = true
  }
  // incrementObjectCounter(state, objectName) {
  //   state.objectsCounters[objectName]++
  // }
  // insertObjOnPage(state, o){

  //   if (o.left - o.width * o.scaleX / 2 < 0) {
  //     o.left = 20 + o.width * o.scaleX / 2;
  //   }

  //   if (o.top - o.height * o.scaleY < 0) {
  //     o.top = 20 + o.height * o.scaleY / 2;
  //   }

  //   state.project.layout[0].add(o);

  //   o.animate("opacity", 1, {
  //     duration: 500,
  //     easing: fabric.util.ease.easeOutQuart,
  //     abort: function() {
  //       if (!state.project.layout[state.pageSelected]) {
  //         callback();
  //         return true;
  //       }
  //     },
  //     onChange: state.project.layout[
  //       state.pageSelected
  //     ].renderAll.bind(
  //       state.project.layout[state.pageSelected]
  //     ),
  //     onComplete: function onComplete() {
  //       if (state.project.layout[state.pageSelected]) {
  //         state.project.layout[
  //           state.pageSelected
  //         ].setActiveObject(o);
  //         // setTimeout(function() {
  //         //   callback();
  //         // }, 10);
  //         console.log(state.project.layout[state.pageSelected]);
  //         state.project.layout[0].dirty = true;
  //         state.project.layout[0].requestRenderAll();
  //       }
  //     }
  //   });

  //   o.animate(
  //     "top",
  //     o.top +
  //       10 * state.project.pageScale[state.pageSelected],
  //     {
  //       duration: 700,
  //       easing: fabric.util.ease.easeOutBounce,
  //       abort: function() {
  //         if (!state.project.layout[state.pageSelected]) {
  //           return true;
  //         }
  //       }
  //     }
  //   );
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
