<template>
  <v-container fill-height>
    <v-layout justify-center align-center column>
      <h1 class="title">{{ message }}</h1>
      <h1 v-if="error && error.statusCode === 404">Page not found</h1>
      <h1 v-else-if="error && error.statusCode === 401">Permission Denied</h1>
      <h1 v-else>An error occurred</h1>

      <v-btn v-if="error.to === 'lessons'" depressed color="primary" to="/">Lesson Library</v-btn> 
      <v-btn v-if="error.statusCode === 404 || error.statusCode === 401" depressed to="/">Home</v-btn> 
      <v-btn v-else depressed color="primary" @click="refreshPage">Refresh</v-btn> 
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: [Object],
      required: true,
      default: function() {
        return {}
      }
    }
  },
  head() {
    return {
      title: this.message,
      meta: [
      ]
    }
  },
  methods: {
    refreshPage() {
      window.location.reload(true); // Reload from server
    }
  },
  computed: {
    message() {
      return this.error.message || `<%= messages.client_error %>`
    }
  }
}
</script>
