const apiFactory = axios => ({
  //DJANGO REST AUTH
  registerUser(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/registration/',
      data: data
    })
  },
  passwordReset(email) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/password/reset/',
      data: email
    })
  },

  passwordResetConfirm(data) {
    // uid, token, new_password1, new_password2
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/password/reset/confirm/',
      data: data
    })
  },
  passwordChange(data) {
    // new_password1, new_password2, old password
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/password/change/',
      data: data
    })
  },
  refreshToken(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/token/refresh/',
      data: data
    })
  },
  localLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/login/',
      data: data
    })
  },
  autoLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/autologin/',
      data: data
    })
  },
  resetStudentLoginLink(username, data){
    return axios({
      method: 'post',
      url: 'api/v1/accounts/' + username +'/reset_qr_code_token/',
      data: data
    })
  },
  getAllStudentLogins(slug) {
    return axios.$get('api/v1/classrooms/' + slug + '/get_student_logins/')
  },
  facebookLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/facebook/',
      data: data
    })
  },
  googleLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/google/',
      data: data
    })
  },
  cleverGetToken(data) {
    if (data.uri) {
      var uri = '&uri=' + data.uri
    }else{
      var uri = ''
    }
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/clever/get_token/?' + data.code + uri,
      data: data
    })
  },
  cleverLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/clever/',
      data: data
    })
  },
  importCleverClassroom(data) {
    return axios({
      method: 'post',
      url: 'api/v1/import_clever_classroom/',
      data: data
    })
  },
  classlinkGetToken(data) {
    if (data.uri) {
      var uri = '&uri=' + data.uri
    }else{
      var uri = ''
    }
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/classlink/get_token/?' + data.code + uri,
      data: data
    })
  },
  classlinkLogin(data) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/classlink/',
      data: data
    })
  },
  importClasslinkClassroom(data) {
    return axios({
      method: 'post',
      url: 'api/v1/import_classlink_classroom/',
      data: data
    })
  },
  resendVerifyEmail(email) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/email/verify/',
      data: email
    })
  },
  verifyEmail(token) {
    return axios({
      method: 'post',
      url: 'api/v1/rest-auth/registration/verify-email/',
      data: token
    })
  },

  //SUBSCRIPTION
  getInvoices(username) {
    return axios.$get('api/v1/stripe/invoices/' + username + '/')
  },
  getSubscription() {
    return axios.$get('api/v1/stripe/subscription/')
  },
  getUserSettings(username) {
    return axios.$get('api/v1/accounts/' + username + '/user_settings/')
  },
  setSubscription(data) {
    // plan-id and stripe token
    return axios({
      method: 'post',
      url: 'api/v1/stripe/subscription/',
      data: data
    })
  },
  cancelSubscription() {
    return axios({
      method: 'delete',
      url: 'api/v1/stripe/subscription/'
    })
  },
  reactivateSubscription() {
    let data = {
      reactivate: true
    }
    return axios({
      method: 'post',
      url: 'api/v1/stripe/subscription/',
      data: data
    })
  },
  checkCoupon(code) {
    return axios.$get('api/v1/stripe/check_coupon/' + code + '/')
  },
  //CLUBS
  getClubs() {
    return axios.$get('api/v1/clubs/')
  },

  //TRAININGS
  getTrainings() {
      return axios.$get('api/v1/trainings/')
    },

  // //NEW ASSIGNMENT REPLACING CURRICULUMS
  // getMyLessons(page, username, status){
  //   var params = ''
  //   params = '&user=' + username
  //   if(!page){
  //     page = 1
  //   }
  //   return axios.$get('api/v1/assignments/show_my_lessons/?page=' + page + params)
  // },
  getPastMyAssignments(page, username) {
    var params = ''
    params = '&user=' + username
    if(!page){
      page = 1
    }
    return axios.$get('api/v1/assignments/show_my_past_assignments/?page=' + page + params)
  },
  // previously setCurriculumAsAssignment / add_as_assignment
  cloneAssignment(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/assignments/' + slug + '/clone_assignment/',
      data: data
    })
  },


  
  //END

  // //CURRICULUMS
  // getCurriculum(slug) {
  //   return axios.$get('api/v1/curriculums/' + slug + '/')
  // },
  // getCurriculums(grade, subject) {
    
  //   let query = ''

  //   if (grade) {
  //     query = '&grade=' + grade
  //   }
  //   if (subject) {
  //     query = query + '&subject=' + subject
  //   }

  //   return axios.$get('api/v1/curriculums/?published_time__isnull=False' + query)
  // },
  // getRelatedCurriculums(grade, exclude) {
  //   let query = ''
  //   if (grade) {
  //     query = '&grade=' + grade
  //   }
  //   if (exclude) {
  //     query = query + '&exclude=' + exclude
  //   }
  //   return axios.$get('api/v1/curriculums/get_related_curriculums/?' + query)

  // },
  // setCurriculumAsAssignment(slug, data) {
  //   return axios({
  //     method: 'post',
  //     url: 'api/v1/curriculums/' + slug + '/add_as_assignment/',
  //     data: data
  //   })
  // },



  
  // //EDIT CURRICULUMS
  // getMyCurriculums() {
  //   return axios.$get('api/v1/curriculums/my_curriculums/')
  // },
  // createCurriculum(data) {
  //   return axios({
  //     method: 'post',
  //     url: 'api/v1/curriculums/',
  //     data: data
  //   })
  // },
  // editCurriculum(slug, data) {
  //   return axios({
  //     method: 'patch',
  //     url: 'api/v1/curriculums/' + slug + '/',
  //     data: data
  //   })
  // },
  // editCurriculumStandards(slug, data) {
  //   return axios({
  //     method: 'post',
  //     url: 'api/v1/curriculums/' + slug + '/save_standards/',
  //     data: data
  //   })
  // },
  // editCurriculumVocab(slug, data) {
  //   return axios({
  //     method: 'post',
  //     url: 'api/v1/curriculums/' + slug + '/save_vocab/',
  //     data: data
  //   })
  // },
  
  //LIST STANDARDS
  getAASL(page) {
    return axios.$get('api/v1/standards/AASL/?page=' + page)
  },
  getCCELA(page) {
    return axios.$get('api/v1/standards/CCELA/?page=' + page)
  },
  getCSTA(page) {
    return axios.$get('api/v1/standards/CSTA/?page=' + page)
  },
  getISTE(page) {
    return axios.$get('api/v1/standards/ISTE/?page=' + page)
  },
  getVocab(page) {
    return axios.$get('api/v1/vocab/?page=' + page)
  },
  getHelperText(page) {
    return axios.$get('api/v1/helpertext/?page=' + page)
  },

  //EVENTS
  getEvent(slug) {
    return axios.$get('api/v1/events/' + slug + '/')
  },

  //CLASSES
  getBanners() {
    return axios.$get('api/v1/banners/')
  },
  selectClassBanner(data) {
    return axios({
      method: 'post',
      url: 'api/v1/class_banner_select/',
      data: data
    })
  },
  getClassbyClasscode(classcode, invite_url, username) {
    let params = ''
    if (invite_url){
      params = '?invite=true'
      if (username){
        params = params + '&username=' + username
      }
    }
    return axios.$get('api/v1/get_classroom/' + classcode + '/' + params)
  },
  setClassbyClasscode(data) {
    return axios({
      method: 'post',
      url: 'api/v1/connect_classroom/',
      data: data
    })
  },
  getStudentClassrooms(username, page, active) {
    const data = {
      "username": username
    }
    // used only for transfer dialog of story to different classroom/assignment
    if (active) data.active = true
    var params = ''
    if (page) {
      params = '?page=' + page
    }
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/student_classroom_list/' + params,
      data: data
    })
  },
  getActiveShortListClassrooms(data) {
    // Classroom List returns  ('slug', 'name', 'active_assignments_count')
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/active_short_list/',
      data: data
    })
  },
  getClassrooms(username, page) {
    var params = ''
    params = '?teacher__username=' + username
    if (page) {
      params = params + '&page=' + page
    }
    return axios.$get('api/v1/classrooms/' + params)
  },
  getClassroomByGrade(grade, page) {
    var params = ''
    params = '?grade=' + grade
    if (page) {
      params = params + '&page=' + page
    }
    return axios.$get('api/v1/classrooms/' + params)

    // work in progress
    // return axios.$get('api/v1/classrooms/public_classrooms_list/' + params)
  },
  getClassroomBySubject(subject, page) {
    var params = ''
    params = '?subject=' + subject
    if (page) {
      params = params + '&page=' + page
    }
    return axios.$get('api/v1/classrooms/' + params)
    // work in progress
    // return axios.$get('api/v1/classrooms/public_classrooms_list/' + params)
  },
  getCoteachingClassrooms(username) {
    var params = ''
    params = '?coteacher=' + username
    return axios.$get('api/v1/classrooms/' + params)
  },
  getClassroom(slug) {
    return axios.$get('api/v1/classrooms/' + slug + '/')
  },

  createClassroom(data) {
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/',
      data: data
    })
  },
  editClassroom(slug, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/classrooms/' + slug + '/',
      data: data
    })
  },
  deleteClassroom(slug) {
    return axios({
      method: 'delete',
      url: 'api/v1/classrooms/' + slug + '/'
    })
  },
  archiveClassroom(slug) {
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/' + slug + '/archive/'
    })
  },
  unarchiveClassroom(slug) {
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/' + slug + '/unarchive/'
    })
  },
  setPasswordForStudents(slug, password, teacher) {
    const data = {
      password: password,
      teacher: teacher
    }
    return axios({
      method: 'post',
      url: 'api/v1/classrooms/' + slug + '/set_password_for_students/',
      data: data
    })
  },
  getGoogleClassrooms(data) {
    return axios({
      method: 'post',
      url: 'api/v1/get_google_classroom/',
      data: data
    })
  },
  importGoogleClassroom(data) {
    return axios({
      method: 'post',
      url: 'api/v1/import_google_classroom/',
      data: data
    })
  },
  createStudent(data) {
    return axios({
      method: 'post',
      url: 'api/v1/create_student/',
      data: data
    })
  },
  removeStudent(data) {
    return axios({
      method: 'post',
      url: 'api/v1/remove_student/',
      data: data
    })
  },
  setUserForGuestStory(storySlug, data){
    // data => username 
    return axios({
      method: 'post',
      url: 'api/v1/products/' + storySlug + '/set_user_for_guest_story/',
      data: data
    })

  },
  deleteOldGuestStories(){
    return axios.$get('api/v1/products/delete_old_guest_stories/')
  },
  transferStory(data) {
    // data => product_slug and classroom_slug
    return axios({
      method: 'post',
      url: 'api/v1/actions/transfer_story/',
      data: data
    })
  },

  // PUBLIC LIBRARY
  getLibrary(slug) {
    return axios.$get('api/v1/library/' + slug + '/')
  },
  getLibraryAssignmentStories(slug) {
    return axios.$get('api/v1/products/?status=p&short=True&assignment__slug=' + slug)
  },
  getStudentRoster(slug, page) {
    var query = ''
    if (page) {
      query = query + '?page=' + page
    }
    return axios.$get('api/v1/classrooms/' + slug + '/get_student_roster/' + query)
  },
  getStudentRosterProfile(slug, page) {
    var query = ''
    if (page) {
      query = query + '?page=' + page
    }
    return axios.$get('api/v1/classrooms/' + slug + '/get_student_roster_profile/' + query)
  },
  getStudentRosterOnline(slug, page) {
    var query = ''
    if (page) {
      query = query + '?page=' + page
    }
    return axios.$get('api/v1/classrooms/' + slug + '/get_online_student_roster/' + query, {
      progress: false
    })
  },

  //ASSIGNMENTS
  getClassroomAssignments(classSlug, page) {
    const data = {
      "class_slug": classSlug
    }
    if (!page) {
      page = 1
    }
    return axios({
      method: 'post',
      url: 'api/v1/assignments/assignments_for_classroom/?page=' + page,
      data: data
    })
  },
  getAssignmentDropdown(slug) {
    return axios.$get('api/v1/assignments/' + slug + '/detail_dropdown_milestones/')
  },
  getAssignment(slug) {
    return axios.$get('api/v1/assignments/' + slug + '/')
  },
  getStudentClassroomAssignments(classSlug, username, page) {
    const data = {
      "class_slug": classSlug,
      "username": username
    }
    if (!page) {
      page = 1
    }
    return axios({
      method: 'post',
      url: 'api/v1/assignments/student_assignments_for_classroom/?page=' + page,
      data: data
    })
  },
  getStudentClassroomAssignmentsName(classSlug, username, page) {
    const data = {
      "class_slug": classSlug,
      "username": username
    }
    if (!page) {
      page = 1
    }
    return axios({
      method: 'post',
      url: 'api/v1/assignments/student_assignments_names_for_classroom/?page=' + page,
      data: data
    })
  },


  getStoryBasic(slug) {
    return axios.$get('api/v1/products/' + slug + '/basic/')
  },

  createAssignment(data) {
    return axios({
      method: 'post',
      url: 'api/v1/assignments/',
      data: data
    })
  },
  editAssignment(slug, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/assignments/' + slug + '/',
      data: data
    })
  },
  deleteAssignment(slug) {
    return axios({
      method: 'delete',
      url: 'api/v1/assignments/' + slug + '/'
    })
  },

  submitToLessons(slug) {
    return axios({
      method: 'post',
      url: 'api/v1/assignments/' + slug + '/submit_to_lessons/'
    })
  },
  getAssignmentStats(slug) {
    return axios.$get('api/v1/assignment_student_stats/' + slug + '/')
  },

  getCurrentMilestoneIndex(storySlug) {
    return axios.$get(
      'api/v1/products/' + storySlug + '/current_milestone_index/'
    )
  },


  //ASSIGNMENT STATUS
  getLibraryAssignmentStatus(slug){
    return axios.$get('api/v1/assignmentstatus/' + slug + '/library/')
  },
  editAssignmentStatus(slug, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/assignmentstatus/' + slug + '/',
      data: data
    })
  },
  closeAssignmentStatus(slug) {
    return axios({
      method: 'post',
      url: 'api/v1/assignmentstatus/' + slug + '/close/'
    })
  },
  reopenAssignmentStatus(slug) {
    return axios({
      method: 'post',
      url: 'api/v1/assignmentstatus/' + slug + '/reopen/'
    })
  },
  deleteAssignmentStatus(slug) {
    return axios({
      method: 'delete',
      url: 'api/v1/assignmentstatus/' + slug + '/'
    })
  },

  getAssignmentStatusUserStories(assignment_status_slug, page, username, created_by){
    
    var params = ''

    params = '&user=' + username
    if (created_by) {
      // options: me, not_me
      let created_by_params = '&created_by=' + created_by
      params += created_by_params
    }

    return axios.$get('api/v1/assignmentstatus/'+ assignment_status_slug + '/user_stories/?page=' + page + params)
  },
  getAssignmentStatusStories(assignment_status_slug, status, page, ) {
    if (!page) {
      page = 1
    }
    return axios.$get(
      'api/v1/assignmentstatus/' + assignment_status_slug + '/get_stories/?status=' + status + '&page=' + page
    )
  },
  getAssignmentStatusPublicStories(assignment_status_slug, page, ) {
    if (!page) {
      page = 1
    }
    return axios.$get(
      'api/v1/assignmentstatus/' + assignment_status_slug + '/get_public_stories/?page=' + page
    )
  },

  //SCHOOLS
  createSchool(data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/',
      data: data
    })
  },
  editSchool(slug, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/schools/' + slug + '/',
      data: data
    })
  },
  deleteSchool(slug) {
    return axios({
      method: 'delete',
      url: 'api/v1/schools/' + slug + '/'
    })
  },
  getSchool(slug) {
    return axios.$get('api/v1/schools/' + slug + '/')
  },
  getSchoolTeachers(slug) {
    return axios.$get('api/v1/schools/' + slug + '/get_teachers/')
  },
  getSchoolInvitations(slug) {
    return axios.$get('api/v1/schools/' + slug + '/get_invites/')
  },
  inviteTeacher(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/' + slug + '/invite_teacher/',
      data: data
    })
  },
  editInvite(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/invitations/' + id + '/',
      data: data
    })
  },
  sendTeacherInvite(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/' + slug + '/send_invite/',
      data: data
    })
  },
  removeTeacher(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/' + slug + '/remove_teacher/',
      data: data
    })
  },
  checkSchoolcode(data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/check_schoolcode/',
      data: data
    })
  },
  connectSchool(data) {
    return axios({
      method: 'post',
      url: 'api/v1/schools/connect_school/',
      data: data
    })
  },
  getPurchaseOrders(school_slug) {
    return axios.$get('api/v1/pos/?school__slug=' + school_slug)
  },
  getQuote(slug) {
    return axios.$get('api/v1/quotes/' + slug + '/')
  },
  createQuote(data) {
    return axios({
      method: 'post',
      url: 'api/v1/quotes/',
      data: data
    })
  },
  editQuote(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/quotes/' + id + '/',
      data: data
    })
  },
  //FEEDBACK
  getNumFeedbacksforStories(data) {
    // data is list of story slugs
    return axios({
      method: 'post',
      url: 'api/v1/feedback/num_for_stories/',
      data: data
    })
  },
  getStoryFeedback(story_slug) {
    let data = {
      "slug": story_slug
    }
    return axios({
      method: 'post',
      url: 'api/v1/feedback/get_feedbacks_by_page/',
      data: data
    })
    // var query = '?product__slug=' + story_slug
    // if (page) {
    //   query = query + '&page=' + page
    // }
    // return axios.$get('api/v1/feedback/' + query)
  },
  createFeedback(data) {
    return axios({
      method: 'post',
      url: 'api/v1/feedback/',
      data: data
    })
  },
  editFeedback(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/feedback/' + id + '/',
      data: data
    })
  },
  deleteFeedback(id) {
    return axios({
      method: 'delete',
      url: 'api/v1/feedback/' + id + '/'
    })
  },

  //CHAT
  getChatHistory(story_slug) {
    return axios.$get('api/v1/rooms/' + story_slug + '/get_chat_history/')
  },

  //COMMENT
  getStoryComment(story_slug, page) {
    var query = '?product__slug=' + story_slug
    if (page) {
      query = query + '&page=' + page
    }
    return axios.$get('api/v1/comment/' + query)
  },
  getComment(comment_id, page) {
    var query = ''
    if (page) {
      query = query + '?page=' + page
    }
    return axios.$get('api/v1/comment/' + comment_id + '/' + query)
  },
  getStoryCommentBy(story_slug, editor, page) {
    var query = 'product__slug=' + story_slug + '&commenter__username=' + editor
    if (page) {
      query = query + '&page=' + page
    }
    return axios.$get('api/v1/comment/' + query)
  },
  createComment(data) {
    return axios({
      method: 'post',
      url: 'api/v1/comment/',
      data: data
    })
  },
  editComment(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/comment/' + id + '/',
      data: data
    })
  },
  deleteComment(id) {
    return axios({
      method: 'delete',
      url: 'api/v1/comment/' + id + '/'
    })
  },

  //ASSET FOLDER
  getAssetFolders(username, page) {
    var query = '?user__username=' + username
    if (page) {
      query = query + '&page=' + page
    }
    return axios.$get('api/v1/assets/assetfolders/' + query)
  },
  getAssetFolder(folder_id, page) {
    var query = ''
    if (page) {
      query = query + '?page=' + page
    }
    return axios.$get('api/v1/assets/assetfolders/' + folder_id + '/' + query)
  },
  createAssetFolder(data) {
    return axios({
      method: 'post',
      url: 'api/v1/assets/assetfolders/',
      data: data
    })
  },
  editAssetFolder(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/assets/assetfolders/' + id + '/',
      data: data
    })
  },
  deleteAssetFolder(id) {
    return axios({
      method: 'delete',
      url: 'api/v1/assets/assetfolders/' + id + '/'
    })
  },
  addAssetToFolder(folder_id, data) {
    return axios({
      method: 'post',
      url: 'api/v1/assets/assetfolders/' + folder_id + '/' + 'add_asset_to_folder/',
      data: data
    })

  },


  //USER
  checkUsername(username) {
    const data = {
      username: username
    }
    return axios({
      method: 'post',
      url: 'api/v1/accounts/check_username/',
      data: data
    })
  },
  checkEmail(email) {
    const data = {
      email: email
    }
    return axios({
      method: 'post',
      url: 'api/v1/accounts/check_email/',
      data: data
    })
  },
  getUsers(params) {
    var query = ''
    if (params) {
      query = '?' + params
    }
    return axios.$get('api/v1/accounts/' + query)
  },
  getUser(username) {
    if (username) {
      return axios.$get('api/v1/accounts/' + username + '/')
    }
  },
  editUser(username, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/accounts/' + username + '/',
      data: data
    })
  },
  editUserSettings(id, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/usersettings/' + id + '/',
      data: data
    })
  },
  addEmail(username, data) {
    return axios({
      method: 'post',
      url: 'api/v1/accounts/' + username + '/add_email/',
      data: data
    })
  },
  editUserPk(pk, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/accountsPk/' + pk + '/',
      data: data
    })
  },
  deleteUser(username) {
    return axios({
      method: 'delete',
      url: 'api/v1/accounts/' + username + '/'
    })
  },

  // FEED
  getNotificationFeed(feed_length) {
    var params = ''
    if (feed_length) {
      params = '&feed_length=' + feed_length
    }
    return axios.$get('api/v1/list_news_feed/?notification=true' + params)
  },

  // STORIES
  createStory(data) {
    return axios({
      method: 'post',
      url: 'api/v1/studio/create/',
      data: data
    })
  },
  editStory(slug, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/products/' + slug + '/',
      data: data
    })
  },
  sendThumbnail(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/products/' + slug + '/send_thumb/',
      data: data
    })
  },
  deleteStory(slug) {
    return axios({
      method: 'delete',
      url: 'api/v1/products/' + slug + '/'
    })
  },
  getMyUsedLessons(page, username) {
    var params = ''
    if(username !== undefined) {
      params = '&user=' + username
    }
    return axios.$get('api/v1/assignments/get_my_used_lessons/?page=' + page + params)
  },
  getMyLessons(page, username) {
    var params = ''
    if(username !== undefined) {
      params = '&user=' + username
    }
    return axios.$get('api/v1/assignments/get_my_lessons/?page=' + page + params)
  },
  getPublishedLessons(page, q) {
    // console.log('Query in getPublishedLessons:', q);
    // Append the query string only if it's provided and not empty
    const queryString = q ? `&${q}` : '';
    return axios.$get(`api/v1/assignments/published_lessons/?page=${page}${queryString}`);
  },
  getSimilarPublishedLessons(slug){
    return axios.$get('api/v1/assignments/' + slug + '/similar_published_lessons/')
  },
  getLesson(slug) {
    return axios.$get('api/v1/assignments/' + slug + '/read_lesson/')
  },
  assignLessonToClassroom(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/assignments/' + slug + '/assign_to_classroom/',
      data: data
    })
  },

  getUserStories(page, username, is_student, status, assignment, created_by) {
    var params = ''

    params = '&user=' + username
    if (is_student) {
      let student_params = '&is_student=' + is_student
      params += student_params
    }

    if (status) {
      // options: draft, public
      let status_params = '&status=' + status
      params += status_params
    }
    if (assignment) {
      let assignment_params = '&assignment=' + assignment
      params += assignment_params
    }
    if (created_by) {
      // options: me, not_me
      let created_by_params = '&created_by=' + created_by
      params += created_by_params
    }

    return axios.$get('api/v1/products/user_stories/?page=' + page + params)
  },
  getReadStory(slug) {
    return axios.$get('api/v1/productsread/' + slug + '/')
  },
  getReviewStory(slug) {
    return axios.$get('api/v1/productsreview/' + slug + '/')
  },
  getFeaturedStories() {
    return axios.$get('api/v1/featured_stories/')
  },
  getPublishedStories(page, search) {
    var searchQ = ''
    if (search) {
      searchQ = '&q=' + search
    }
    return axios.$get('api/v1/products/published_stories/?page=' + page + searchQ)
  },
  // ASSETS
  getAssetsList(page, category, search, group){
    var searchQ = ''
    if (search) {
      searchQ = '&q=' + search
    }
    let categoryQ = ''
    if(category){
      categoryQ = '&category=' + category
    }
    let groupQ = ''
    if(group){
      groupQ = '&group=' + group
    }

    return axios.$get('api/v1/assets/studio_assets/?page=' + page + categoryQ + searchQ + groupQ)
  },
  getAssetsSVGList(page){
    return axios.$get('api/v1/assets/studio_svgs/?page=' + page)
  },
  getFeaturedAssets() {
    return axios.$get('api/v1/assets/featured_assets/')
  },
  getAssetsInCollection(col_id) {
    return axios.$get('api/v1/assets/get_col_assets/' + col_id + '/')
  },
  getAssetsDetailsInCollection(col_id) {
    return axios.$get('api/v1/assets/get_col_assets_details/' + col_id + '/')
  },
  getAssetsFromAsset(slug) {
    return axios.$get('api/v1/assets/get_col_assets_from_asset/' + slug + '/')
  },
  getSVGCollectionFromAsset(slug) {
    return axios.$get('api/v1/assets/get_col_svg_from_asset/' + slug + '/')
  },

  getAssetSvgInCollection(col_id) {
    return axios.$get('api/v1/assets/get_col_assetsvgs/' + col_id + '/')
  },
  getAssetCollections(username) {
    return axios.$get('api/v1/assets/asset_collections/' + username + '/')
  },
  getSVGCollections(username) {
    return axios.$get('api/v1/assets/svg_collections/' + username + '/')
  },

  filterSoundsList(options){
    let params_list = []
    let params = ''
    
    if (options.category){
      params_list.push('category=' + options.category)
    }
    if (options.genre){
      params_list.push('genre=' + options.genre)
    }
    if (options.mood){
      params_list.push('mood=' + options.mood)
    }
    if(options.instrument){
      params_list.push('has_instrument=' + options.instrument)
    }
    if(options.collection_id){
      params_list.push('collection__id=' + options.collection_id)
    }
    if (params_list.length > 0){
      params = params_list.join('&')
    }
    return axios.$get('api/v1/assets/get_sounds/?' + params)

  },
  getSoundEffectCollectionsList(genre){
    return axios.$get('api/v1/assets/get_sound_effect_collections/?genre=' + genre)
  },


  
  getSoundsInCollection(col_id) {
    return axios.$get('api/v1/assets/get_col_sounds/' + col_id + '/')
  },
  getFeaturedSounds() {
    return axios.$get('api/v1/assets/featured_sounds/')
  },
  getSoundCollectionsByArtist(username) {
    return axios.$get('api/v1/assets/sound_collections_by_artist/' + username + '/')
  },

  
  //MESSAGES
  getDisplayMessages() {
    return axios.$get('api/v1/messages/?display=true')
  },
  removeMessage(id) {
    return axios({
      method: 'patch',
      url: 'api/v1/messages/' + id + '/',
      data: {
        display: false
      }
    })
  },
  getSuggestedUsers(pagesize) {
    //default pagesize is 12
    const params = ''
    if (pagesize) {
      params = '?page_size=' + pagesize
    }
    return axios.$get('api/v1/suggested_users/' + params)
  },
  // PROFILE
  getUserProfile(username) {
    return axios.$get('api/v1/accounts/' + username + '/')
  },
  editUserProfile(username, data) {
    return axios({
      method: 'patch',
      url: 'api/v1/profile/' + username + '/',
      data: data
    })
  },
  getAvatars(page) {
    return axios.$get('api/v1/avatars/?page=' + page)
  },

  changeAvatar(data) {
    return axios({
      method: 'post',
      url: 'api/v1/avatar_select/',
      data: data
    })
  },
  getFollowers(username) {
    return axios({
      method: 'get',
      url: 'api/v1/accounts/' + username + '/get_followers/'
    })
  },
  getFollowing(username) {
    return axios({
      method: 'get',
      url: 'api/v1/accounts/' + username + '/get_following/'
    })
  },
  // BADGES
  getBadges() {
    return axios.$get('api/v1/badges/?category=wr')
  },

  // ACTIONS

  getVersion() {
    return axios.$get('api/v1/actions/getversion')
  },
  like(data) {
    // data = {object_id, type}
    return axios({
      method: 'post',
      url: 'api/v1/actions/like/',
      data: data
    })
  },
  follow(username) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/follow/',
      data: {
        username: username
      }
    })
  },
  report(data) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/report/',
      data: data
    })
  },
  remixStory(data) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/remix/',
      data: data
    })
  },
  sendStoryLog(data) {
    return axios.$post('api/v1/actions/send_log/', data, {
      progress: false
    })
  },
  hit(data) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/hit/',
      data: data,
      options: {
        progress: false
      }
    })
  },
  updateNotifications(data) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/update_notification/',
      data: data
    })
  },
  updateTutorial(data) {
    return axios({
      method: 'post',
      url: 'api/v1/actions/update_notification/?rand=' + Math.random(),
      data: data
    })
  },
  getTags(model) {
    if (!model) {
      model = 'product'
    }
    return axios.$get('api/v1/actions/get_tags?model=' + model)
  },
  getCurrency() {
    return axios.$get('api/v1/actions/get_currency/')
  },

  // FORMS
  serverStatus() {
    return axios.$get('api/v1/server_status/')
  },
  contactForm(data) {
    return axios({
      method: 'post',
      url: 'api/v1/contact/',
      data: data
    })
  },
  errorForm(data) {
    return axios({
      method: 'post',
      url: 'api/v1/error/',
      data: data
    })
  },
  subscribeForm(data) {
    return axios({
      method: 'post',
      url: 'api/v1/subscribe/',
      data: data
    })
  },

  // ROOMS
  addMember(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/rooms/' + slug + '/add_member/',
      data: data
    })
  },
  removeMember(slug, data) {
    return axios({
      method: 'post',
      url: 'api/v1/rooms/' + slug + '/remove_member/',
      data: data
    })
  },
  getAllRoomMembers(slug, page) {
    // returns list of all members in the room
    return axios.$get('api/v1/rooms/' + slug + '/get_all_members/?page=' + page)
  },
  changeMember(slug, data) {
    // username, data
    return axios({
      method: 'post',
      url: 'api/v1/rooms/' + slug + '/change_member/',
      data: data
    })
  },

  //STUDIO
  getEditStory(slug) {
    return axios.$get('api/v1/studio/edit/' + slug + '/')
  },
  deleteRecordSound(id) {
    return axios({
      method: 'delete',
      url: 'api/v1/assets/recordsounds/' + id + '/'
    })
  },
  addRecordSound(data) {
    return axios({
      method: 'post',
      url: 'api/v1/assets/recordsounds/',
      data: data
    })
  },
  getPageTemplatesList(category, page) {
    let params = ''
    if(!page){
      page = 1
    }
    if (category) {
      params = '&category=' + category
    }
    return axios.$get('api/v1/pagetemplates/?page=' + page + params)
  },
  getPageTemplate(slug) {
    return axios.$get('api/v1/pagetemplates/' + slug + '/')
  },

  getTutorialsList(category, page) {
    let params = ''
    if(!page){
      page = 1
    }
    if (category) {
      params = '&category=' + category
    }
    return axios.$get('api/v1/tutorials/?page=' + page + params)
  },
  getTutorial(slug) {
    return axios.$get('api/v1/tutorials/' + slug + '/')
  },

  searchGifs(searchTerm, apiKey){
    return axios.$get(`https://api.giphy.com/v1/gifs/search?api_key=${apiKey}&q=${searchTerm}&rating=g`)
  }

})

/*
 ** Executed by ~/.nuxt/index.js with context given
 ** This method can be asynchronous
 */
export default ({
  app
}, inject) => {
  // Inject `api` key
  // -> app.$api
  // -> this.$api in vue components
  // -> this.$api in store actions/mutations
  // app.$axios.defaults.xsrfHeaderName = 'X-CSRFToken'
  // app.$axios.defaults.xsrfCookieName = 'csrftoken'
  const api = apiFactory(app.$axios)
  inject('api', api)
}
