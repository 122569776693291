import Vue from 'vue'

Vue.directive('studio-click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (
        !(el == event.target || el.contains(event.target)) &&
        // check if event.target is select element (for image categories)
        (!event.target.classList.contains('v-list-item__title') &&
        !event.target.classList.contains('v-subheader') && 
        !event.target.classList.contains('v-list-item') &&
        !event.target.classList.contains('v-list-item__content'))
      ) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  }
})
